import {useSelector} from "react-redux";
import {useActionCreator} from "../Redux/useActionCreator";
import {getInputReselectedStore} from "../Redux/Modules/Inputs/reselect";

export const useInput = (name) => {
	const inputs = useSelector(getInputReselectedStore)
	const {updateInputsStoreAction} = useActionCreator()
	const onChange = (e) => updateInputsStoreAction({[e.target.name]: e.target.value})
	return {
		onChange,
		value: inputs[name] || ''
	}
}
