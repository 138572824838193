import { Grid } from '@material-ui/core';

import { JobShortCard } from 'components';

export const CareersSearchResultBlock = ({
  listData,
  handleSelectCard,
    themeSelected
}) => {
    return (
        <Grid container justifyContent={'center'}>
            <Grid item md={12}>
                <Grid container>
                    {listData.map((card) => (
                        <JobShortCard
                            key={card.id}
                            handleSelectCard={handleSelectCard}
                            card={card}
                            themeSelected={themeSelected}
                        />
                    ))}
                </Grid>
            </Grid>
        </Grid>
    )
};
