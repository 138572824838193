import {useHistory} from "react-router-dom";
import {useContext, useEffect, useMemo, useState} from "react";
import useDocumentScrollThrottled from "hooks/useDocumentScrollThrottled";
import {useSelector} from "react-redux";
import generalReselect from "Redux/Modules/General/reselect";
import {useActionCreator} from "Redux/useActionCreator";
import {Context} from "Context/Context";
import stylesEnumObject from "Theme/ThemeSwitching/theneNames";
import {useLocation} from 'react-router-dom';
import PageLevelService from "helpers/PageLevelService";
import {blockTypesEnum} from "BlockConstructor/BlockTypes";

const useHeader = () => {
	const history = useHistory();
	const location = useLocation();
	const [theme, setTheme] = useState(stylesEnumObject.blackBackground)
	const {header, headerAdditional = []} = useSelector(generalReselect.getGeneralReselectedStore);
	const {generalHeaderFetchMainContentAction} = useActionCreator();
	const {navigationCareers} = useSelector(state => state.careers);
	const {currentType} = useSelector(state => state.cms);

	const isTypeCareers = useMemo(() => currentType === blockTypesEnum.Career, [currentType])

	const {backgroundData} = useContext(Context);

	useEffect(() => {
		if (backgroundData?.themeSelected) {
			setTheme(backgroundData?.themeSelected)
		}
	}, [backgroundData])

	// eslint-disable-next-line
	useEffect(generalHeaderFetchMainContentAction, []);

	const pageLevelService = new PageLevelService(location.pathname);

	useEffect(() => {
		if (pageLevelService.isFirstLevel) {
			setTheme(stylesEnumObject.blackBackground)
		} else {
			setTheme(stylesEnumObject.whiteBackground)
		}
		// eslint-disable-next-line
	}, [location.pathname]);

	const [hoveredItemId, setHoveredItemId] = useState('')
	const handleCheckItemId = (id) => () => {
		setHoveredItemId(id)
	}

	const handleSelectDropdownItem = (path) => () => {
		if (typeof path !== 'string') {
			history.push(`${path.path}${path.anchor ? `#${path.anchor}` : ''}`)
		} else {
			history.push(path)
		}
	}
	const handleRedirectOnTab = (item) => () => {
		if (item.path) {
			history.push(item.path)
		}
	}

	const [shouldHideHeader, setShouldHideHeader] = useState(false);

	const MINIMUM_SCROLL = 1;
	const TIMEOUT_DELAY = 10;

	useDocumentScrollThrottled(callbackData => {
		const {previousScrollTop, currentScrollTop} = callbackData;
		const isScrolledDown = previousScrollTop < currentScrollTop;
		const isMinimumScrolled = currentScrollTop > MINIMUM_SCROLL;
		setTimeout(() => {
			isMinimumScrolled && setShouldHideHeader(isScrolledDown);
			if (currentScrollTop <= 0) {
				setShouldHideHeader(false)
			}
		}, TIMEOUT_DELAY);
	});
	const hiddenStyle = useMemo(() => shouldHideHeader ? 'hidden' : '', [shouldHideHeader]);

	const [toggleHeader, setToggleHeader] = useState(false);
	const [enableScrollAnimation, setEnableScrollAnimation] = useState(false)

	useEffect(() => {
		setToggleHeader(true)
		setTimeout(() => {
			setEnableScrollAnimation(true)
		}, 2500)
	}, []);

	return {
		headerListData: header,
		handleSelectDropdownItem,
		handleRedirectOnTab,
		hiddenStyle,
		handleCheckItemId,
		hoveredItemId,
		theme,
		headerAdditional,
		toggleHeader,
		enableScrollAnimation,
		navigationCareers,
		isTypeCareers,
	}

}
export default useHeader;
