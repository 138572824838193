import { GET_CONTACT_US_COUNTRIES, GET_CONTACT_US_OFFICES } from './constants';
import axios from 'axios';

class ContactUs {
  async getCountries(params) {
    try {
      return axios.get(GET_CONTACT_US_COUNTRIES, params);
    } catch (e) {}
  }
  async getOffices(id) {
    try {
      return axios.get(GET_CONTACT_US_OFFICES(id));
    } catch (e) {}
  }
}

export default ContactUs;
