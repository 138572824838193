import useStyles from './style'
import {Grid} from "@material-ui/core";
import {MainContainer} from "../MainContainer";
import FilterListIcon from '@mui/icons-material/FilterList';
import CloseIcon from '@mui/icons-material/Close';
import {SubmitIcon} from "../SubmitIcon";
import {CareersInput} from "../CareersInput";
import React from "react";
import {FilterMobileButton} from "../FilterMobileButton";
import {MobileFilterSubmitButton} from "../MobileFilterSubmitButton";
import {useMobileFilterScreen} from "./useMobileFilterScreen";
import {modalNamesEnum} from "Context/ModalContext";

const MobileFilterScreen = ({
								blockName = 'careers-mobile-filter-screen',
								data
							}) => {
	const classes = useStyles(data.themeSelected)();
	const {
		themeSelected = '',
		isShown = false,
		inputStoreKey = '',
		inputPlaceholder = '',
		findAction = () => null,
		resetAction = () => null,
		submitRedirectUrl = '',
		mainButtonName = 'Filter Jobs'
	} = data;

	const {
		handleClickIcon,
		listItemAction,
		isListShown,
		filters,
		inputs,
		history,
		handleCloseModal,
		filtersData,
		buttonsData,
		handleToggleList
	} = useMobileFilterScreen({filtersData: data.filtersData});

	return <MainContainer themeSelected={themeSelected} blockName={blockName}
						  externalClassName={`${classes.container} ${isShown ? classes.menuOpened : classes.menuClosed}`}>

		<Grid item xs={12}
			  className={`${classes.contentContainer}`}>
			<Grid container alignItems={'flex-start'} className={classes.titleContainer}>
				<Grid item xs={1}>
					<FilterListIcon/>
				</Grid>
				<Grid item xs={10}>
					{mainButtonName}
				</Grid>
				<Grid item xs={1} className={classes.closeIcon}>
					<CloseIcon onClick={handleCloseModal(modalNamesEnum.careersFilterModal)}/>
				</Grid>
			</Grid>
			<Grid container className={classes.inputContainer}>
				<CareersInput handleSubmit={() => null} name={inputStoreKey} classes={classes}
							  themeSelected={data.themeSelected}
							  isMobile={true}
							  placeholder={inputPlaceholder}
							  inputRightIcon={SubmitIcon}
				/>
				<Grid item xs={12} className={classes.filtersTitle}>
					Filters:
				</Grid>
			</Grid>

			<Grid container className={classes.filterContainer}>
				{filtersData.map((button, idx) => <FilterMobileButton
					key={button.name}
					themeSelected={data.themeSelected}
					handlePressButton={handleToggleList(button.name)}
					isListShown={isListShown[idx]?.status}
					name={button.name}
					list={filters[button.name]}
					title={inputs[button.name]}
					placeholder={button.placeholder}
					isDropdown={true}
					listItemAction={listItemAction(button.name)}
					handleClickIcon={inputs[button.name] ? handleClickIcon(button.name) : handleToggleList(button.name)}
				/>)}
			</Grid>

			<Grid container justifyContent={'space-between'} className={classes.submitButtonsContainer}>
				{buttonsData.map((button) => <MobileFilterSubmitButton
						key={button.title}
						buttonType={button.buttonType}
						themeSelected={data.themeSelected}
						title={button.title}
						icon={button.icon}
						press={button.action({
							findAction,
							history,
							key: inputStoreKey,
							resetAction,
							handleCloseModal: handleCloseModal(modalNamesEnum.careersFilterModal),
							submitRedirectUrl
						})}
					/>
				)}
			</Grid>
		</Grid>
	</MainContainer>
}

export default MobileFilterScreen;
