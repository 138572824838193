import {applyMiddleware, combineReducers, createStore} from 'redux'
import logger from 'redux-logger'
import thunk from "redux-thunk";
import {inputsReducer} from "./Modules/Inputs/inputs-reducer";
import {persistStore, persistReducer} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import {filtersReducer} from "./Modules/Filters/filters-reducer";
import {mainPageReducer} from './Modules/MainPage/main-page-reducer';
import {generalReducer} from './Modules/General/general-reducer';
import {client} from "../App";
import {careersReducer} from "./Modules/Careers/careers-reducer";
import {insightsReducer} from "./Modules/Insights/insights-reducer";
import {aboutUsReducer} from "./Modules/AboutUs/aboutUs-reducer";
import {contactUsReducer} from "./Modules/ContactUs/contactUs-reducer";
import {cmsReducer} from "./Modules/CMS/cmsReducer";

const persistConfig = {
	key: 'root',
	storage,
	blacklist: ['inputs', 'careers', 'insights', 'cms', 'general']
}

const rootReducer = combineReducers({
	inputs: inputsReducer,
	filters: filtersReducer,
	mainPage: mainPageReducer,
	general: generalReducer,
	careers: careersReducer,
	insights: insightsReducer,
	aboutUs: aboutUsReducer,
	contactUs: contactUsReducer,
	cms: cmsReducer,
})
const persistedReducer = persistReducer(persistConfig, rootReducer)
const store = createStore(persistedReducer, applyMiddleware(
	logger,
	thunk.withExtraArgument(client)))
let persistor = persistStore(store)

const exportData = {store, persistor}
export default exportData;
