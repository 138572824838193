import React, {useContext} from 'react';
import { Grid } from '@material-ui/core';

import { ModalContext } from 'Context/ModalContext';
import SettingsIcon from '@mui/icons-material/Settings';
import stylesEnumObject from 'Theme/ThemeSwitching/theneNames';
import {modalNamesEnum} from "Context/ModalContext";

import useStyles from './style';

const CoockieConsentModal = ({ modal }) => {
  const classes = useStyles(stylesEnumObject.blackBackground)();

  const { handleCloseModal } = useContext(ModalContext);

  const handleAccepetCookies = () => {
    localStorage.setItem('cookie-banner', 'true')
    handleCloseModal(modalNamesEnum.cookieConsentModal)();
  }

  return (
    <Grid
      className={`${classes.container} ${
        modal.isShown ? classes.menuOpened : classes.menuClosed
      }`}
    >
      <Grid component='p' className={classes.cookieText}>
        {
          'This website uses cookies to technically implement the functions of our site. With your consent, we also use third-party services to improve the functionality of our website as well as for performance analysis and targeting/advertising purposes, which help us to improve our website. By clicking on "Accept all cookies" you declare your consent, which can be revoked at any time. Further information, in particular on the storage period and recipients, can also be found in our '
        }
        <span>{'data protection notice & cookie policy.'}</span>
      </Grid>
      <Grid container className={classes.buttonsContainer}>
        <Grid item xs={8} sm={2} component='button' className={classes.acceptButton} onClick={handleAccepetCookies}>Accept all cookies</Grid>
        <Grid item xs={8} sm={2} component='button' className={classes.necessaryButton}>Accept only necessary</Grid>
        <Grid item xs={8} sm={2} component='button' className={classes.settingsButton}><SettingsIcon />Cookie Settings</Grid>
      </Grid>
    </Grid>
  );
};

export default CoockieConsentModal;
