import {createContext, useCallback, useEffect, useMemo, useState} from "react";
import {useLocation} from 'react-router-dom'

export const Context = createContext({})

export const ContextProvider = ({children}) => {
	const [backgroundData, setBackgroundData] = useState({});
	const location = useLocation();
	const handleToggleScroll = useCallback(() => {
		const observer = JSON.parse(localStorage.getItem('observer') || '{}');
		const currentLocatedBlock = Object.values(observer).find(item => item.bottom > 0 && item.bottom < item.height)
		if (backgroundData?.id !== currentLocatedBlock?.id) {
			setBackgroundData(prev => {
				if (prev?.id !== currentLocatedBlock?.id) {
					return currentLocatedBlock
				} else {
					return prev
				}
			})
		}
	}, [backgroundData?.id])
	useEffect(() => {
		window.addEventListener('scroll', handleToggleScroll)
		return () => window.removeEventListener('scroll', handleToggleScroll);
	}, [handleToggleScroll])
	useEffect(() => {
		localStorage.removeItem('observer')
		setBackgroundData({})
	}, [location.pathname])

	const memoData = useMemo(() => backgroundData, [backgroundData])
	return (
		<Context.Provider value={{backgroundData: memoData}}>
			{children}
		</Context.Provider>
	)
}
