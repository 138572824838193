import React, {useEffect} from 'react';
import {
	// Route, Switch,
	useLocation} from 'react-router-dom';
import {Grid} from '@material-ui/core';
import {Header, Footer, HeaderBurger, CoockieConsent} from 'components';
import stylesEnum from 'Theme/ThemeSwitching/theneNames';

// import routes from './Routes';
import useStyles from './app-styles';
import {ApiClient} from "jsonapi-react";
import {BASE_URL} from "./API/constants";
import blockTypes from 'BlockConstructor/BlockTypes'
// import {usePageType} from "./hooks/usePageType";
// import routes from "./Routes";
import {useActionCreator} from "./Redux/useActionCreator";
// import constantsClass from "./API/ConstantsClass";
import {useSelector} from "react-redux";

const schema = {};

export const client = new ApiClient({
	url: BASE_URL,
	schema
});

function App() {
	const classes = useStyles();
	const cms = useSelector(state => state.cms);
	const {fetchCmsSubRequestAction} = useActionCreator();
	const {pathname} = useLocation();
	useEffect(() => {
		fetchCmsSubRequestAction(pathname);
		//eslint-disable-next-line
	}, [pathname])

	return (
		<Grid container justifyContent={'center'} className={classes.wrapper}>
			<CoockieConsent/>
			<Grid item xs={12}>
				<HeaderBurger/>
				<Header/>
			</Grid>
			<Grid container>
				{blockTypes.getPageType(cms.currentType, {pageData: cms[pathname]})}
				{/*<Switch>*/}
				{/*	{routes.map((route, idx) => (*/}
				{/*		<Route key={idx} {...route} />*/}
				{/*	))}*/}
				{/*</Switch>*/}
			</Grid>
			<Footer themeSelected={stylesEnum.blackBackground}/>
		</Grid>
	);
}

export default App;
