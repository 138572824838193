import React from 'react';
import stylesEnum from 'Theme/ThemeSwitching/theneNames';
import {blockComponents} from 'BlockConstructor/BlockNames';
import {useInsights} from './useInsights';
import {Grid} from "@material-ui/core";
import {usePageMeta} from "../../hooks/usePageMeta";

const Insights = ({themeSelected = stylesEnum.blackBackground, pageData = []}) => {
	useInsights();
	const MetaTag = usePageMeta();

	return (
		<Grid container>
			<MetaTag/>
			<div style={{ height: 70, width: '100%'}} />
			<Grid item xs={12}>
				{!!pageData?.length && pageData.map((block, index) => blockComponents.getComponent(block?.type)({params: block}, index))}
			</Grid>
		</Grid>
	);
};

export default Insights;
