import API from 'API/index';
import {UPDATE_INSIGHTS_STORE} from "./insights-type";
import {handleParseJob} from "helpers/handleParseJob";


class InsightsActions {
	handleUpdateInsightsStoreAction = (payload) => ({
		type: UPDATE_INSIGHTS_STORE,
		payload
	});

	getInsightsPageDataAction = () => async (dispatch, store, query) => {
		try {
			const queryData = query.config
			const res = await API.insights.getPageData();
			const updatedData = res.data.included.map(i => ({...i, attributes: {...i.attributes, type: i.type}}))
			const data = queryData.normalize({data: updatedData})
			dispatch(this.handleUpdateInsightsStoreAction({pageData: data}))
		} catch (e) {
			console.error(e)
		}
	};

	getInsightsListOfNewsAction = () => async (dispatch, store, query) => {
		try{
			const res = await API.insights.getBaseNews();
			dispatch(this.handleUpdateInsightsStoreAction({newsList: handleParseJob(res.data)}))
		} catch (e) {
			console.error(e)
		}
	}

	getSingeInsightNewsAction = (id) => async (dispatch, store, query) => {
		try {
      const queryData = query.config
			const res = await API.insights.getSingeInsightNews(id);
			const updatedData = res.data.included.map(i => ({...i, attributes: {...i.attributes, type: i.type}}))
			const data = queryData.normalize({data: updatedData})
			dispatch(this.handleUpdateInsightsStoreAction({newsDetail: data.data}))
		}catch (e) {
			console.error(e)
		}
	}

	getFilteredInsightsAction = ({...rest} = {}) => async (dispatch, store, query) => {
		try {
			const state = store().inputs;
			const {insightsSearch = '', typeFilter='', industryFilter=''} = state;
			const res = await API.insights.getInsightsByTitle({insightsSearch, typeFilter, industryFilter, ...rest});
			dispatch(this.handleUpdateInsightsStoreAction({foundInsights: handleParseJob(res.data), wasSearch: true}));
		} catch (e) {
			console.error(e)
		}
	}

	setNewsInfo = (data) => async (dispatch, store, query) => {
		try {
			dispatch(this.handleUpdateInsightsStoreAction({newsInfo: data}));
		} catch (e) {
			console.error(e)
		}
	}
}

export default new InsightsActions();
