import {useEffect, useState} from "react";

export const useOnScreen =(ref) =>{

	const [isIntersecting, setIntersecting] = useState(false)

	const observer = new IntersectionObserver(
		([entry]) => setIntersecting(entry.isIntersecting)
	)

	useEffect(() => {
		observer.observe(ref.current)
		// Remove the observer as soon as the component is unmounted
		return () => { observer.disconnect() }
		// eslint-disable-next-line
	}, [])

	return isIntersecting
}
