import axios from "axios";
import {MAIN_PAGE_BLOCK_URL} from "./constants";

class MainPageApi {
	async getData(params) {
		try {
			return axios.get(MAIN_PAGE_BLOCK_URL, params)
		} catch (e) {
			console.error(e)
		}
	}
}

export default MainPageApi
