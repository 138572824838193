import { Grid, Typography } from '@material-ui/core';

import { MainContainer, Title } from 'components';
import stylesEnum from 'Theme/ThemeSwitching/theneNames';

import { API_URL } from 'API/constants';

import useStyles from './style';

const AwardsModule = ({
  themeSelected = stylesEnum.whiteBackground,
  blockName = 'article-tile',
  data
}) => {
  const classes = useStyles(themeSelected)();
  const { title = '', label = 'We are transcom', offset = 'rightOffset', awards } = data;

  return (
    <MainContainer themeSelected={themeSelected} blockName={blockName}>
      <Grid container>
        <Title
          offsetOption={offset}
          title={title}
          label={label}
          themeSelected={themeSelected}
          className={classes.title}
          titleGrid={{ xs: 12, sm: 8 }}
        />
        <Grid item className={classes.container} xs={12} sm={8}>
          <Grid
            container
            justifyContent='flex-start'
            spacing={4}
            className={classes.articleContainer}
          >
            {!!awards.length &&
              awards?.map((card) => (
                <Grid key={card.id} item xs={9} sm={4} className={classes.award}>
                  {card.image && (
                    <Grid
                      component='img'
                      src={API_URL + card.image}
                      className={classes.articleImage}
                    />
                  )}
                  <Typography variant='h6' className={classes.awardTitle}>
                    {card.title || ''}
                  </Typography>
                  <Typography variant='body1' className={classes.awardDesc}>
                    {card.description || ''}
                  </Typography>
                </Grid>
              ))}
          </Grid>
        </Grid>
      </Grid>
    </MainContainer>
  );
};

export default AwardsModule;
