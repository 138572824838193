import useStyles from './style';
import animationStyle from './animationStyle.module.css'
import {Button, MainContainer} from "components";
import stylesEnumObject from "Theme/ThemeSwitching/theneNames";
import {Grid} from "@material-ui/core";
import {useRef} from "react";
import {useOnScreen} from "hooks/useOnScreen";
//
// const data = [
// 	{
// 		title: '',
// 		offset: 'right',
// 		type: 'chat',
// 		style: animationStyle.spaceContainer1,
// 		textAnimation: animationStyle.loading1
// 	},
// 	{title: 'We should talk!', offset: 'right', type: 'chat', style: animationStyle.spaceContainer2},
// 	{title: 'Read', offset: 'right', type: 'status', style: animationStyle.spaceContainer3},
// 	{title: 'Mail?', offset: 'left', type: 'chat', style: animationStyle.spaceContainer4},
// 	{title: 'Tweet?', offset: 'left', type: 'chat', style: animationStyle.spaceContainer5},
// 	{title: 'Call?', offset: 'left', type: 'chat', style: animationStyle.spaceContainer6},
// 	{title: 'Read', offset: 'left', type: 'status', style: animationStyle.spaceContainer7},
// 	{
// 		title: '',
// 		offset: 'right',
// 		type: 'chat',
// 		style: animationStyle.spaceContainer8,
// 		textAnimation: animationStyle.loading2
// 	},
// 	{title: 'Anything goes!', offset: 'right', type: 'chat', style: animationStyle.spaceContainer9},
// 	{title: 'Read', offset: 'right', type: 'status', style: animationStyle.spaceContainer10},
// ]

const Bubble = ({item: {title, offset, style, textAnimation}}) => <Grid className={style} container
																		justifyContent={offset === 'right' ? 'flex-end' : 'flex-start'}>
	<Grid item className={animationStyle.chatIcon}>
		<span className={textAnimation}>{title}</span>
	</Grid>
</Grid>

const ReadStatus = ({item: {title, offset, style}}) => <Grid className={style} container
															 justifyContent={offset === 'right' ? 'flex-end' : 'flex-start'}>
	<Grid item className={animationStyle.readStatus}>
		{title}
	</Grid>
</Grid>

const AnimationRender = ({isShown, animationData}) => <Grid container>
	<Grid item xs={12}>
		{isShown && animationData.map((item, idx) => item.type.includes('chat') ? <Bubble key={idx} item={item}/> :
			<ReadStatus key={idx} item={item}/>)}
	</Grid>
</Grid>


const ChatAnimationModule = ({
								 animationData = [],
								 buttonUrl = '',
								 buttonName = '',
								 blockName = '',
								 themeSelected = stylesEnumObject.blackBackground,
								 title = 'Ready to get started?',
								 label = 'Label',
								 subtitle = 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s.',
							 }) => {
	const classes = useStyles(themeSelected)();
	const ref = useRef();
	const isShown = useOnScreen(ref);

	const parsedAnimationOffset = animationData.reduce((firstLevelAcc, firstLevelItem) => {
		const parsedData = firstLevelItem.field_message.map(i => (
			{
				title: i === "..." ? "" : i,
				offset:firstLevelItem.field_position,
				type: 'chat',
			}
		))
		parsedData.push({title: 'Read', offset: firstLevelItem.field_position, type: 'status'})
		return [...firstLevelAcc, ...parsedData]
	}, [])

	let typingCount = 0;
	let statusCount = 0;
	let bubbleCount = 0;

	const parsedAnimationDataWithStyles = parsedAnimationOffset.map((item) => {
		if(!item.title){
			typingCount +=1;
			return ({...item, textAnimation: `${animationStyle[`loadingDelay${typingCount}`]} ${animationStyle.loading}`, style: `${animationStyle[`loadingContainer`]} ${animationStyle[`loadingContainerDelay${typingCount}`]}`})
		}
		if(item.type === 'status') {
			statusCount +=1;
			return ({...item, style: `${animationStyle.readContainer} ${animationStyle[`readDelay${statusCount}`]}`})
		}
		bubbleCount += 1;
		return ({...item, style: `${animationStyle[`bubbleDelay${bubbleCount}`]} ${animationStyle.bubbleContainer}`})
	})

	return <MainContainer themeSelected={themeSelected} blockName={blockName}>
		<Grid item sm={6} xs={12} ref={ref}>
			<Grid container alignContent={'space-between'}>
				<Grid item xs={12}>
					<Grid container>
						<Grid item xs={12} className={classes.label}>{label}</Grid>
						<Grid item xs={12} sm={6} className={classes.title}>{title}</Grid>
					</Grid>
				</Grid>
				<Grid className={classes.animationMobileContainer} item xs={12}>
					<AnimationRender isShown={isShown} animationData={parsedAnimationDataWithStyles}/>
				</Grid>
				<Grid item xs={12}>
					<Grid container direction={'column'}>
						<Grid item sm={6} xs={12} className={classes.subtitle}>
							{subtitle}
						</Grid>
						<Button sm={3} xs={5} themeSelected={themeSelected} buttonName={buttonName}
								url={buttonUrl}
								className={classes.button}/>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
		<Grid className={classes.animationDesctopContainer} item xs={6}>
			<AnimationRender isShown={isShown} animationData={parsedAnimationDataWithStyles}/>
		</Grid>
	</MainContainer>
}

export default ChatAnimationModule;
