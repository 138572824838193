import {makeStyles} from "@material-ui/core";
import {themeType} from "../../Theme/ThemeSwitching";

const styles = (themeSelected) => makeStyles(theme => ({
	title: {
		fontSize: 63,
		fontWeight: 500,
		letterSpacing: -2.2,
		[theme.breakpoints.down('xs')]: {
			// ...theme.typography.subHeadline1,
			fontSize: 39
		}
	},

	cardsContainer: {
		marginTop: 84,
		[theme.breakpoints.down('xs')]: {
			// ...theme.typography.subHeadline1,
			marginTop: 32,
		}
	},

	cardItemTitle: {
		fontWeight: 500,
		...theme.typography.bodyText1,
		...themeType[themeSelected].title,
		marginTop: 16,
	},
	cardItemSubtitle: {
		...themeType[themeSelected].subtitle,
		...theme.typography.bodyText2,
		marginTop: 8,
		height: 60,
		overflow: 'hidden'
	},
	cardItemShowMore: {
		...themeType[themeSelected].subtitle,
		...theme.typography.bodyText2,
		fontWeight: 500,
		marginTop: 20,
		cursor: 'pointer',
		display:'flex'
	},
	cardItemDescription: {
		...themeType[themeSelected].subtitle,
		...theme.typography.bodyText2,
		marginTop: 28,
		position: 'relative'
	}
}))

export default styles;
