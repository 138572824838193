import React from 'react';
import {Grid} from '@material-ui/core';
// import useMainPage from 'hooks/useMainPage';
import {blockComponents} from '../../BlockConstructor/BlockNames';
import useStyles from './styles';
import {usePageMeta} from "../../hooks/usePageMeta";
// import {MembersModule} from "modules";

const MainPage = ({pageData = []}) => {
	const classes = useStyles();
	// const {pageData} = useMainPage();
	const MetaTag = usePageMeta();
	return (
		<Grid item xs={12} className={classes.container}>
			<MetaTag/>
			<div style={{height: 70, width: '100%', background: 'black'}}/>
			{/*<MembersModule/>*/}
			{pageData.map((block, index) => blockComponents.getComponent(block?.type)({ params: block }, index))}
		</Grid>
	);
};

export default MainPage;
