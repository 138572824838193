import {makeStyles} from '@material-ui/core';
import {themeType} from "../../Theme/ThemeSwitching";

// eslint-disable-next-line
export default (themeSelected) => makeStyles((theme) => ({
	container: {
		border: `1px solid #BEBEBE`,
		borderRadius: 15,
		padding: '32px 16px 24px 32px',
		marginTop: 23,
		minHeight: 204
	},
	title: {
		fontWeight: 500,
		fontSize: 27,
		lineHeight: '32px',
		boxSizing: 'border-box',
		cursor: 'pointer',
		textAlign: 'left',
		...themeType[themeSelected].title,
		[theme.breakpoints.down('sm')]: {
			fontSize: 21,
			lineHeight: '26px',
		},
	},
	location: {
		fontSize: 15,
		marginTop: 40,
		color: '#0c0c0c'
	},
	descriptionContainer: {
		height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between'
	},
	description: {
		fontSize: 15,
		lineHeight: '20px',
		// marginTop: 12,
		...themeType[themeSelected].button.subtitle,
		textAlign: 'left',
	},
	locationContainer: {
		color: theme.colors.darkBlack,
		fontSize: 15,
		letterSpacing: -0.2
	},
	tagsContainer: {
		marginTop: 22
	},
	tag: {
		background: theme.colors.darkBlack,
		color: theme.colors.white,
		padding: 15,
		borderRadius: 20,
		marginRight: 16
	},
	buttonContainer: {
		fontSize: 19,
		lineHeight: '20px',
		cursor: 'pointer',
		display: 'flex',
		justifyContent: 'flex-end',
		[theme.breakpoints.down("xs")]: {
			marginTop: 44,
		},
		'& span': {
			border: 'none',
			width: 141,
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			borderRadius: 100,
			height: 50,
			...themeType[themeSelected].button.primary,
		}
	},
	arrowIcon: {
		marginLeft: 12,
		width: 16,
		height: 16,
		...themeType[themeSelected].button.primary,
	}
}));
