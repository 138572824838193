import {useActionCreator} from "Redux/useActionCreator";
import {useEffect} from "react";
import {useHistory} from "react-router-dom";
import paths from "Routes/paths";
import {initialState} from 'Redux/Modules/Inputs/inputs-reducer'

export const useCareerMainContent = () => {
	const {filterFetchAction, updateInputsStoreAction} = useActionCreator();
	// /const {pageData} = useSelector(state => state.careers);
	const history = useHistory();

	const handleExploredMore = async () => {
		await updateInputsStoreAction({...initialState});
		history.push(paths.jobOpening)
	}

	useEffect(() => {
		filterFetchAction();
		// getCareersPageModules();
		// eslint-disable-next-line
	}, [])

	return {
		handleExploredMore,
		// pageData,
	}
}
