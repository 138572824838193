import React from "react";
import {blockNames} from 'pages/MainPage/mocks';
import {offsetTitleOptionData} from 'components/Title/Title';
import InsightsFilters from "pages/Insights/components/InsightsFilters/InsightsFilters";
import {API_URL} from 'API/constants';
import {
	CaseStudiesModule,
	PartnershipsModule,
	ArticleRowModule,
	TwoColumnsModule,
	DocumentDownload,
	CardsModule,
	VideoModule,
	QuoteSliderModule,
	StackModule,
	Animation,
	ImageTextModule,
	CareersInputArea,
	ChatAnimationModule,
	LabelModule,
	NotFoundPageCTAModule,
	MapModule,
	AwardsModule,
	CarouselModule,
	VerticalTabsModule,
	ArticleTile,
	PressListings,
	MembersModule,
  ContactUsForm,
  FindOfficeModule
} from 'modules';

import Image from 'assets/images/Image Formats.svg';
import stylesEnum from 'Theme/ThemeSwitching/theneNames';

class BlockComponents {
	[blockNames['paragraph--article_tile_expandable']] = ({params}, idx) => {
		const data = {
			title: params.field_title,
			label: params.field_section_title,
			cards: params.field_article_card_expandable.map((el) => ({
				title: el.field_title,
				description: el.field_description,
				image: el.field_image?.field_media_image?.uri?.url,
				isShownText: el.field_link_text,
				subtitle: el.field_location
			}))
		};
		return (
			<MembersModule
				blockName={idx}
				key={params.id}
				data={data}
				themeSelected={params.field_module_theme_expandable}
			/>
		);
	};
	[blockNames["paragraph--cta_not_found"]] = ({params}, idx) => <NotFoundPageCTAModule
		title={''}
		blockName={idx}
		themeSelected={''}
		buttonName={''}
		key={params.id}
		buttonUrl={''}/>;
	[blockNames["paragraph--hero_images"]] = ({params}, idx) => <Animation blockName={idx} key={params.id}
																		   pageData={params}/>;
	[blockNames["paragraph--image_and_text_stack"]] = ({params}, idx) => {
		const {
			field_title,
			field_section_title,
			field_module_theme,
			field_image_and_text = [],
			field_description,
			field_link = {}
		} = params;
		const propsData = {
			title: field_title,
			label: field_section_title,
			description: field_description,
			link: field_link,
			posts: field_image_and_text.map((i, id) => ({
				id: i.id,
				title: i.field_title,
				label: i.field_heading,
				description: i.field_description,
				offset: ((id % 2) === 0) ? 'left' : 'right',
				image: API_URL + i.field_image?.field_media_image?.uri?.url,
				layout: i.field_layout,
				link: i.field_link
			})),
		}
		return (
			<StackModule
				blockName={idx}
				key={params.id}
				stackData={propsData}
				themeSelected={field_module_theme}
			/>
		)
	};
	[blockNames['paragraph--animated_chat']] = ({params}, idx) => {
		const {field_title, field_description, field_heading, field_link, field_reply = []} = params;
		return <ChatAnimationModule
			blockName={idx}
			title={field_title}
			subtitle={field_description}
			label={field_heading}
			buttonName={field_link.title}
			buttonUrl={field_link.url || field_link.uri}
			animationData={field_reply}
			key={params.id}
		/>
	};
	[blockNames["paragraph--image_and_text_module"]] = ({params}, idx) => {
		const imageRatios = {'16_9': 0, '1_1': 1, '3_4': 2}
		const post = {
			title: params.field_title,
			description: params.field_description,
			label: params.field_heading,
			image: params.field_image?.field_media_image?.image_style_uri[imageRatios[params.field_image_ratio || '1_1']][params.field_image_ratio || '1_1'],
			imageRatio: params.field_image_ratio,
			link: params.field_link,
			imageOffset: params.field_image_alignment,
			layout: params.field_layout
		}
		return (
			<ImageTextModule
				themeSelected={params.field_module_theme || stylesEnum.azureBackground}
				blockName={idx}
				key={params.id}
				post={post}
			/>
		)
	};
	[blockNames["paragraph--insights_search_and_filter"]] = ({params}, idx) => {
		const data = {
			resetButton: params.field_filter_reset_button_label
		}
		return (
			<InsightsFilters
				themeSelected={params.field_module_theme || stylesEnum.whiteBackground}
				blockName={idx}
				key={params.id}
				data={data}
			/>
		)
	};
	[blockNames["paragraph--filters"]] = ({params}, idx) => {
		const {field_module_theme, field_include_job_offers_listing, field_column, field_link} = params;
		return (
			<CareersInputArea
				themeSelected={field_module_theme}
				isListShown={field_include_job_offers_listing}
				blockName={idx}
				key={params.id}
				columnData={field_column}
				buttonsData={field_link}
			/>
		)
	};
	[blockNames['paragraph--text_two_columns']] = ({
													   params,
													   // = () => null,
												   }, idx) => {
		const {
			field_title,
			field_section_title,
			field_column,
			field_call_to_action,
			field_module_theme,
			field_title_alignment,
			field_cta_type = 'field_cta_type'
		} = params;

		return (
			<TwoColumnsModule
				blockName={idx}
				key={params.id}
				themeSelected={field_module_theme}
				label={field_section_title}
				title={field_title}
				columnData={field_column}
				buttonsData={field_call_to_action}
				titleOffset={field_title_alignment}
				field_cta_type={field_cta_type}
			/>
		);
	};

	[blockNames['paragraph--partners']] = ({params}, idx) => {
		const {field_title, field_section_title, field_module_theme, field_images = []} = params;
		const iconsData = field_images.map(i => ({
			id: i?.field_media_image.id,
			img: i?.field_media_image?.uri?.url
		}))
		return (
			<PartnershipsModule
				blockName={idx}
				key={params.id}
				themeSelected={field_module_theme}
				partnershipsData={iconsData}
				title={field_title}
				label={field_section_title}
			/>
		);
	};

	[blockNames['paragraph--article_row']] = ({params}, idx) => {

		const {
			field_alignment,
			field_section_title,
			field_title,
			field_title_alignment,
			field_module_theme,
			field_article_card,
		} = params;

		return (
			<ArticleRowModule
				blockName={idx}
				key={params.id}
				themeSelected={field_module_theme}
				contentData={field_article_card.map(i => ({
					title: i.field_title,
					subtitle: i.field_subtitle,
					id: i.id,
					img: i.field_image?.field_media_image?.image_style_uri?.reduce((acc, val) => ({...acc, ...val}), {})[i.field_image_ratio],
					link: i.field_link?.title,
					url: i.field_link?.url || i.field_link?.uri
				}))}
				titleOffset={field_title_alignment || offsetTitleOptionData.left}
				contentOffset={field_alignment}
				label={field_section_title}
				title={field_title}

			/>
		);
	};
	[blockNames['paragraph--case_study']] = ({params}, idx) => {
		const {
			field_section_title,
			field_image,
			field_title,
			field_module_theme,
			field_case_subtitle,
			field_result = [],
			field_call_to_action = [],
			field_article_card = [],
		} =
			params;
		const additionalField = field_result.map(item => ({
			title: item.field_title,
			subtitle: item.field_description,
			img: Image
		}))

		return (
			<CaseStudiesModule
				blockName={idx}
				key={params.id}
				field_call_to_action={field_call_to_action}
				posts={[
					{
						label: field_section_title,
						title: field_title,
						img: field_image?.field_media_image?.uri?.url,
						descriptionTitle: field_case_subtitle,
						cases: additionalField,
					},
					...field_article_card.map(i => ({
						label: '',
						title: i.field_title,
						linkTitle: i?.field_link?.title,
						linkUrl: i?.field_link?.url || i?.field_link?.uri,
						img: i?.field_image?.field_media_image?.image_style_uri?.reduce((acc, val) => ({
							...acc,
							[Object.keys(val)[0]]: val[Object.keys(val)[0]]
						}), {})[i?.field_image_ratio]
					}))
				]}
				themeSelected={field_module_theme}
			/>
		);
	};

	[blockNames['paragraph--document_download']] = ({params}, idx) => {
		const reportData = {
			title: params.field_title,
			description: params.field_description,
			label: params.field_section_title,
			reports: ['Presentations', 'Financial calendar'],
			documents: params.field_documents_tab
		};

		return (
			<DocumentDownload
				blockName={idx}
				key={params.id}
				reportData={reportData}
				themeSelected={params.field_documents_download_theme}
			/>
		);
	};

	[blockNames['paragraph--use_cases']] = ({params}, idx) => {
		const cardsData = {
			title: params.field_title,
			description: params.field_description,
			label: params.field_section_title,
			cards: params.field_use_cases,
			buttonName: params.field_link?.title,
			buttonUrl: params.field_link?.url || params.field_link?.uri
		};

		return (
			<CardsModule
				blockName={idx}
				key={params.id}
				cardsData={cardsData}
				isUSP={params.field_module_type === "usps"}
				themeSelected={stylesEnum.platinumBackground}
			/>
		);
	};
	[blockNames['paragraph--global_footprint']] = ({params}, idx) => {
		const data = {
			title: params.field_title,
			label: params.field_section_title,
			description: params.field_description,
			theme: params.field_theme,
			indicators: params.field_indicators
		}
		return (
			<MapModule
				blockName={idx}
				key={params.id}
				mapData={data}
			/>
		);
	};
	[blockNames['paragraph--awards']] = ({params}, idx) => {
		const data = {
			title: params.field_title,
			label: params.field_section_title,
			awards: params.field_award.map((el) => ({
				title: el.field_title,
				description: el.field_description,
				image: el.field_image?.field_media_image?.uri?.url
			}))
		};
		return (
			<AwardsModule
				blockName={idx}
				key={params.id}
				data={data}
			/>
		);
	};
	[blockNames["paragraph--page_header"]] = ({params, headerLabelClass = ''}, idx) => {
		const {field_title, field_theme} = params;

		return <LabelModule
			themeSelected={field_theme}
			blockName={idx}
			text={field_title}
			key={params.id}
			className={headerLabelClass}
			xs={12}/>
	};
	[blockNames["paragraph--call_to_action"]] = ({params}, idx) => {
		return <NotFoundPageCTAModule
			buttonName={params.field_link?.title}
			buttonUrl={params.field_link?.url}
			key={params.id}
			blockName={idx} title={'The requested page is unavailable or may not exist.'}/>

	}
	[blockNames["paragraph--article_tile"]] = ({params}, idx) => {
		return <ArticleTile key={params.id} blockName={idx} articleData={params}
							themeSelected={params.field_module_theme || stylesEnum.whiteBackground}/>
	};
	[blockNames['paragraph--press_listing']] = ({params}, idx) => {
		return <PressListings blockName={idx} key={params.id} themeSelected={params.field_theme}/>
	}
	[blockNames['paragraph--vertical_tabs']] = ({params}, idx) => {
		const {field_title, field_section_title, field_description, field_vertical_tab = []} = params;
		const steps = field_vertical_tab.reduce((acc, val) => ({
			...acc, [val.field_tab_label]: {
				title: val.field_title || '',
				subtitle: val.field_tab_content.filter(i => i.type === 'paragraph--body').map(i => ({
					id: i.id,
					value: i.field_body.value
				}))
			}
		}), [])
		return (
			<VerticalTabsModule
				title={field_title}
				label={field_section_title}
				subtitle={field_description}
				blockName={idx}
				key={params.id}
				steps={steps}
			/>
		);
	};
	[blockNames['paragraph--carousel']] = ({params}, idx) => {
		const data = {
			title: params.field_title,
			label: params.field_section_title,
			description: params.field_description,
			images: params.field_image_with_description
		}
		return (
			<CarouselModule
				blockName={idx}
				key={params.id}
				data={data}
				themeSelected={stylesEnum.whiteBackground}
			/>
		);
	};
	[blockNames['paragraph--quote_slider']] = ({params}, idx) => {
		const quotesData = {
			quotes: params.field_quote_slide.map((el) => ({
				quote: el.field_quote,
				author: el.field_author,
				title: el.field_title,
				country: el.field_country_office,
				image: el.field_image?.field_media_image?.uri?.url,
				imageRatio: el.field_image_ratio,
				optionalLine: el.field_optional_line
			}))
		};
		return (
			<QuoteSliderModule
				blockName={idx}
				key={params.id}
				quotes={quotesData.quotes}
				themeSelected={params.field_module_theme}
			/>
		);
	};

	[blockNames['paragraph--video_module']] = ({params}, idx) => {
		const videoData = {
			title: params?.field_title,
			label: params?.field_section_title,
			titleOffset: params?.field_title_alignment,
			description: params?.field_description,
			coverImage: params?.field_video_or_image?.field_image?.field_media_image?.uri?.url,
			videoLink: params?.field_video_or_image?.field_video?.field_media_video_embed_field,
			buttonsData: params?.field_call_to_action
		};

		return (
			<VideoModule
				blockName={idx}
				key={params.id}
				videoData={videoData}
				themeSelected={params.field_module_theme}
			/>
		);
	};
	[blockNames['paragraph--hubspot_forms']] = ({params}, idx) => {
  const data = { title: params.field_title, forms: params.field_hubspot_forms };

		return (
      <ContactUsForm
        blockName={idx}
        key={params.id}
        data={data}
        themeSelected={params.field_module_theme}
      />
    );
	};
	[blockNames['paragraph--find_your_office']] = ({params}, idx) => {
    const data = {
      title: params.field_title,
      description: params.field_description
    };
		return (
      <FindOfficeModule
        blockName={idx}
        key={params.id}
        data={data}
        themeSelected={params.field_module_theme}
      />
    );
	};

	getComponent(value) {
		if (this[value]) {
			return this[value];
		}
		return () => null;
	}
}

export const blockComponents = new BlockComponents();
