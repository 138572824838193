import {useState} from 'react';

import {Grid} from '@material-ui/core';

import DropdownArrow from 'assets/icons/DropdownArrow';
import colors from 'Theme/colors';

import {MappedMenu} from './MappedMenu';

import useStyles from './styles';

const HeaderItem = ({
						menu = {},
						themeSelected,
						classesExternal,
						handleSelectDropdownItem,
						handleRedirectOnTab,
						left = true,
						dropdownContainerExternal,
						divider,
						triangle,
						handleHoverAction,
						id,
						hoveredItemId,
						isSqueezed = true,
						icon = null,
						...rest
					}) => {
	const classes = useStyles(themeSelected)();
	const [isItemHovered, setIsItemHovered] = useState(false);

	const mouseEnter = () => {
		setIsItemHovered(true);
		handleHoverAction(id)();
	};
	const mouseLeave = () => {
		setIsItemHovered(false);
		handleHoverAction('')();
	};
	const dropdownPosition = left ? {left: 0} : {right: -100};
	return (
		<Grid
			onMouseEnter={mouseEnter}
			onMouseLeave={mouseLeave}
			className={classesExternal.itemContainer}
			item
			xs={2}
			{...rest}
			key={menu.id}
		>
			<Grid
				container
				style={{position: 'relative', width: isSqueezed ? 'max-content' : 'unset'}}
				alignItems={'center'}
				justifyContent={'flex-end'}
			>
				{icon && icon}

				<Grid
					item
					component={'span'}
					className={`${classes.title} ${
						hoveredItemId && id !== hoveredItemId && classes.selectedAnotherMenu
					}`}
					onClick={handleRedirectOnTab(menu)}
				>
					{menu.title}
				</Grid>
				{menu.dropdown?.length && (
					<Grid
						item
						component={() => (
							<DropdownArrow
								fill={
									hoveredItemId && id !== hoveredItemId ? colors.gray : 'unset'
								}
								className={`${classes.dropdownArrow} ${
									isItemHovered && classes.dropdownArrowRotated
								} ${
									hoveredItemId &&
									id !== hoveredItemId &&
									classes.selectedAnotherMenu
								}`}
							/>
						)}
					/>
				)}
			</Grid>
			{isItemHovered && !!menu.dropdown?.length && (
				<div className={`${classes.container} ${dropdownContainerExternal}`}>
					<div className={classes.layout}/>
					<MappedMenu
						divider={divider}
						triangle={triangle}
						dropdownPosition={dropdownPosition}
						isItemHovered={isItemHovered}
						menu={menu}
						classes={classes}
						handleSelectDropdownItem={handleSelectDropdownItem}
					/>
				</div>
			)}
		</Grid>
	);
};

export default HeaderItem;
