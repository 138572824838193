import {useContext, useEffect, useState} from "react";
import {useHistory, useLocation} from "react-router-dom";
import {useActionCreator} from "Redux/useActionCreator";
import {useSelector} from "react-redux";
import generalReselect from "Redux/Modules/General/reselect";
import stylesEnumObject from "Theme/ThemeSwitching/theneNames";
import {Context} from "Context/Context";
import PageLevelService from "helpers/PageLevelService";

const useHeaderBurger = () => {
	const [toggled, setToggled] = useState(false);
	const history = useHistory();
	const location = useLocation();
	const [theme, setTheme] = useState(stylesEnumObject.blackBackground);
	const {generalHeaderFetchMainContentAction} = useActionCreator();
	const {header, headerAdditional} = useSelector(generalReselect.getGeneralReselectedStore);
	const {backgroundData} = useContext(Context);

	useEffect(() => {
		if (toggled) {
			document.body.style.overflow = 'hidden'
		} else {
			document.body.style.overflow = 'unset'
		}
	}, [toggled]);

	useEffect(() => {
		if (backgroundData?.themeSelected) {
			setTheme(backgroundData?.themeSelected)
		}
	}, [backgroundData])

	const pageLevelService = new PageLevelService(location.pathname);

	useEffect(() => {
		if(pageLevelService.isFirstLevel){
			setTheme(stylesEnumObject.blackBackground)
		}else{
			setTheme(stylesEnumObject.whiteBackground)
		}
		// eslint-disable-next-line
	}, [location.pathname]);
	// eslint-disable-next-line
	useEffect(generalHeaderFetchMainContentAction, [])

	const handleRedirectPage = (path) => () => {
		setToggled(false);
		if (typeof path !== 'string') {
			history.push(`${path.path}${path.anchor ? `#${path.anchor}` : ''}`)
		} else {
			history.push(path)
		}
	}

	const handleToggleBurger = () => setToggled(prev => !prev)
	return {
		toggled,
		handleToggleBurger,
		headerData: header,
		handleRedirectPage,
		theme,
		headerAdditional
	}
}

export default useHeaderBurger;
