import React, {useState} from "react";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StepContent from "@material-ui/core/StepContent";
import StepperIcon from 'assets/icons/Stepper.svg';
import StepperSelectedIcon from 'assets/icons/StepperSelected.svg';
import {Grid} from "@material-ui/core";

const RenderItem = ({data, classes}) => {
	const {title, subtitle} = data;
	return <Grid container className={classes.stepperItemContentContainer}>
		<Grid item xs={9} className={classes.stepperTitle}>
			{title}
		</Grid>
		<Grid item xs={9} className={classes.stepperSubtitle}>
			{subtitle.map(i => <div key={i.id} dangerouslySetInnerHTML={{__html:i.value}}/>)}
		</Grid>
	</Grid>
}

const VerticalLinearStepper = ({classes, steps}) => {
	const [state, setState] = useState({
		activeStep: 0,
	})

	const handleChange = (name, value) => () => {
		setState(prev => ({...prev, [name]: value}))
	};

	const {activeStep} = state;

	return (
		<div className={classes.stepperContentContainer}>
			<Stepper activeStep={activeStep} orientation="vertical" style={{position:'relative'}}>
				{Object.entries(steps).map(([label, customStep], idx) => (
					<Step key={label}>
						<StepLabel icon={<div className={classes.stepperIcon}>{idx === activeStep ? <img src={StepperSelectedIcon} alt={'#'}/> :
							<img src={StepperIcon} alt={'#'}/>}</div>}
								   onClick={handleChange('activeStep', idx)}>{label}</StepLabel>
						<StepContent>
							<RenderItem data={customStep} classes={classes}/>
						</StepContent>
					</Step>
				))}
			</Stepper>
		</div>
	);

}

export default VerticalLinearStepper;
