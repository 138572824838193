import {Grid, makeStyles} from '@material-ui/core';
import {themeType} from '../../Theme/ThemeSwitching';
import {useLinkPush} from "../../hooks/useLinkPush";

const useStyles = (themeSelected) =>
	makeStyles((theme) => ({
		listItemTitle: {
			...themeType[themeSelected].title,
			fontWeight: 500,
			fontSize: 27,
			letterSpacing: -0.2,
			marginTop: 24
		},
		listItemSubtitle: {
			...themeType[themeSelected].subtitle,
			fontSize: 15,
			letterSpacing: -0.2,
			marginTop: 12
		},

		listItemReadMore: {
			...themeType[themeSelected].readMore,
			marginTop: 24,
			fontSize: 15,
			fontWeight: 'bold',
			letterSpacing: -0.2,
			cursor: 'pointer',
		}
	}));

const ImageDescriptionCard = ({data, themeSelected}) => {
	const classes = useStyles(themeSelected)();
	const handleRedirect = useLinkPush(data?.url);
	return (
		<Grid item sm={12}>
			<Grid container>
				<Grid item component={'img'} src={data.img} xs={12}/>
				<Grid item xs={12} className={classes.listItemTitle}>
					{data.title}
				</Grid>
				<Grid item xs={8} className={classes.listItemSubtitle}>
					{data.subtitle}
				</Grid>
				<Grid item xs={12} className={classes.listItemReadMore} onClick={handleRedirect}>
					{data.link}
				</Grid>
			</Grid>
		</Grid>
	);
};

export default ImageDescriptionCard;
