import React, { useEffect, useMemo } from 'react';
import { useCountUp } from 'react-countup';
import { Grid } from '@material-ui/core';

import MapLight from 'assets/images/Map_Light.svg';
import MapDark from 'assets/images/Map_Dark.svg';

import { useMapModule } from './useMapModule';
import useStyles from './style';

const Counter = ({ classes, field_title, field_number, isHovered }) => {
  const { start } = useCountUp({
    ref: field_title,
    end: field_number,
    duration: 1.5,
    decimals: 0,
    thousandsSeparator: ',',
  });

  useEffect(() => {
    if (isHovered) {
      start();
    }
  }, [isHovered, start]);

  return (
    <Grid item className={classes.itemWrapper}>
      <Grid item component='p' className={classes.label}>
        {field_title}
      </Grid>
      <Grid item className={classes.counter} id={field_title} />
    </Grid>
  );
};

const MapModule = ({ themeSelected, mapData, blockName = 'map-module' }) => {
  const { title = '', description = '', theme, indicators } = mapData;

  const { mapRef, isHovered } = useMapModule(blockName, themeSelected);

  const colorProps = useMemo(() => {
    if (theme === 'dark') {
      return { color: '#fff', background: '#000', labelColor: '#E8E8E8' };
    }
    return { color: '#0C0C0C', background: '#fff', labelColor: '#464646' };
  }, [theme]);

  const classes = useStyles(themeSelected, colorProps)();

  return (
    <Grid ref={mapRef} container className={classes.container}>
      <Grid
        item
        component='img'
        src={theme === 'dark' ? MapDark : MapLight}
        className={classes.image}
      />
      <Grid item className={classes.mainContainer}>
        <Grid container className={classes.textContainer}>
          <Grid item component='p' className={classes.title}>
            {title}
          </Grid>
          <Grid item component='p' className={classes.description}>
            {description}
          </Grid>
        </Grid>
        <Grid container className={classes.numbersContainer} justifyContent='space-between'>
          {indicators.map((indicator) => (
            <Counter
              key={indicator.field_number}
              classes={classes}
              isHovered={isHovered}
              {...indicator}
            />
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default MapModule;
