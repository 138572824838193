import {makeStyles} from '@material-ui/core';
import {themeType} from "../../Theme/ThemeSwitching";

// eslint-disable-next-line import/no-anonymous-default-export
export default (themeSelected) => makeStyles((theme) => ({
	inputContainer: {
		marginTop: 9
	},
	inputLabel: {
		fontStyle: 'normal',
		fontWeight: 'normal',
		fontSize: 18,
		textAlign: 'left'
	},
	input: {
		width: '100%',
		...themeType.getThemeData(themeSelected).background,
		border: '1px solid #BEBEBE',
		borderRadius: 100,
		height: 74,
		margin: '25px 0',
		fontSize: 19,
		...themeType.getThemeData(themeSelected).title,
		padding: '25px 27px 25px 64px',
		'&::placeholder': {
			fontSize: 19,
			[theme.breakpoints.down('xs')]:{
				fontSize: 15,
			}
		},
		[theme.breakpoints.down('xs')]:{
			height: 52,
			padding: '16px 0 16px 40px',
			margin: 0
		}
	},
	icon: {
		paddingLeft: 6, width: 60, ...themeType.getThemeData(themeSelected).subtitle
	}
}));
