import React from 'react';
import {Grid} from '@material-ui/core';

// import {useAboutsUs} from './useAboutsUs';
import {blockComponents} from "BlockConstructor/BlockNames";
import {usePageMeta} from "../../hooks/usePageMeta";

const Industry = ({pageData = []}) => {

	// const {pageData} = useAboutsUs();
	const MetaTag = usePageMeta();

	return (
		<Grid container justifyContent={'center'}>
			<MetaTag/>
			<Grid item xs={12}>
				<div style={{ height: 70, width: '100%'}} />
				{!!pageData.length && pageData.map((block, index) => blockComponents.getComponent(block?.type)({params: block}, index))}
			</Grid>
		</Grid>
	);
};

export default Industry;
