import React, { useEffect } from 'react';

import { useContext } from 'react';
import { ModalContext } from 'Context/ModalContext';
import { modalNamesEnum } from 'Context/ModalContext';
import stylesEnumObject from 'Theme/ThemeSwitching/theneNames';

const CoockieConsent = () => {
  const { handleSetModal } = useContext(ModalContext);

  let cookie = localStorage.getItem('cookie-banner');

  useEffect(() => {
    if (cookie !== 'true') {
      handleSetModal({
        modalName: modalNamesEnum.cookieConsentModal,
        themeSelected: stylesEnumObject.blackBackground
      })();
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <></>;
};

export default CoockieConsent;
