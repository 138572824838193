class Paths {
  main = '/';
  start = '/start';
  eCommerce = '/e-commerce';
  servicesAndUtilities = '/services_and_utilities';
  telcoAndTables = '/telco_and_tables';
  careers = '/en/careers';
  insights = `/en/insights`;
  contact = `/contact_us`;
  jobOpening = `${this.careers}/job_opening`;
  workingTranscom = `${this.careers}/working_transcom`;
  diversity = `${this.careers}/diversity`;
  searchResults = `${this.careers}/search_results`;
  jobCardDetail = (id = ':id') => `${this.jobOpening}/${id}`;
  onlineRetail = `${this.eCommerce}/online_retail`;
  platformSocialMedia = `${this.eCommerce}/platform_and_social_media`;
  jobStackPost = (id = ':id') => `${this.careers}/${id}`;
  insightsDetail = (id = ':id') => `${this.insights}/${id}`;
  aboutUs = '/en/about-us';
}

export default new Paths();
