import {memo} from 'react';
import {v4 as uuidv4} from 'uuid';
import {Grid} from '@material-ui/core';
import colors from 'Theme/colors';
import Label from 'assets/images/Label';
import GlobalIcon from 'assets/icons/GlobalIcon';
import PhoneIcon from 'assets/icons/PhoneIcon';
import {HeaderItem} from './components/HeaderItem';
import path from '../../Routes/paths';
import useHeader from './useHeader';
import useStyles from './styles';


const globalId = uuidv4();
const contactId = uuidv4();

const Header = memo(() => {
	const {
		headerListData,
		handleSelectDropdownItem,
		handleRedirectOnTab,
		hiddenStyle,
		handleToggleIsHeaderHovered,
		handleCheckItemId,
		hoveredItemId,
		theme,
		headerAdditional = [],
		toggleHeader,
		enableScrollAnimation,
		navigationCareers,
		isTypeCareers,
	} = useHeader();
	const classes = useStyles(theme)();

	return (
		<Grid
			container
			component={'nav'}
			onMouseEnter={handleToggleIsHeaderHovered}
			onMouseLeave={handleToggleIsHeaderHovered}
			className={`${classes.container} ${(toggleHeader) && classes.showingContainer} ${enableScrollAnimation && (!hiddenStyle ? classes.showByScrollingUp : classes.hideByScrollingDown)}`}
			justifyContent={'center'}
		>
			<Grid item className={`${classes.content}`}>
				<Grid container justifyContent={'space-between'} alignItems={'center'}>
					<Grid
						item
						xs={2}
						className={`${classes.label}`}
						onClick={handleSelectDropdownItem(path.main)}>
						<Grid
							container
							className={`${classes.imgLogo}`}
						>
							<Grid item xs={12} sm={10} component={Label}/>
						</Grid>
					</Grid>
					<Grid
						item
						xs={6}

					>
						<Grid
							container
							alignItems={'center'}
							// justifyContent={'space-between'}
						>
							{headerListData.map((menu) => (
								<HeaderItem
									handleHoverAction={handleCheckItemId}
									hoveredItemId={hoveredItemId}
									themeSelected={theme}
									handleSelectDropdownItem={handleSelectDropdownItem}
									menu={menu}
									id={menu.id}
									handleRedirectOnTab={handleRedirectOnTab}
									classesExternal={classes}
									key={menu.id}
								/>
							))}
						</Grid>
					</Grid>
					<Grid
						item
						xs={3}
					>
						<Grid container justifyContent={'flex-end'}>
							<Grid item xs={5} className={classes.globalContainer}>
								<Grid
									container
									alignItems={'center'}
									style={{position: 'relative'}}
								>
									<HeaderItem
										sm={12}
										id={headerAdditional[0]?.id}
										handleHoverAction={handleCheckItemId}
										hoveredItemId={hoveredItemId}
										themeSelected={theme}
										handleSelectDropdownItem={handleSelectDropdownItem}
										menu={headerAdditional[0]}
										classesExternal={classes}
										handleRedirectOnTab={handleRedirectOnTab}
										dropdownContainerExternal={classes.contactContainer}
										divider={false}
										triangle={false}
										isSqueezed={false}
										icon={<GlobalIcon
											className={classes.globalIconImg}
											fill={
												hoveredItemId && globalId !== hoveredItemId
													? colors.gray
													: 'unset'
											}
										/>}
									/>

								</Grid>
							</Grid>
							<Grid item xs={5} className={classes.globalContainer}>
								<Grid
									container
									alignItems={'flex-end'}
									style={{position: 'relative'}}
								>
									<HeaderItem
										sm={12}
										id={headerAdditional[1]?.id}
										handleHoverAction={handleCheckItemId}
										hoveredItemId={hoveredItemId}
										themeSelected={theme}
										handleSelectDropdownItem={handleSelectDropdownItem}
										menu={headerAdditional[1]}
										classesExternal={classes}
										handleRedirectOnTab={handleRedirectOnTab}
										dropdownContainerExternal={classes.contactContainer}
										isSqueezed={false}
										icon={<PhoneIcon
											className={classes.globalIconImg}
											fill={
												hoveredItemId && contactId !== hoveredItemId
													? colors.gray
													: 'unset'
											}
										/>}
									/>

								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</Grid>

			{isTypeCareers && <Grid item xs={12} className={classes.subNavContainer}>
				<Grid container justifyContent={'flex-end'} style={{marginRight: -18}}>
					{navigationCareers.map((tab) => (
						<Grid item className={classes.linkItem} key={tab.id}>
				    <span
						className={classes.linkText}
						onClick={handleSelectDropdownItem(tab.path)}
					>
				      {tab.title}
				    </span>
						</Grid>
					))}
				</Grid>
			</Grid>}
		</Grid>
	);
});

export default Header;
