import {makeStyles} from "@material-ui/core";
import { themeType } from 'Theme/ThemeSwitching';

const styles = (themeSelected) => makeStyles(theme => ({
	title: {
		fontStyle: 'normal',
		fontWeight: 500,
    fontSize: 63,
    lineHeight: '72px',
    ...themeType[themeSelected].title
	},
  articleContainer: {
    marginTop: 63
  },
  articleTitle: {
    fontSize: 19,
    ...themeType[themeSelected].title
  },
  articleDescription: {
    fontSize: 15,
    ...themeType[themeSelected].description
  },
  articleImage: {
    width: '100%',
  }
}))


export default styles;
