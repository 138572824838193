import React from 'react';
import { Grid, Typography } from '@material-ui/core';

import ReadMoreButton from 'components/ReadMoreButton/ReadMoreButton';

import useStyles from './style';
import {MainContainer} from "../../components";

const getImageClassName = (classes, imageRatio) => {
  switch (imageRatio) {
    case '16_9':
      return classes.postImage;
    case '3_4':
      return classes.toothImage;
    case '1_1':
      return classes.squaredImage;

    default:
      return classes.postImage;
  }
};

const ImageTextModule = ({ post, themeSelected, blockName }) => {
  const { image, label, title, description, link, imageRatio, imageOffset = 'right', layout = 'layout_a' } = post;
  const classes = useStyles(themeSelected, { imageOffset, layout, link })();

  const imageClassName = getImageClassName(classes, imageRatio);
  return (
    <MainContainer
      container
      justifyContent='space-between'
      direction={imageOffset === 'right' ? 'row-reverse' : 'row'}
      className={classes.postContainer}
      themeSelected={themeSelected}
      blockName={blockName}
    >
      <Grid item sm={7} className={classes.imageContainer}>
        <Grid component='img' className={imageClassName} src={image} alt={'#'} />
      </Grid>
      <Grid sm={5} item className={classes.post}>
        <Grid container>
          <Typography variant='body2' className={classes.postLabel}>
            {label}
          </Typography>
          <Grid component='p' className={classes.postTitle}>
            {title}
          </Grid>
        </Grid>
        <Grid container>
          <Typography variant='body1' className={classes.postDescription}>
            {description}
          </Typography>
          {link && <ReadMoreButton themeSelected={themeSelected} title={link.title} />}
        </Grid>
      </Grid>
    </MainContainer>
  );
};

export default ImageTextModule;
