import {UPDATE_CAREERS_STATE_TYPE} from "./careers-type";

export const initialState = {
	jobsList: [],
	certainJob: {},
	certainJobData: {},
	foundJobs: [],
	wasSearching: false,
	meta: {count: 0},
	itemsPerPage: 10,
	pageOffset: 0,
	foundListOffset: 0,
	metaSearch: {count: 0},
	pageData: [],
	navigationCareers: [],
	openedPost: {}
}

export const careersReducer = (state = {...initialState}, action) => {
	switch (action.type) {
		case UPDATE_CAREERS_STATE_TYPE: {
			return {...state, ...action.payload}
		}
		default: {
			return state
		}
	}
}
