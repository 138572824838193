import {Grid, makeStyles} from "@material-ui/core";
import {Button} from "components";
import stylesEnumObject from "Theme/ThemeSwitching/theneNames";
import {useThemeToggleByScroll} from "hooks/useThemeToggleByScroll";
import {themeType} from "Theme/ThemeSwitching";

const useStyles = (themeSelected) => makeStyles(theme => ({
	container: {
		...themeType.getThemeData(themeSelected).background,
		...themeType.getThemeData(themeSelected).title,
		padding: '36px 36px 72px 36px',
		[theme.breakpoints.down('sm')]:{
			fontSize: 17,
			padding: '72px 16px 106px 16px',
		}
	},
	title: {
		marginBottom: 72,
		fontWeight: 500,
		fontSize: 19,
		letterSpacing: -0.4,
		[theme.breakpoints.down('sm')]:{
			fontSize: 17,
		}
	}
}))

const NotFoundPageCTAModule = ({
								   title = 'The requested page is unavailable or may not exist.',
								   buttonName = 'Return to homepage',
								   buttonUrl = '',
								   themeSelected = stylesEnumObject.whiteBackground,
								   blockName = 'page-not-found-cta'
							   }) => {
	const {ref} = useThemeToggleByScroll({blockName, themeSelected});
	const classes = useStyles(themeSelected)()
	return (
		<Grid container ref={ref} className={classes.container}>
			<Grid item sm={12} xs={10} className={classes.title}>
				{title}
			</Grid>
			<Button buttonName={`${buttonName}`} url={buttonUrl} themeSelected={themeSelected} xs={9} sm={2}/>
		</Grid>
	)
}

export default NotFoundPageCTAModule;
