import { makeStyles } from '@material-ui/core';
import { themeType } from 'Theme/ThemeSwitching';

const styles = (themeSelected, props) =>
  makeStyles((theme) => {
    return {
      container: {
        position: 'fixed',
        bottom: 0,
        width: '100%',
        minWidth: 200,
        height: 228,
        zIndex: 100,
        padding: 36,
        color: '#fff',
        textAlign: 'left',
        background: '#464646',
        [theme.breakpoints.down('md')]: {
          padding: '24px 16px'
        },
        [theme.breakpoints.down('xs')]: {
          height: 'auto',
          top: 'auto',
          bottom: 0
        },
      },
      contentContainer: {
        ...themeType.getThemeData(themeSelected).title
      },
      menuOpened: {
        display: 'block',
        transition: 'all .5s ease-in-out !important'
      },
      buttonsContainer: {
        alignItems: 'center'
      },
      menuClosed: {
        display: 'none',
        transition: 'all .5s ease-in-out !important'
      },
      cookieText: {
        fontSize: 15,
        fontWeight: 400,
        letterSpacing: -0.2,
        marginBottom: 48,
        lineHeight: '20px',
        '& span': {
          textDecoration: 'underline'
        },
        [theme.breakpoints.down('sm')]: {
          marginBottom: 25
        },
      },
      acceptButton: {
        border: 'none',
        background: '#FFB800',
        cursor: 'pointer',
        borderRadius: 50,
        marginRight: 25,
        maxWidth: 200,
        height: 46,
        color: '#0C0C0C',
        fontSize: 17,
        fontWeight: 500,
        padding: '0 10px',
        transition: 'all 0.35s ease-in-out',
        '&:hover': {
          background: '#c08b01',
          color: '#535353'
        },
        [theme.breakpoints.down('lg')]: {
          minWidth: 160,
        },
      },
      necessaryButton: {
        border: '1px solid #7d7d7d',
        background: 'transparent',
        cursor: 'pointer',
        borderRadius: 50,
        marginRight: 25,
        maxWidth: 225,
        height: 46,
        color: '#fff',
        fontSize: 17,
        fontWeight: 500,
        padding: '0 10px',
        transition: 'all 0.35s ease-in-out',
        '&:hover': {
          background: '#7d7d7d'
        },
        [theme.breakpoints.down('lg')]: {
          minWidth: 175,
          [theme.breakpoints.down('xs')]: {
            margin: '20px 0'
          },
        },
      },
      settingsButton: {
        background: 'transparent',
        border: 'none',
        cursor: 'pointer',
        color: '#fff',
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        marginLeft: 'auto',
        fontSize: 15,
        fontWeight: 500,
        '& svg': {
          marginRight: 5
        },
        [theme.breakpoints.down('sm')]: {
          minWidth: 200
        },
        [theme.breakpoints.down('xs')]: {
          justifyContent: 'flex-start',
          margin: '0 auto 0 0'
        },
      }
    };
  });

export default styles;
