import React, {useRef} from 'react';
import {Grid} from '@material-ui/core';

import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import useStyles from './style';
import stylesEnumObject from "../../Theme/ThemeSwitching/theneNames";
import {handleLimitHeightOfDescription} from "../../helpers/handleLimitHeightOfDescription";

const JobShortCard = ({card, handleSelectCard, themeSelected = stylesEnumObject.whiteBackground}) => {
	const classes = useStyles(themeSelected)();
	// const {tags = []} = card;

	const country = card.relationships.country?.attributes.name;
	const city = card.relationships.field_taxonomy_city?.attributes.name;
	const job_type = card.relationships.job_type?.attributes.name;

	const ref = useRef(null)

	const handleSelect = () => {
		// window.open(card.attributes.link.uri, '_blank').focus();
		handleSelectCard(card)()
		window.dataLayer.push({
			'event': 'job_application',
			'ec': 'job_application',
			'ea': 'read_job_ad',
			'el': card?.attributes?.title || '',
			'ev': 0,
			'cd2_location': `${country}, ${city}` || '',
			'cd3_job_type': job_type || '',
		});
	};
	return (
		<Grid item md={12} className={classes.container}>
			<Grid container alignItems='flex-end' alignContent={'space-between'}
				  justifyContent='space-between'>
				<Grid item md={12} className={classes.descriptionContainer}>
					<Grid container className={classes.title}>
						<Grid item xs={12} onClick={handleSelectCard(card)}>
							{card?.attributes?.title}
						</Grid>
						<Grid ref={ref} item xs={10} className={classes.description}
							  style={{height: handleLimitHeightOfDescription(ref), overflow: 'hidden'}}
							  dangerouslySetInnerHTML={{__html: `${card?.attributes?.body.value}`}}/>
					</Grid>
					{/*{!!tags?.length &&<Grid container className={classes.tagsContainer}>*/}
					{/*	{tags.map((tag) => (*/}
					{/*		<Grid key={tag.id} item className={classes.tag}>*/}
					{/*			{tag.title}*/}
					{/*		</Grid>*/}
					{/*	))}*/}
					{/*</Grid>}*/}
				</Grid>
			</Grid>
			<Grid container alignItems={'flex-end'} justifyContent={'space-between'}>
				<Grid item sm={10} xs={12} className={classes.locationContainer}>
					{`${country}, ${city}`}
				</Grid>
				<Grid
					item
					sm={2}
					className={classes.buttonContainer}
					onClick={handleSelect}
				>
					<span>Apply <ArrowRightAltIcon className={classes.arrowIcon}/></span>
					{/*Apply <Grid component='img' src={ArrowIcon} className={classes.arrowIcon}/>*/}
				</Grid>
			</Grid>
		</Grid>
	);
};

export default JobShortCard;
