import {Grid} from '@material-ui/core';

import {ButtonsListContainer, Title, MainContainer} from 'components';

import useStyles from './style';
import React from 'react';

const TwoColumnsModule = ({
							  title,
							  themeSelected = '',
							  label,
							  columnData = [],
							  titleOffset,
							  titleGrid,
							  titleClassName,
							  buttonsData = [],
							  blockName,
						  }) => {
	const classes = useStyles(themeSelected)();
	return (
		<MainContainer
			themeSelected={themeSelected}
			blockName={blockName}
		>
			<Grid item xs={12} sm={12}>
				<Title
					offsetOption={titleOffset}
					title={title}
					label={label}
					themeSelected={themeSelected}
					className={titleClassName || (buttonsData.length > 1 ? classes.smallTitle : classes.title)}
					titleGrid={titleGrid}
				/>
				<Grid container justifyContent={'center'} className={classes.contentContainer}>
					<Grid item md={8}>
						<Grid container justifyContent={'flex-start'}>
							{columnData.map((column, idx) => (
								<Grid
									className={classes.column}
									item
									xs={12}
									sm={columnData.length > 1 ? 6 : 12}
									key={idx}
								>
									{column}
								</Grid>
							))}
						</Grid>
					</Grid>
				</Grid>
				<Grid container justifyContent={'center'}>
					<Grid item xs={12} sm={8} md={8} className={classes.buttonContainer}>
						<ButtonsListContainer
							buttonsData={buttonsData}
							themeSelected={themeSelected}
						/>
					</Grid>
				</Grid>

			</Grid>
		</MainContainer>
	);
};

export default TwoColumnsModule;
