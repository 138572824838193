import {makeStyles} from "@material-ui/core";
import {themeType} from "Theme/ThemeSwitching";

const styles = (themeSelected) => makeStyles(theme => ({
	articleContainer: {
		paddingLeft: 60,
		height: '100%',
		[theme.breakpoints.down('sm')]: {
			paddingLeft: 39,
		},
		[theme.breakpoints.down('xs')]: {
			paddingLeft: 0,
		},
	},
	title: {
		fontFamily: 'IBM Plex Sans',
		display: 'inline-block',
		fontStyle: 'normal',
		fontWeight: 500,
		fontSize: 39,
		letterSpacing: -0.9,
		[theme.breakpoints.down('sm')]: {
			fontSize: 30,
			letterSpacing: -0.7,
		},
		[theme.breakpoints.down('xs')]: {
			marginTop: 10,
			fontSize: 30,
			letterSpacing: -0.7,
		},
	},
	subtitle: {
		fontFamily: 'IBM Plex Sans',
		fontStyle: 'normal',
		fontWeight: 'normal',
		fontSize: 19,
		letterSpacing: -0.4,
		...themeType[themeSelected].subtitle,
		[theme.breakpoints.down('sm')]: {
			marginTop: 44,
			fontSize: 17,
			letterSpacing: -0.4,
		},
		[theme.breakpoints.down('xs')]: {
			marginTop: 40,
		},
	},
	readMore: {
		...themeType[themeSelected].readMore,
		fontFamily: 'IBM Plex Sans',
		fontStyle: 'normal',
		fontWeight: 'normal',
		fontSize: 15,
		letterSpacing: -0.2,
		marginTop: 20,
	}
}))

export default styles;
