import API from 'API'
import {UPDATE_MAIN_PAGE_STORE_ACTION} from "./main-page-type";


class MainPageActions {
	updateStoreData = (payload) => ({
		type: UPDATE_MAIN_PAGE_STORE_ACTION,
		payload
	});

	getPageData = () => {
		return async (dispatch, store, query) => {

			const res = await API.mainPageApi.getData()
			const queryData = query.config
			const updatedData = res.data.included.map(i => ({...i, attributes: {...i.attributes, type: i.type}}))
			const data = queryData.normalize({data: updatedData}, {
				include: [
					'field_sections','field_sections.field_documents_tab','field_sections.field_documents_tab.field_documents_tab_content',"field_sections.field_use_cases",'field_sections.field_quote_slide,field_sections.field_result','field_sections.field_image.field_media_image'
				],
			})
			dispatch(this.updateStoreData({pageData: data.data}))
		}
	}
}

export default new MainPageActions();
