export const BASE_URL = process.env.REACT_APP_BASE_URL;
export const API_URL = process.env.REACT_APP_URL;
export const FILTERS_COUNTRIES = `${BASE_URL}/taxonomy/country`;
export const FILTERS_CITIES = `${BASE_URL}/taxonomy/city`;
export const FILTERS_JOB_TYPES = `${BASE_URL}/taxonomy/job-type`;
export const FILTERS_LANGUAGES_TYPES = `${BASE_URL}/taxonomy/language`;

export const FOOTER_URL = `${BASE_URL}/menu_items/footer?fields[menu_link_content--menu_link_content]=title,url,parent,weight`;
export const HEADER_MAIN_CONTENT_URL = `${BASE_URL}/menu_items/main?fields[menu_link_content--menu_link_content]=title,url,parent,weight,options`;
export const HEADER_ADDITIONAL_DATA_URL = `${BASE_URL}/menu_items/header-secondary-menu?fields[menu_link_content--menu_link_content]=title,url,parent,weight`;
export const FOOTER_SOCIAL_MEDIA_ICONS = `${BASE_URL}/block/block?filter[drupal_internal__id]=socialmedialinks`;

export const MAIN_PAGE_BLOCK_URL = `${BASE_URL}/node/homepage?include=field_sections,field_sections.field_hero_images.field_media_image,field_sections.field_images,field_sections.field_images.field_media_image,field_sections.field_documents_tab,field_sections.field_documents_tab.field_documents_tab_content,field_sections.field_documents_tab.field_documents_tab_content.field_documents.field_media_file,field_sections.field_use_cases,field_sections.field_quote_slide,field_sections.field_result,field_sections.field_image.field_media_image,field_sections.field_video_or_image,field_sections.field_video_or_image.field_video,field_sections.field_video_or_image.field_image,field_sections.field_video_or_image.field_image.field_media_image,field_sections.field_reply,field_sections.field_indicators`;
export const CAREERS_PAGE_BLOCK = `${BASE_URL}/node/career?include=field_sections,field_sections.field_image_and_text,field_sections.field_image_and_text.field_image,field_sections.field_image_and_text.field_image.field_media_image,field_sections.field_article_card,field_sections.field_quote_slide,field_sections.field_image_with_description,field_sections.field_indicators,field_sections.field_award.field_image,field_sections.field_award.field_image.field_media_image`;
export const CAREERS_NAVIGATION = `${BASE_URL}/menu_items/career-navigation?fields[menu_link_content--menu_link_content]=title,url,parent,weight,options`;
export const GET_CONTACT_US_COUNTRIES = `${BASE_URL}/node/locations?filter[location-type][condition][path]=field_location_type&filter[location-type][condition][operator]==&filter[location-type][condition][value]=country`;
export const GET_ABOUT_US_PAGE = `${BASE_URL}/node/about_us?filter[city][condition][path]=field_press_article&filter[city][condition][operator]==&filter[city][condition][value]=1&include=field_sections,field_sections.field_article_card,field_sections.field_article_card.field_image,field_sections.field_article_card.field_image.field_media_image,field_sections.field_documents_tab,field_sections.field_documents_tab.field_documents_tab_content,field_sections.field_documents_tab.field_documents_tab_content.field_documents.field_media_file,field_sections.field_image_and_text,field_sections.field_image_and_text.field_image,field_sections.field_image_and_text.field_image.field_media_image,field_sections.field_images,field_sections.field_images.field_media_image,field_sections.field_main_content,field_sections.field_vertical_tab,field_sections.field_vertical_tab.field_tab_content`;
export const GET_ABOUT_US_PRESS_LISTINGS = `${BASE_URL}/node/about_us?filter[city][condition][path]=field_press_article&filter[city][condition][operator]==&filter[city][condition][value]=0&include=field_sections,field_sections.field_article_card,field_sections.field_article_card.field_image,field_sections.field_article_card.field_image.field_media_image,field_sections.field_documents_tab,field_sections.field_documents_tab.field_documents_tab_content,field_sections.field_documents_tab.field_documents_tab_content.field_documents.field_media_file,field_sections.field_image_and_text,field_sections.field_image_and_text.field_image,field_sections.field_image_and_text.field_image.field_media_image,field_sections.field_images,field_sections.field_images.field_media_image`;
export const INSIGHTS_PAGE = `${BASE_URL}/node/insights?include=field_insight_industry,field_type,field_listing_image.field_media_image,field_sections.field_image,field_sections.field_image.field_media_image,field_sections.field_main_content&sort=-created&filter[city][condition][path]=field_featured&filter[city][condition][operator]==&filter[city][condition][value]=1`;
export const INSIGHTS_PAGE_NEWS = `${BASE_URL}/node/insights?include=field_insight_industry,field_type,field_listing_image.field_media_image,field_sections.field_image,field_sections.field_image.field_media_image,field_sections.field_main_content&sort=-created&filter[city][condition][path]=field_featured&filter[city][condition][operator]==&filter[city][condition][value]=0`;
export const INSIGHTS_INDUSTRY_FILTER = `${BASE_URL}/taxonomy/insights-industry?fields[country]=name`;
export const INSIGHTS_TYPE_FILTER = `${BASE_URL}/taxonomy/insights-type?fields[country]=name`;

export const NOT_FOUND_PAGE_BLOCK = `${BASE_URL}/node/error_page_2021?include=field_sections`

export const GET_CONTACT_US_OFFICES = (id) => `${BASE_URL}/node/locations?filter[location-type][condition][path]=field_location_type&filter[location-type][condition][operator]==&filter[location-type][condition][value]=city&filter[country][condition][path]=field_country.drupal_internal__nid&filter[country][condition][operator]==&filter[country][condition][value]=${id}&include=field_country`;
export const GET_SINGLE_INSIGHT_NEWS = (id) => `${BASE_URL}/node/insights/${id}?include=field_insight_industry,field_type,field_listing_image.field_media_image,field_sections.field_image,field_sections.field_image.field_media_image,field_sections.field_main_content&sort=-created&filter[city][condition][path]=field_featured&filter[city][condition][operator]==&filter[city][condition][value]=0`;
export const CAREERS_JOB = (offset, limit) => `${BASE_URL}/node/job_offering?page[limit]=${limit}&page[offset]=${offset}&include=field_taxonomy_city,country,job_type,field_taxonomy_city,field_taxonomy_language&fields[city]=name&fields[country]=name&fields[job-type]=name&fields[language]=name`;
export const CAREERS_SINGLE_JOB = (id) => `${BASE_URL}/node/job_offering/${id}?include=field_taxonomy_city,country,job_type,field_taxonomy_city,field_taxonomy_language&fields[jobs]=title,field_taxonomy_city,field_taxonomy_language,country,job_type,body,link&fields[city]=name&fields[country]=name&fields[job-type]=name&fields[language]=name`;
export const FIND_JOB_BY_TITLE_AND_DESCRIPTION = (value, {
	cities = '', countries = '', languages = '', jobType = '', limit = '10', offset = '0'
}) => {
	let path = `${BASE_URL}/node/job_offering?page[limit]=${limit}&page[offset]=${offset}&include=field_taxonomy_city,country,job_type,field_taxonomy_city,field_taxonomy_language&fields[jobs]=title,field_taxonomy_city,field_taxonomy_language,country,job_type,body,link&fields[city]=name&fields[country]=name&fields[job-type]=name&fields[language]=name`
	if (value) {
		path = path + `&filter[title-description][group][conjunction]=OR&filter[body][condition][path]=body.value&filter[body][condition][operator]=CONTAINS&filter[body][condition][value]=${value}&filter[body][condition][memberOf]=title-description&filter[title][condition][path]=title&filter[title][condition][operator]=CONTAINS&filter[title][condition][value]=${value}&filter[title][condition][memberOf]=title-description`
	}
	if (cities) {
		path = path + `&filter[city][condition][path]=field_taxonomy_city.name&filter[city][condition][operator]==&filter[city][condition][value]=${cities}`
	}
	if (countries) {
		path = path + `&filter[country][condition][path]=field_taxonomy_country.name&filter[country][condition][operator]==&filter[country][condition][value]=${countries}`
	}
	if (languages) {
		path = path + `&filter[language][condition][path]=field_taxonomy_language.name&filter[language][condition][operator]==&filter[language][condition][value]=${languages}`
	}
	if (jobType) {
		path = path + `&filter[job-type][condition][path]=job_type.name&filter[job-type][condition][operator]==&filter[job-type][condition][value]=${jobType}`
	}
	return path;
}

export const FIND_INSIGHTS_BY_NAME = ({insightsSearch, industryFilter, typeFilter}, params) => {
	let path = `${BASE_URL}/node/insights?include=field_insight_industry,field_type,field_listing_image.field_media_image,field_sections.field_image,field_sections.field_image.field_media_image,field_sections.field_main_content&sort=-created`

	if (insightsSearch) {
		path = path + `&filter[city][condition][path]=field_featured&filter[city][condition][operator]==&filter[city][condition][value]=0&filter[search-title][condition][path]=title&filter[search-title][condition][operator]=CONTAINS&filter[search-title][condition][value]=${insightsSearch}`
	}

	if (industryFilter) {
		path = path + `&filter[industry][condition][path]=field_insight_industry.name&filter[industry][condition][operator]==&filter[industry][condition][value]=${industryFilter}`
	}

	if (typeFilter) {
		path = path + `&filter[type][condition][path]=field_type.name&filter[type][condition][operator]==&filter[type][condition][value]=${typeFilter}`
	}

	return path;
}
