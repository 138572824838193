import { Grid } from '@material-ui/core';
import { Title, MainContainer, ButtonsListContainer } from 'components';
import useStyles from './style';
import {buttonTypeEnum} from "Theme/ThemeSwitching";

const CallToActionModule = ({ title, columnData, themeSelected, titleOffset, buttonsData = [
    {
        uri: '',
        title: 'Contact Us',
        buttonType: buttonTypeEnum.primary
    },
    {
        uri: '',
        title: 'Contact Us',
        buttonType: buttonTypeEnum.secondary
    }
] }) => {
  const classes = useStyles(themeSelected)();

  return (
    <MainContainer justifyContent={'center'} themeSelected={themeSelected}>
      <Title
        offsetOption={titleOffset}
        title={title}
        label={'label'}
        themeSelected={themeSelected}
        className={classes.title}
      />
      <Grid item xs={12} sm={8} className={classes.subtitle}>
        <Grid container justifyContent={'space-between'}>
          {columnData.map((column, idx) => (
            <Grid
              item
              xs={12}
              sm={columnData.length > 1 ? 6 : 8}
              key={idx}
            >
              {column}
            </Grid>
          ))}
        </Grid>
      </Grid>
      <Grid item xs={12} sm={8} md={8}>
        <ButtonsListContainer themeSelected={themeSelected} buttonsData={buttonsData}/>
      </Grid>
    </MainContainer>
  );
};
export default CallToActionModule;
