import {Grid, makeStyles} from '@material-ui/core';
import {useActionCreator} from "Redux/useActionCreator";
import {getInputReselectedStore} from "../../Redux/Modules/Inputs/reselect";
import {useSelector} from "react-redux";
import {buttonTypeEnum, themeType} from "../../Theme/ThemeSwitching";
// import stylesEnumObject from "../../Theme/ThemeSwitching/theneNames";
// import {useEffect, useMemo} from "react";

const useStyles = (themeSelected, buttonType) => makeStyles(theme => ({
	filter: {
		'-webkit-appearance': 'none',
		borderRadius: 100,
		width: 131,
		height: 36,
		marginRight: 12,
		display: 'flex',
		alignItems: 'center',
		textAlignLast: 'center',
		fontSize: 15,
		letterSpacing: -0.2,
		...themeType.getThemeData(themeSelected).button[buttonType]
	}
}))

const FilterSelect = ({data = {}, name = '', placeholder, cb, themeSelected, ...rest}) => {
	const {updateInputsStoreAction} = useActionCreator();
	const inputs = useSelector(getInputReselectedStore);

	const classes = useStyles(themeSelected, inputs[name] ? buttonTypeEnum.primary : buttonTypeEnum.secondary)()

	const handleSelectCard = (e) => {
		updateInputsStoreAction({[e.target.name]: e.target.value})
		cb({[e.target.name]: e.target.value})
	}
	return (
		<Grid item component={'select'} onChange={handleSelectCard} value={inputs[name] || ''}
			  name={name} className={classes.filter} {...rest}>
			{placeholder && <option value={''}>{placeholder}</option>}
			{data[name]?.map((i) => (
				<option value={i.title} key={i.id}>
					{i.title}
				</option>
			))}
		</Grid>
	);
};

export default FilterSelect;
