class PageLevelService {

	levelValue = true;
	static #splitedLocation;

	constructor(location) {
		this.location = location;
	};

	handleSplitLocation () {
		const removedEmptyItems = this.location.split('/').filter(i => i);
		PageLevelService.#splitedLocation = removedEmptyItems.filter((path, idx) => !(idx === 0 && path.length === 2))
	};

	handleDefineLevel () {
		this.handleSplitLocation();
		this.levelValue = PageLevelService.#splitedLocation.length <= 1;
	};

	get isFirstLevel () {
		this.handleDefineLevel();
		return this.levelValue;
	};
}

export default PageLevelService;
