import {createSelector} from "reselect";

const getInputStore = state => state.inputs;

export const getInputReselectedStore = createSelector(getInputStore, state => state)

export const getCareersPlaceholderName = createSelector(getInputReselectedStore, (state) => {
	const {countries, cities, jobType, languages} = state;
	return [countries, cities, jobType, languages].filter(i => i).join(", ")
})

export const getInsightsPlaceholderName = createSelector(getInputReselectedStore, (state) => {
	const {industryFilter, typeFilter} = state;
	return [industryFilter, typeFilter].filter(i => i).join(", ")
})

export const getToggleIfSearchFilteringSelected = createSelector(getInputReselectedStore, (state) => {
	const {countries, cities, jobType, languages} = state;

	return (countries || cities || jobType || languages)
})
