import { makeStyles } from '@material-ui/core';

const styles = () =>
  makeStyles((theme) => {
    return {
      title: {
        fontWeight: 500,
        fontSize: 52,
        lineHeight: '72px',
        letterSpacing: -2.2,
        whiteSpace: 'pre-line',
        [theme.breakpoints.down('md')]: {
          fontSize: 35,
          lineHeight: '36px'
        }
      },
      contentContainer: {
        margin: '48px auto 0 auto',
        display: 'flex',
        [theme.breakpoints.down('sm')]: {
          margin: '24px auto 0 auto'
        }
      },
      buttonContainer: {
        marginTop: 100,
        letterSpacing: -0.2,
        [theme.breakpoints.down('sm')]: {
          marginTop: 48
        }
      },
      description: {
        fontSize: 15,
        lineHeight: '24px',
        letterSpacing: -0.4
      },
      report: {
        fontWeight: 500,
        fontSize: 19,
        lineHeight: '24px',
        letterSpacing: -0.4,
        marginTop: 40
      },
      reportFile: {
        fontSize: 15,
        color: '#464646',
        marginTop: 8,
        border: 'none',
        background: 'transparent',
        cursor: 'pointer',
        textAlign: 'left',
        padding: 0,
        transition: 'all 0.1s linear',
        '&:hover': {
          opacity: 0.8
        }
      },
      accordion: {
        padding: '10px 0 !important',
        minHeight: '0 !important',
      },
      yearWrapper: {
        margin: '0 !important'
      },
      year: {
        fontWeight: 500,
        fontSize: 19,
        lineHeight: '24px',
        letterSpacing: -0.4
      },
      file: {
        display: 'flex',
        fontSize: 15,
        color: '#464646',
        cursor: 'pointer',
        lineHeight: '24px',
        letterSpacing: -0.4,
        textDecoration: 'underline',
        marginBottom: 6,
        background: 'transparent',
        border: 'none',
        padding: 0,
        '& > img': {
          marginLeft: 10,
          verticalAlign: 'middle'
        }
      },
      text: {
        display: 'flex',
        fontSize: 15,
        marginBottom: 6,
        color: '#464646',
        textDecoration: 'none'
      },
      accordionRoot: {
        background: 'transparent !important'
      }
    };
  });

export default styles;
