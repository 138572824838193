import React from 'react';
import {Grid, TextField} from '@material-ui/core';
import SearchIcon from '@mui/icons-material/Search';
import {useInsightsFilters} from './useInsightsFilters';
import {NewsCard} from '../NewsCard';
import {InsightsFilterAreaMobile} from "./InsightsFilterAreaMobile";
import useStyles from './style';
import {CareersFilters} from "components";

const InsightsFilters = ({themeSelected, data}) => {
	const classes = useStyles(themeSelected)();

	const {
		insightsSearch,
		onChange,
		newsList,
		handleSubmit,
		foundInsights,
		handleSelectItem,
		wasSearch,
		handleSetModal,
		filtersData,
		handleReset,
		handleToggleList,
		placeholderName
	} = useInsightsFilters();

	return (
		<Grid container className={classes.container}>
			<Grid item xs={12}>
				<CareersFilters resetCareersAction={handleReset}
								themeSelected={themeSelected}
								handleToggleList={handleToggleList}
								filtersData={filtersData}
				/>
				<InsightsFilterAreaMobile
					classes={classes}
					themeSelected={themeSelected}
					handleSetModal={handleSetModal}
					findInsightsAction={handleSubmit}
					resetAction={handleReset}
					placeholderName={placeholderName}
				/>
				<Grid item component={'form'} onSubmit={handleSubmit}>
					<TextField
						value={insightsSearch}
						name={'insightsSearch'}
						variant='outlined'
						onChange={onChange}
						className={classes.inputRoot}
						label={
							<span className={classes.inputLabel}>
                <SearchIcon/> Search for Insights
              </span>
						}
					/>
				</Grid>
			</Grid>
			<Grid item xs={12} className={classes.resultsContainer}>
				{(wasSearch ? foundInsights : newsList)?.map((news) => (
					<NewsCard
						id={news.id}
						handleSelectItem={handleSelectItem}
						themeSelected={themeSelected}
						title={news.attributes.title}
						date={news.attributes.created}
						classes={classes}
						key={news.id}
						news={news}
					/>
				))}
			</Grid>
		</Grid>
	);
};

export default InsightsFilters;
