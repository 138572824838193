import { makeStyles } from '@material-ui/core';

const styles = (themeSelected) =>
  makeStyles((theme) => ({
    container: {
      width: '100%',
      padding: '24px 36px'
    }
  }));

export default styles;
