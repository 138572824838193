import {makeStyles} from "@material-ui/core";

const styles = (themeSelected) => makeStyles(theme => ({
	title: {
		fontStyle: 'normal',
		fontWeight: 500,
		fontSize: '4.3vw',
		letterSpacing: -2.2,
		[theme.breakpoints.down('sm')]: {
			fontSize: 39,
			letterSpacing: -1.2,
		},
		[theme.breakpoints.down('xs')]: {
			marginTop: 16
		},
	},
}))


export default styles;
