import React from 'react'
import {Grid} from '@material-ui/core';
import {JobShortCard, CareersJobCard} from 'components';
import arrowLeft from 'assets/icons/arrowLeft.svg';
import useJobCardDetails from './useJobCardDetails';
import useStyles from './style';

export const JobCardDetailsContainer = ({pageData ={}}) => {
	const { handleGoBack, jobList, handleSelectCard } = useJobCardDetails();
	const classes = useStyles();

	const jobData = {
		title: pageData?.attributes?.title,
		description: pageData?.attributes?.body?.value,
		country: pageData?.relationships?.country?.attributes?.name,
		city: pageData?.relationships?.field_taxonomy_city?.attributes?.name,
		jobType: pageData?.relationships?.job_type?.attributes?.name,
		link: pageData?.attributes?.link?.uri
	}
	return (
		<Grid container className={classes.container}>
			<Grid item xs={2} component={'button'} onClick={handleGoBack} className={classes.buttonBack}>
				<img src={arrowLeft} alt="arrowLeft"/>Back to overview
			</Grid>
			<Grid item xs={12}>
				<Grid container>
					<CareersJobCard certainJobData={jobData}/>
				</Grid>
				<h2>More jobs like this</h2>
				<Grid container>
					{jobList.slice(0, 3).map((card) => (
						<JobShortCard
							key={card.id}
							handleSelectCard={handleSelectCard}
							card={card}
						/>
					))}
				</Grid>
			</Grid>
		</Grid>
	);
};
