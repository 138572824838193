import { makeStyles } from '@material-ui/core';
import { themeType } from 'Theme/ThemeSwitching';
import themeTypography from 'Theme/typography';

const styles = (themeSelected, props) =>
  makeStyles((theme) => {
    return {
      title: {
        textAlign: 'left',
        lineHeight: '48px !important',
        ...themeTypography.headline2,
        [theme.breakpoints.down('xs')]: {
          ...themeTypography.headline5
        },
      },
      ctaButtonContainer: {
        margin: '32px 120px',
        [theme.breakpoints.down('xs')]: {
          margin: '32px 0'
        },
      },
      description: {
        margin: '32px 0 120px',
        textAlign: 'left',
        [theme.breakpoints.down('xs')]: {
          margin: '32px 0'
        },
        fontSize: 15,
        maxWidth: 878,
        ...themeType[themeSelected].subtitle
      },
      postContainer: {
        marginBottom: 120,
        [theme.breakpoints.down('xs')]: {
          marginBottom: 32
        }
      },
      post: {
        padding: props && props.offset === 'right' ? '0 120px 0 0 ' : '0 0 0 120px',
        [theme.breakpoints.down('xs')]: {
          padding: 0
        }
      },
      postTitle: {
        fontSize: 52,
        fontWeight: 500,
        lineHeight: '48px',
        margin: 0,
        ...themeType[themeSelected].title,
        [theme.breakpoints.down('xs')]: {
          fontSize: 35,
        }
      },
      postLabel: {
        width: '100%',
        fontSize: 12,
        lineHeight: '12px',
        marginBottom: 12,
        textAlign: 'left',
        ...themeType[themeSelected].subtitle
      },
      postDescription: {
        width: '100%',
        maxWidth: 450,
        fontSize: 15,
        lineHeight: '20px',
        marginBottom: 40,
        textAlign: 'left',
        ...themeType[themeSelected].subtitle
      },
      postImage: {
        width: '100%'
      },
      postButton: {
        width: '100%',
        textAlign: 'left',
        padding: 0,
        fontSize: 15,
        lineHeight: '20px',
        fontWeight: 'bold',
        background: 'transparent',
        border: 'none',
        cursor: 'pointer',
        ...themeType[themeSelected].subtitle
      }
    };
  });

export default styles;
