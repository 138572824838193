import React from 'react';

export const MappedItem = ({
  handleSelectDropdownItem,
  dropdownItem,
  classes,
  divider = true,
  triangle = true
}) => {
  // const [isItemHover, setIsItemHover] = useState(false)
  // const handleHoverToggle = () => setIsItemHover(prev => !prev)
  return (
    <li
      key={dropdownItem.id}
      // onMouseEnter={handleHoverToggle}
      // onMouseLeave={handleHoverToggle}
      className={classes.dropdownItemContainer}
    >
      <span
        onClick={handleSelectDropdownItem(dropdownItem)}
        className={classes.dropdownItem}
      >
        {dropdownItem.title}
      </span>
      {divider && <div className={classes.divider} />}
      {triangle && <div className={classes.itemTriangle} />}
      {!!dropdownItem.link?.length && (
        // && isItemHover
        <div className={classes.subLinksContainer}>
          {dropdownItem.link.map((i) => (
            <div
              onClick={handleSelectDropdownItem(i)}
              className={classes.subLinks}
              key={i.id}
            >
              <div style={{ left: -13 }} className={classes.itemTriangle} />
              {i.title}
            </div>
          ))}
        </div>
      )}
    </li>
  );
};

