import React, {useEffect} from 'react';
import {useSelector} from 'react-redux';
import {Grid} from '@material-ui/core';
import format from 'date-fns/format'

import {MainContainer, Title} from 'components';
import {API_URL} from 'API/constants';
import generalReselect from 'Redux/Modules/General/reselect';
import iconsInterface from 'components/Footer/IconsInterface';

import useStyles from './style';
import {TwoColumnsModule} from 'modules';

const HTMLTextModule = ({pageData = [], themeSelected}) => {
	const classes = useStyles(themeSelected)();
	const {socialMediaIcons} = useSelector(generalReselect.getGeneralReselectedStore);
	const {newsInfo} = useSelector(state => state.insights);

	const twoColumnsModule = pageData.find((el) => el.type === 'paragraph--text_two_columns');

	useEffect(() => {
		window.scrollTo({top: 0, behavior: 'smooth'});
	}, []);
	return (
		<Grid container className={classes.container}>
			<MainContainer themeSelected={themeSelected} justifyContent='center'>
				<Title
					themeSelected={themeSelected}
					offsetOption={'leftFlushed'}
					title={newsInfo.attributes.title}
					label={format(new Date(newsInfo.attributes.created), 'dd MMMM yyyy')}
					className={classes.title}
				/>
				<Grid item xs={10} sm={8}>
					{pageData.map((el, idx) => {
						if (el.type === 'paragraph--body') {
							return <div key={idx} dangerouslySetInnerHTML={{__html: el.field_body.value}}/>;
						}
						if (el.type === 'paragraph--image') {
							return (
								<Grid container key={idx}>
									<Grid
										item
										component='img'
										src={API_URL + el.field_image?.field_media_image?.uri?.url}
									/>
								</Grid>
							);
						}
						if (el.type === 'paragraph--call_to_action') {
							return (
								<Grid key={idx} item component='button' className={classes.callToAction}>
									{el.field_link.title}
								</Grid>
							);
						}
						if (el.type === 'paragraph--footnotes') {
							return <div key={idx} dangerouslySetInnerHTML={{__html: el.field_body.value}}/>;
						}
						return true
					})}
					<Grid container>
						<Grid item xs={6} sm={3} md={2} className={classes.iconContainer}>
							{Array.isArray(socialMediaIcons) &&
							!!socialMediaIcons.length &&
							socialMediaIcons?.map((icon) =>
								iconsInterface.getIconComponent(icon.name)({
									classes,
									icon
								})
							)}
						</Grid>
					</Grid>
				</Grid>
			</MainContainer>
			<TwoColumnsModule
				themeSelected={twoColumnsModule?.field_module_theme}
				label={twoColumnsModule?.field_section_title}
				title={twoColumnsModule?.field_title}
				columnData={twoColumnsModule?.field_column}
				buttonsData={twoColumnsModule?.field_call_to_action}
				titleOffset={twoColumnsModule?.field_title_alignment}
				field_cta_type={twoColumnsModule?.field_cta_type}
			/>
		</Grid>
	);
};

export default HTMLTextModule;
