import {useSelector} from 'react-redux';
import {useActionCreator} from 'Redux/useActionCreator';
import {useHistory} from 'react-router-dom';
import path from 'Routes/paths';
import {useContext, useEffect} from 'react';
import {ModalContext} from 'Context/ModalContext';
import {getInsightsPlaceholderName} from "Redux/Modules/Inputs/reselect";

const filtersData = [
	{name: 'industryFilter', placeholder: 'Industries', status: false},
	{name: 'typeFilter', placeholder: 'All types', status: false}
];

export const useInsightsFilters = () => {
	const {insightsSearch, industryFilter, typeFilter} = useSelector(state => state.inputs)
	const {newsList, foundInsights, wasSearch} = useSelector(state => state.insights)
	const placeholderName = useSelector(getInsightsPlaceholderName);
	const {
		updateInputsStoreAction,
		getFilteredInsightsAction,
		getSingeInsightNewsAction,
		getInsightsListOfNewsAction,
		handleUpdateInsightsStoreAction,
		setNewsInfo
	} = useActionCreator();


	const onChange = (e) => updateInputsStoreAction({[e.target.name]: e.target.value});

	const {handleSetModal} = useContext(ModalContext);

	const history = useHistory();

	const handleSubmit = (e) => {
		e.preventDefault && e.preventDefault();
		getFilteredInsightsAction();
	};

	const handleResetIcon = () => {
		updateInputsStoreAction({
			insightsSearch: '',
			typeFilter: '',
			industryFilter: ''
		});
		handleUpdateInsightsStoreAction({
			wasSearch: false
		});
		getInsightsListOfNewsAction();
	};

	const handleSelectItem = (data) => async () => {
		await getSingeInsightNewsAction(data.id);
		setNewsInfo(data)
		history.push(path.insightsDetail(data.id));
	};

	useEffect(() => {
		if (!industryFilter && !typeFilter) {
			handleUpdateInsightsStoreAction({
				wasSearch: false
			})
			getInsightsListOfNewsAction();
		}
		// eslint-disable-next-line
	}, [industryFilter, typeFilter])

	useEffect(() => {
		if (industryFilter || typeFilter) {
			getFilteredInsightsAction({industryFilter, typeFilter})
		}
		// eslint-disable-next-line
	}, [industryFilter, typeFilter])

	const handleReset = () => {
		handleResetIcon();
	};

	return {
		insightsSearch,
		onChange,
		newsList,
		foundInsights,
		handleSubmit,
		handleSelectItem,
		wasSearch,
		handleSetModal,
		filtersData,
		handleReset,
		placeholderName
	};
};
