import API from 'API/index';
import getYear from 'date-fns/getYear';
import getMonth from 'date-fns/getMonth';
import { UPDATE_ABOUT_US_STORE } from './aboutUs-type';

class AboutUsActions {
  updateAboutUsStore = (payload) => ({
    type: UPDATE_ABOUT_US_STORE,
    payload
  });

  getAboutUsPageDataAction = () => async (dispatch, store, query) => {
    try {
      const queryData = query.config;
      const res = await API.aboutus.getPageData();
      const updatedData = res.data.included.map((i) => ({
        ...i,
        attributes: { ...i.attributes, type: i.type }
      }));
      const data = queryData.normalize({ data: updatedData });
      dispatch(this.updateAboutUsStore({ pageData: data.data }));
    } catch (e) {
      console.error(e);
    }
  };

  getAboutUsPressListingsAction = () => async (dispatch, store, query) => {
    try {
      const queryData = query.config;
      const res = await API.aboutus.getPageData();
      const updatedData = res.data.data.map((i) => ({
        ...i,
        attributes: { ...i.attributes, type: i.type }
      }));
      const { data } = queryData.normalize({ data: updatedData });

      const years = [...new Set(data.map((el) => getYear(new Date(el.created))))];

      const archiveDates = years.map((el) => ({
        [el]: data.filter((press) => getYear(new Date(press.created)) === el)
      }));

      dispatch(
        this.updateAboutUsStore({
          pressListings: data,
          defaultPressListings: data,
          archiveDates
        })
      );
    } catch (e) {
      console.error(e);
    }
  };

  filterPressListings = (years, months) => (dispatch, store) => {
    try {
      const state = store().aboutUs;

      let filteredData = [];
      if (!months.length && !years.months) {
        filteredData = state.defaultPressListings;
      } else {
        filteredData = state.defaultPressListings.filter(
          (el) =>
            years.includes(getYear(new Date(el.created))) &&
            months.includes(getMonth(new Date(el.created)))
        );
      }
      dispatch(this.updateAboutUsStore({ pressListings: filteredData }));
    } catch (e) {
      console.error(e);
    }
  };
}

export default new AboutUsActions();
