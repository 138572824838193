export const handleParseJob = (data) => {
	let mutatedData = null;

	if (Array.isArray(data.data)) {
		mutatedData = data.data;
	} else {
		mutatedData = [data.data];
	}

	return mutatedData.map(job => {
		const relationships = Object.keys(job.relationships).reduce((acc, value) => {
			const relationShipData = job.relationships[value]?.data;
			let properData;
			if (Array.isArray(relationShipData)) {
				properData = relationShipData.map(j => data.included.find(i => i.id === j.id))
			} else {
				properData = data.included.find(i => i.id === relationShipData?.id)
			}
			return {...acc, [value]: properData}
		}, {})
		return {...job, relationships}
	})

	// mutatedData.relationships = {...mutatedDataRelationships};
}
