import React from 'react'
import { Grid } from '@material-ui/core';
import format from 'date-fns/format'

import test from 'assets/images/Image Formats.svg';

import useStyles from './style';

const NewsCard = ({ image = test, title ='', date = '', description ='', handleSelectItem, themeSelected, news }) => {
  const classes = useStyles(themeSelected)();

  return (
    <Grid container justifyContent='flex-start' className={classes.cardContainer}>
      <Grid item sm={4}>
        <Grid component='img' className={classes.cardImage} src={image} />
      </Grid>
      <Grid item sm={8}>
        <Grid container className={classes.post} direction='column' justifyContent='space-between'>
          <Grid item className={classes.newsContainer}>
            <Grid component='p' className={classes.cardLabel}>
              {format(new Date(date), 'dd MMMM yyyy')}
            </Grid>
            <Grid component='p' className={classes.cardTitle} onClick={handleSelectItem(news)}>
              {title}
            </Grid>
          </Grid>
          <Grid container>
            <Grid component='p' className={classes.cardDescription}>
              {description}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default NewsCard
