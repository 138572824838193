import { GET_ABOUT_US_PAGE, GET_ABOUT_US_PRESS_LISTINGS } from './constants';
import axios from 'axios';

class AboutUs {
  async getPageData(params) {
    try {
      return axios.get(GET_ABOUT_US_PAGE, params);
    } catch (e) {}
  }
  async getPressListingsData(params) {
    try {
      return axios.get(GET_ABOUT_US_PRESS_LISTINGS, params);
    } catch (e) {}
  }
}

export default AboutUs;
