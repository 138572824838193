import { Grid } from '@material-ui/core';
import { API_URL } from 'API/constants';

import { MainContainer, Title, ReadMoreButton } from 'components';
import stylesEnum from 'Theme/ThemeSwitching/theneNames';

import useStyles from './style';

const ArticleTile = ({
  themeSelected = stylesEnum.coralBackground,
  blockName = 'article-tile',
  articleData = {}
}) => {
  const classes = useStyles(themeSelected)();
  const {
    field_title = '',
    field_section_title  = 'We are transcom',
    field_title_alignment = 'rightOffset',
    field_article_card
  } = articleData;
  return (
    <MainContainer themeSelected={themeSelected} blockName={blockName}>
      <Grid item xs={12}>
        <Title
          offsetOption={field_title_alignment}
          title={field_title}
          label={field_section_title}
          themeSelected={themeSelected}
          className={classes.title}
          titleGrid={{ xs: 12, sm: 9 }}
        />
        <Grid
          container
          justifyContent='flex-start'
          spacing={2}
          className={classes.articleContainer}
        >
          {field_article_card?.map((article) => {
            return (
                <Grid key={article.id} item xs={12} sm={4}>
                  {article.field_image && (
                      <Grid
                          component='img'
                          src={API_URL + article.field_image.field_media_image.uri.url}
                          className={classes.articleImage}
                      />
                  )}
                  <Grid component='p' className={classes.articleTitle}>
                    {article.field_title || ''}
                  </Grid>
                  <Grid component='p' className={classes.articleDescription}>
                    {article.field_subtitle || ''}
                  </Grid>
                  <ReadMoreButton
                      themeSelected={themeSelected}
                      title={article?.field_link?.title}
                      url={article?.field_link?.url || article?.field_link?.uri}
                  />
                </Grid>
            )
          })}
        </Grid>
      </Grid>
    </MainContainer>
  );
};

export default ArticleTile;
