class BackgroundClass {
	constructor(background) {
		this.background = background;
	}
}

class TitleClass {
	constructor(color) {
		this.color = color;
	}
}

class SubtitleClass {
	constructor(color) {
		this.color = color;
	}
}

class ReadMoreClass {
	constructor(color) {
		this.color = color;
	}
}

class ButtonClass {
	constructor(backgroundColor = '', titleColor = '', borderColor = 'transparent') {
		this.background = backgroundColor;
		this.color = titleColor;
		this.border = `1px solid ${borderColor}`;
	}
}

class ListClasses {
	constructor(BackgroundClass, TitleClass, SubtitleClass, ReadMoreClass, ButtonClass) {
		this.Background = BackgroundClass;
		this.Title = TitleClass;
		this.Subtitle = SubtitleClass;
		this.ReadMore = ReadMoreClass;
		this.Button = ButtonClass
	}
}

export default new ListClasses(BackgroundClass, TitleClass, SubtitleClass, ReadMoreClass, ButtonClass)


