import { Grid } from '@material-ui/core';

import { Button, Title, MainContainer } from 'components';

import useStyles from './style';

const CardsModule = ({ themeSelected, isUSP, cardsData, blockName }) => {
  const classes = useStyles(themeSelected, { isUSP })();
  const { title = '', description = '', label = '', cards = [], buttonName = '', buttonUrl = '/' } = cardsData;

  return (
    <MainContainer themeSelected={themeSelected} blockName={blockName}>
      <Grid item xs={12}>
        <Title
          title={title}
          label={label}
          themeSelected={themeSelected}
          className={classes.title}
        />
        <Grid container justifyContent={'center'}>
          <Grid item xs={12} sm={10} lg={8} className={classes.contentContainer}>
            <Grid item component={'p'} className={classes.description}>
              {description}
            </Grid>
          </Grid>
        </Grid>
        <Grid container justifyContent={'center'}>
          <Grid item sm={10} lg={8}>
            <Grid container justifyContent={'space-between'}>
              {!!cards.length &&
                cards.map((card, idx) => (
                  <Grid className={classes.uspColumns} item key={idx} md={4}>
                    <Grid item component={'p'} className={classes.uspTitle}>
                      {card.field_title}
                    </Grid>
                    <Grid item component={'p'} className={classes.uspDescription}>
                      {card.field_description}
                    </Grid>
                  </Grid>
                ))}
            </Grid>
          </Grid>
        </Grid>
        {buttonName && (
          <Grid container justifyContent={'center'}>
            <Grid item xs={12} sm={10} lg={8} className={classes.buttonContainer}>
              <Button
                buttonName={buttonName}
                themeSelected={themeSelected}
                className={classes.uspButton}
                url={buttonUrl}
              />
            </Grid>
          </Grid>
        )}
      </Grid>
    </MainContainer>
  );
};

export default CardsModule;
