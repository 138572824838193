export const handleSplitUrl = (url = '') => {
	if (url.includes('http')) {
		return url
	}
	const removedEmptyItems = url.split('/').filter(i => i);
	const filteredUrl = removedEmptyItems.filter((path, idx) => !(idx === 0 && path.length === 2))
	if (filteredUrl.length) {
		return `/${filteredUrl.join("/")}`
	} else {
		return "/"
	}
}
