import API from 'API/index'
import {UPDATE_GENERAL_STORE_TYPE, UPDATE_META_DATA} from "./general-type";
import parseFooterData from "helpers/parseFooterData";
import parseHeaderData from "helpers/parseHeaderData";

class FiltersActions {
	generalUpdateStoreAction = (payload) => {
		return {
			type: UPDATE_GENERAL_STORE_TYPE,
			payload
		}
	};
	generalUpdateMetaDataAction = (payload) => {
		return {
			type: UPDATE_META_DATA,
			payload
		}
	};
	generalFooterFetchAction = () => async (dispatch, store) => {
		try {
			// const {footer} = store().general;
			// if (!footer.length) {
			const res = await API.generalApi.getFooterData()
			const response = res.data.data;
			dispatch(this.generalUpdateStoreAction({footer: parseFooterData(response)}))
			// }

		} catch (e) {
			console.error()
		}
	};
	generalFooterFetchSocialMediaIconsAction = () => async (dispatch, store) => {
		try {
			// const {socialMediaIcons} = store().general;
			// if (!socialMediaIcons.length) {
			const res = await API.generalApi.getFooterSocialMediaIcons();
			const response = res.data.data[0].attributes.settings?.platforms;
			const setValidValues = Object.keys(response).filter(i => response[i].value);
			const parsedValues = setValidValues.map(i => ({name: i, ...response[i]}));
			dispatch(this.generalUpdateStoreAction({socialMediaIcons: parsedValues}))
			// }
		} catch (e) {
			console.error(e)
		}
	}
	generalHeaderFetchMainContentAction = () => async (dispatch, store) => {
		try {
			// const {header} = store().general;
			// if (!header.length) {
			const res = await API.generalApi.getHeaderMainData();
			const {data} = await API.generalApi.getHeaderAdditionalData();
			const response = res.data.data;

			dispatch(this.generalUpdateStoreAction({
				header: parseHeaderData(response),
				headerAdditional: parseHeaderData(data.data),
			}))
			// }
		} catch (e) {
			console.error(e)
		}
	}

	getNotFoundPageDataAction = () => async (dispatch, store, query) => {
		try {
			const res = await API.generalApi.getNotFoundPageData();
			const queryData = query.config;
			const updatedData = res.data.included.map(i => ({...i, attributes: {...i.attributes, type: i.type}}))
			const data = queryData.normalize({data: updatedData})
			dispatch(this.generalUpdateStoreAction({
				notFoundPageData: data.data
			}))
		} catch (e) {
			console.error(e)
		}
	}
}

export default new FiltersActions()
