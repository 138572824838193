import {
	FIND_INSIGHTS_BY_NAME,
	GET_SINGLE_INSIGHT_NEWS, INSIGHTS_INDUSTRY_FILTER,
	INSIGHTS_PAGE,
	INSIGHTS_PAGE_NEWS,
	INSIGHTS_TYPE_FILTER
} from "./constants";
import axios from "axios";

class Insights {
	async getPageData(params) {
		try {
			return axios.get(INSIGHTS_PAGE, params)
		} catch (e) {

		}
	};

	async getBaseNews(params) {
		try {
			return axios.get(INSIGHTS_PAGE_NEWS, params)
		} catch (e) {
			console.error(e)
		}
	};

	async getSingeInsightNews(id = '', params) {
		try {
			return axios.get(GET_SINGLE_INSIGHT_NEWS(id))
		}catch (e) {
			console.error(e)
		}
	}

	async getInsightsTypeFiler (params) {
		try {
			return axios.get(INSIGHTS_TYPE_FILTER, params)
		}catch (e) {
			console.error(e)
		}
	}

	async getInsightsIndustryFilter (params) {
		try {
			return axios.get(INSIGHTS_INDUSTRY_FILTER, params)
		}catch (e) {
			console.error(e)
		}
	}

	async getInsightsByTitle ({...rest}, params) {
		try{
			return axios.get(FIND_INSIGHTS_BY_NAME({...rest}, params))
		}catch (e) {
			console.error(e)
		}
	}
}

export default Insights;
