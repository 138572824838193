import {useInput} from 'hooks/useInput';
import {makeStyles} from "@material-ui/core";

const useStyles = () => makeStyles(theme => ({
	container: {
		position: 'relative',
	},
	icon: {
		position: 'absolute',
		top: '50%',
		transform: 'translateY(-50%)',
	},

	rightIcon: {
		right: 0
	}
}))


const Input = ({
				   name,
				   LeftImage,
				   leftImageStyle,
				   RightImage,
				   rightImageStyle,
				   themeSelected = '',
				   submit,
				   ...rest
			   }) => {
	const {value, onChange} = useInput(name);
	const classes = useStyles(themeSelected)();

	return (
		<div className={classes.container}>
			{LeftImage && (<LeftImage className={classes.icon}
									  style={leftImageStyle}/>
			)}
			<input name={name} value={value} onChange={onChange} {...rest} />
			{RightImage && (
				<RightImage themeSelected={themeSelected} style={rightImageStyle}
							className={`${classes.icon} ${classes.rightIcon}`} submit={submit}/>
			)}
		</div>
	);
};

export default Input;
