import colors from "../../Theme/colors";

class HeaderThemeOptionEnum {
	dark = 'dark';
	light = 'light';
}

export const headerThemeOptionEnum = new HeaderThemeOptionEnum()

class HeaderThemeClass {
	constructor(background, color, hoverColor) {
		this.background = background;
		this.color = color;
		this.fill = color;
		this.borderColor = color;
		this.hoverColor = hoverColor;
	}
}

class HeaderTheme {
	constructor(dark, light) {
		this[headerThemeOptionEnum.dark] = dark;
		this[headerThemeOptionEnum.light] = light;
	}
	getThemeData(value){
		if(this[value]){
			return this[value]
		}
		return {}
	}
}

export const headerTheme = new HeaderTheme(new HeaderThemeClass(colors.darkBlack, colors.white, colors.gold), new HeaderThemeClass(colors.white, colors.darkBlack, colors.darkBlue))
