import {UPDATE_INSIGHTS_STORE} from "./insights-type";

const initialState = {
	pageData: [],
	newsList: [],
	newsDetail: [],
	foundInsights: [],
	wasSearch: false,
  newsInfo: {}
};

export const insightsReducer = (state = {...initialState}, action) => {
	switch (action.type) {
		case UPDATE_INSIGHTS_STORE: {
			return {...state, ...action.payload}
		}
		default: {
			return state;
		}
	}
}

