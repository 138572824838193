import { makeStyles } from '@material-ui/core';
import { themeType } from 'Theme/ThemeSwitching';
import themeTypography from 'Theme/typography';

const styles = (themeSelected, props) =>
  makeStyles((theme) => ({
    container: {
      width: '100%',
      padding: '24px 36px',
      maxWidth: 1680
    },
    imageContainer: {
      textAlign: 'center'
    },
    title: {
      ...themeTypography.headline2,
      ...themeType[themeSelected].title
    },
    postContainer: {
      padding: '60px 36px',
      maxWidth: 1680,
      [theme.breakpoints.down('xs')]: {
        marginBottom: 32
      },
      ...themeType[themeSelected].background
    },
    post: {
      width: '100%',
      display: 'flex',
      flexWrap: 'wrap',
      flexDirection: 'column',
      justifyContent: props && props.link ? 'space-between' : 'flex-end',
      padding: props && props.imageOffset === 'right' ? '0 60px 0 0 ' : '0 0 0 60px',
      [theme.breakpoints.down('xs')]: {
        padding: 0
      }
    },
    postTitle: {
      fontSize: 52,
      fontWeight: 500,
      lineHeight: props && props.layout === 'layout_a' ? '72px' : '56px',
      margin: 0,
      marginBottom: 40,
      ...themeType[themeSelected].title,
        [theme.breakpoints.down('xs')]: {
            fontSize: 35,
        }
    },
    postLabel: {
      width: '100%',
      marginBottom: 12,
      textAlign: 'left',
      ...themeType[themeSelected].subtitle
    },
    postDescription: {
      width: '100%',
      maxWidth: 450,
      marginBottom: 40,
      textAlign: 'left',
      ...themeType[themeSelected].subtitle
    },
    postImage: {
      width: '100%'
    },
    selectRoot: {
      width: '100%',
      borderRadius: '50px',
      marginBottom: 50
    },
    toothImage: {
      width: '100%',
      maxWidth: 500,
      height: 700,
      objectFit: 'cover',
      objectPosition: '60% 50%'
    },
    squaredImage: {
      width: '100%',
      maxWidth: 700,
      height: 700,
      objectFit: 'cover'
    }
  }));

export default styles;
