import {makeStyles} from "@material-ui/core";

const style = (themeSelected) => makeStyles(theme => ({
	title: {
		fontWeight: 500,
		fontSize: '5.2vw',
		letterSpacing: -2.8,
		[theme.breakpoints.down('sm')]: {
			fontSize: 39
		},
	},
	subtitle: {
		marginTop: 60,
		fontWeight: 500,
		letterSpacing: -0.4,
		fontSize: 19,
	},
	stepperContentContainer: {
		position: 'relative',
		marginTop: 100,
	},
	stepperIcon: {
		position: 'relative',
		left: '50%',
		transform: 'translateX(-30%)'
	},
	stepperItemContentContainer: {
		position: "absolute",
		left: 200,
		top: 20,
		[theme.breakpoints.down('sm')]: {
			left: 150
		}
	},
	stepperTitle: {
		fontWeight: 500,
		fontSize: 27,
		letterSpacing: -0.2,
	},
	stepperSubtitle: {
		fontSize: 15,
		letterSpacing: -0.2,
		marginTop: 43
	}
}))

export default style;
