import React, { useEffect } from 'react';

import { blockComponents } from 'BlockConstructor/BlockNames';
import { useActionCreator } from 'Redux/useActionCreator';

import { usePageMeta } from '../../hooks/usePageMeta';
import {Grid, makeStyles} from "@material-ui/core";
const useStyles = makeStyles(theme => ({
  spaceForHeader: {
    height: 80,
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      height: 20
    }
  }
}))

const ContactUs = ({ pageData = [] }) => {
  const { getContactUsCountries, clearContactUsData } = useActionCreator();

  const MetaTag = usePageMeta();
  const classes = useStyles()

  useEffect(() => {
    clearContactUsData();
    getContactUsCountries();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
      <Grid container justifyContent={'center'}>
        <MetaTag/>
        <Grid item xs={12}>
          <div className={classes.spaceForHeader} />
          {pageData?.length && pageData?.map((block, index) => blockComponents.getComponent(block?.type)({params: block}, index))}
        </Grid>
      </Grid>
  );
};

export default ContactUs;
