import {UPDATE_CAREERS_STATE_TYPE} from "./careers-type";
import API from 'API/index';
import {initialState} from './careers-reducer';
import inputActions from '../Inputs/inputs-actions';
import {handleParseJob} from "helpers/handleParseJob";
import parseHeaderData from "helpers/parseHeaderData";

class CareersActions {
	updateCareersStateAction = (payload) => ({
		type: UPDATE_CAREERS_STATE_TYPE,
		payload
	});
	getCareersPageModules = () => async (dispatch, store, query) => {
		try {
			const queryData = query.config;
			const res = await API.careerJobs.getCareersPageBlock();
			const updatedData = res.data.included.map(i => ({...i, attributes: {...i.attributes, type: i.type}}))
			const data = queryData.normalize({data: updatedData})
			dispatch(this.updateCareersStateAction({pageData: data.data}))
		} catch (e) {
			console.error(e)
		}
	};
	getCareersNavigation = () => async (dispatch, store) => {
		try {
			const res = await API.careerJobs.getCareersNavigation();
			const response = res.data.data
			dispatch(this.updateCareersStateAction({navigationCareers: parseHeaderData(response)}))
		} catch (e) {
			console.error(e)
		}
	};
	getAllJobsCareersAction = (showMore) => async (dispatch, store, query) => {
		try {
			const state = store().careers;
			const limit = state.itemsPerPage;
			const offset = state.pageOffset;
			const res = await API.careerJobs.getAllJobs(offset, limit);
			if (showMore) {
				return dispatch(this.updateCareersStateAction({
					jobsList: [...state.jobsList, ...handleParseJob(res.data)],
					meta: res.data.meta || '',
				}))
			}
			dispatch(this.updateCareersStateAction({
				jobsList: handleParseJob(res.data),
				meta: res.data.meta || {},
			}))
		} catch (e) {
			console.error(e)
		}
	};

	pressShowMoreCareersAction = (payload) => (dispatch, store) => {
		const state = store().careers;
		const pageOffsetValue = !state.wasSearching ? state.pageOffset + state.itemsPerPage : state.pageOffset;
		const foundListOffsetValue = state.wasSearching ? state.foundListOffset + state.itemsPerPage : state.foundListOffset;
		dispatch(this.updateCareersStateAction({pageOffset: pageOffsetValue, foundListOffset: foundListOffsetValue}))
	}

	getCertainJobAction = (id) => async (dispatch, state, query) => {
		try {
			const res = await API.careerJobs.getCertainJob(id);
			dispatch(this.updateCareersStateAction({certainJobData: handleParseJob(res.data)[0]}))
		} catch (e) {
			console.error(e)
		}
	}

	findJobsAction = ({inputValue = '', ...rest}, showMore) => async (dispatch, store) => {
		try {
			const inputsState = store().inputs;
			const {countries = '', cities = '', jobType = '', languages = ''} = store().inputs;
			const searchCondition = !(!inputsState.jobOpeningSearch && !inputsState.cities && !inputsState.countries && !inputsState.jobType && !inputsState.languages)
			const value = inputsState[inputValue];

			const state = store().careers;
			const limit = state.itemsPerPage;
			const offset = state.foundListOffset;

			if (searchCondition) {
				const res = await API.careerJobs.findJobs(value, {
					cities, countries, languages, jobType,
					limit, offset,
					...rest
				});

				window.dataLayer = window.dataLayer || [];
				window.dataLayer.push({
					'event': 'job_search',
					'ec': 'job_application',
					'ea': 'search',
					'el': value,
					'ev': 0,
					'cd4_filter_country': countries,
					'cd5_filter_location': cities,
					'cd6_filter_job_type': jobType,
					'cd7_filter_language': languages,
				});


				dispatch(this.updateCareersStateAction({
					wasSearching: searchCondition,
					foundJobs: showMore ? [...state.foundJobs, ...handleParseJob(res.data)] : handleParseJob(res.data),
					metaSearch: res.data.meta || {}
				}))
			} else {
				dispatch(this.updateCareersStateAction({
					wasSearching: searchCondition,
					foundJobs: [],
					// meta: ''
				}))
			}
			// }
		} catch (e) {
			console.error(e)
		}
	}

	resetCareersAction = () => (dispatch, store) => {
		try {
			const updatedInputsState = {
				jobOpeningSearch: '',
				countries: '',
				jobType: '',
				cities: '',
				languages: '',
			}
			dispatch(inputActions.updateInputsStoreAction(updatedInputsState))
			dispatch(this.updateCareersStateAction({...initialState}))
		} catch (e) {
			console.error(e)
		}
	}

  setStackPost = (post) => (dispatch, store) => {
    try {
			dispatch(this.updateCareersStateAction({openedPost: post}))
		} catch (e) {
			console.error(e)
		}
  }
}

export default new CareersActions();
