import {UPDATE_MAIN_PAGE_STORE_ACTION} from "./main-page-type";

const initialState = {
	pageData: [],
}

export const mainPageReducer = (state = initialState, action) => {
	switch (action.type) {
		case UPDATE_MAIN_PAGE_STORE_ACTION: {
			return {...state, ...action.payload}
		}
		default: {
			return state
		}
	}
}
