import { makeStyles } from '@material-ui/core';
import themeTypography from 'Theme/typography';

export default makeStyles((theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'center'
  },
  titleContainer: {
    marginLeft: 'auto',
    display: 'flex',
    justifyContent: 'center'
  },
  title: {
    ...themeTypography.headline1,
    [theme.breakpoints.down('sm')]: {
      ...themeTypography.headline4
    },
  },
  jobInfo: {
    margin: '100px 0 48px 0',
    [theme.breakpoints.down('sm')]: {
      margin: '64px 0 48px 0',
    },
  },
  jobOptions: {
    fontSize: 15,
    lineHeight: '20px',
    fontWeight: 'bold',
    margin: '5px 0',
    '& > span': {
      fontWeight: 'normal'
    }
  },
  parahraphTitle: {
    fontSize: 27,
    lineHeight: '32px',
    marginBottom: 16
  },
  applyButton: {
    background: theme.colors.blue,
    color: theme.colors.white,
    border: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 100,
    height: 50,
    width: 141,
    fontSize: 19,
    lineHeight: '20px',
    cursor: 'pointer',
    margin: '80px 0'
  },
  personTitle: {
    fontWeight: 600,
    fontSize: 19,
    margin: '10px 0 5px 0'
  },
  personName: {
    fontSize: 15,
    margin: '0 0 45px 0'
  },
  applyPersonButton: {
    background: '#fff',
    border: '1px solid #bcbcbc',
    cursor: 'pointer',
    borderRadius: 100,
    height: 50,
    width: 141,
    color: '000'
  },
  iconContainer: {
    '& > svg': {
      width: 32,
      height: 32,
      marginRight: 24,
      cursor: 'pointer'
    },
  },
  iconsHeader: {
    width: '100%',
    fontSize: 15,
    marginBottom: 12
  }
}));
