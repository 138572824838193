import axios from "axios";
import {FILTERS_CITIES, FILTERS_COUNTRIES, FILTERS_JOB_TYPES, FILTERS_LANGUAGES_TYPES} from "./constants";

class FiltersApi {
	async getCountries(params) {
		try {
			return axios.get(FILTERS_COUNTRIES, params)
		} catch (e) {
			console.error(e)
		}
	}

	async getCities(params) {
		try {
			return axios.get(FILTERS_CITIES, params)
		} catch (e) {
			console.error(e)
		}
	}

	async getJobTypes(params) {
		try {
			return axios.get(FILTERS_JOB_TYPES, params)
		} catch (e) {
			console.error(e)
		}
	}

	async getLanguages(params) {
		try {
			return axios.get(FILTERS_LANGUAGES_TYPES, params)
		}catch (e) {
			console.error(e)
		}
	}
}

export default FiltersApi;
