import {makeStyles} from "@material-ui/core";

export default makeStyles(theme =>({
	container: {
		padding: 36
	},
	title: {
		fontWeight: 500,
		fontSize: 54
	},
  buttonBack: {
    background: 'transparent',
    border: 'none',
    textAlign: 'left',
    marginBottom: 58,
    '& > img': {
      marginRight: 10
    }
  }
}))
