const parseFooterData = (data = []) => {
	let parentData = data.filter(i => !i.attributes.parent).map(field => ({
		title: field.attributes.title,
		id: field.id
	}))
	data.forEach(item => {
		if (i => i.attributes.parent) {
			const ifParentExist = parentData.findIndex(parent => parent.id === item.attributes.parent)
			if (ifParentExist > -1) {
				parentData[ifParentExist].items = parentData[ifParentExist].items ? [...parentData[ifParentExist].items, {
					title: item.attributes.title,
					id: item.id,
					url: item.attributes.url || ''
				}] : [{
					title: item.attributes.title,
					id: item.id,
					url: item.attributes.url || ''
				}]
			}
		}

	})
	return parentData || []
}

export default parseFooterData;


// const parseFooterData = (data = []) => {
// 	let parentData = data.filter(i => !i.attributes.parent).map(field => ({
// 		title: field.attributes.title,
// 		id: field.id
// 	}))
// 	data.forEach(item => {
// 		if (i => i.attributes.parent) {
// 			const ifParentExist = parentData.findIndex(parent => parent.id === item.attributes.parent)
// 			if (ifParentExist > -1) {
// 				parentData[ifParentExist].items = parentData[ifParentExist].items ? [...parentData[ifParentExist].items, {
// 					title: item.attributes.title,
// 					id: item.id,
// 					url: item.attributes.url || ''
// 				}] : [{
// 					title: item.attributes.title,
// 					id: item.id,
// 					url: item.attributes.url || ''
// 				}]
// 			}
// 		}
//
// 	})
// 	return parentData || []
// }
//
// export default parseFooterData;
