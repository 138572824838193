import { Grid } from '@material-ui/core';

import { MainContainer, Title } from 'components';
import MockImage1 from 'assets/images/Rectangle 1271.svg';
import MockImage2 from 'assets/images/Rectangle 1272.svg';
import MockImage3 from 'assets/images/Rectangle 1271-2.svg';
import { offsetTitleOptionData } from 'components/Title/Title';

import useStyles from './style';

const OneImageBlock = () => (
  <Grid container>
    <Grid item xs={12} component={'img'} alt={'#'} src={MockImage1} />
  </Grid>
);

const TwoImageBlock = () => (
  <Grid container alignItems={'flex-start'} justifyContent={'space-between'} spacing={3}>
    <Grid item xs={8} component={'img'} alt={'#'} src={MockImage3} />
    <Grid item xs={4} component={'img'} alt={'#'} src={MockImage2} />
  </Grid>
);

const ContentBlock = ({
  imageBlock,
  title,
  content,
  imageComponent,
  classes,
  contentPosition,
  ...rest
}) => {
  return (
    <Grid item xs={12} className={classes.imagesBlock}>
      <Grid container alignItems={'center'} justifyContent={'space-between'} {...rest}>
        <Grid item xs={12} sm={6}>
          {imageComponent}
        </Grid>
        <Grid item sm={6}>
          <Grid
            container
            alignItems={'center'}
            className={classes.contentContainer}
            justifyContent={contentPosition}
          >
            <Grid item xs={12} sm={10} className={classes.contentTitle}>
              {title}
            </Grid>
            <Grid item sm={10} className={classes.content}>
              {content}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

const InspirationModule = ({ title = '', themeSelected }) => {
  const classes = useStyles(themeSelected)();
  return (
    <MainContainer themeSelected={themeSelected}>
      <Grid item xs={12}>
        <Title
          offsetOption={offsetTitleOptionData.right}
          title={title}
          label={'label'}
          themeSelected={themeSelected}
          className={classes.title}
        />
        <Grid container justifyContent={'center'}>
          <Grid item xs={12} sm={8}>
            <Grid container>
              <ContentBlock
                imageComponent={<TwoImageBlock />}
                contentPosition={'flex-end'}
                title={'Lorem ipsum'}
                classes={classes}
                content={
                  'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut.'
                }
              />
              <ContentBlock
                contentPosition={'flex-start'}
                imageComponent={<OneImageBlock />}
                classes={classes}
                direction={'row-reverse'}
                title={'Lorem ipsum'}
                content={
                  'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut.'
                }
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </MainContainer>
  );
};

export default InspirationModule;
