import React from 'react';
import {Grid} from '@material-ui/core';
import useCareersFilters from './useCareersFilters';
import {FilterMobileButton} from 'components';
import useStyles from './style';
import {buttonTypeEnum} from "Theme/ThemeSwitching";
import ReplayIcon from '@mui/icons-material/Replay';
import {useSelector} from "react-redux";
import {getInputReselectedStore} from "Redux/Modules/Inputs/reselect";

const CareersFilters = ({resetCareersAction, themeSelected, filtersData}) => {
	const classes = useStyles(themeSelected, buttonTypeEnum.primary)();
	const {filters, listItemAction, handleClickIcon} = useCareersFilters();
	const inputs = useSelector(getInputReselectedStore);

	return (
		<Grid container justifyContent={'space-between'} alignItems={'center'} className={classes.container}>
			<Grid item xs={11}>
				<Grid container alignItems={'center'}>
					{filtersData.map((button, idx) => <FilterMobileButton
						key={button.name}
						themeSelected={themeSelected}
						name={button.name}
						list={filters[button?.name]?.sort((a, b) => (a.title > b.title) ? 1 : ((b.title > a.title) ? -1 : 0))}
						title={inputs[button.name]}
						placeholder={button.placeholder}
						isDropdown
						listItemAction={listItemAction(button.name)}
						handleClickIcon={handleClickIcon(button.name)}
					/>)}
				</Grid>
			</Grid>
			<Grid item xs={1}>
				<Grid container justifyContent={'flex-end'} alignItems={'center'} className={classes.reset}
					  onClick={resetCareersAction}>
					<ReplayIcon/>
					Reset
				</Grid>
			</Grid>
		</Grid>
	);
};

export default CareersFilters;
