import AboutUs from 'pages/AboutUs/AboutUs';
import {Careers} from 'pages/careers/careers';
import NotFoundPage from 'pages/NotFoundPage/NotFoundPage'
import {MainPage} from "../pages/MainPage";
import {Insights} from "../pages/Insights";
import {JobCardDetailsContainer} from "../pages/careers/careers-pages/job-card-details/job-card-details-container";
import Industry from "../pages/IndustryPage/IndustryPage";
import Solution from "../pages/Solution/Solution";
import Location from '../pages/Location/Location'
import {ContactUs} from "../pages/ContactUs";

class BlockTypesEnum {
	['AboutUs'] = 'about_us';
	['Career'] = 'career';
	['Industry'] = 'industry';
	['Location'] = 'location';
	['Solution'] = 'solution';
	['MainPage'] = 'mainPage';
	['NotFoundPage'] = 'notFoundPage';
	["Insights"] = 'insights';
	["JobOffering"] = "job_offering";
	["ContactUs"] = "contact_us";
}

export const blockTypesEnum = new BlockTypesEnum();

class BlockTypes {
	[blockTypesEnum.AboutUs];
	[blockTypesEnum.Career];
	[blockTypesEnum.Industry];
	[blockTypesEnum.Location];
	[blockTypesEnum.Solution];
	[blockTypesEnum.MainPage];
	[blockTypesEnum.NotFoundPage];
	[blockTypesEnum.Insights];
	[blockTypesEnum.JobOffering];
	[blockTypesEnum.ContactUs];

	constructor(AboutUs, Careers, Industry, Location, Solution, MainPage, JobCardDetailsContainer, ContactUs) {
		this[blockTypesEnum.AboutUs] = (props) => <AboutUs {...props}/>;
		this[blockTypesEnum.Career] = (props) => <Careers {...props}/>;
		this[blockTypesEnum.Industry] = (props) => <Industry {...props}/>;
		this[blockTypesEnum.Location] = (props) => <Location {...props}/>;
		this[blockTypesEnum.Solution] = (props) => <Solution {...props}/>;
		this[blockTypesEnum.MainPage] = (props) => <MainPage {...props}/>;
		this[blockTypesEnum.NotFoundPage] = (props) => <NotFoundPage {...props}/>;
		this[blockTypesEnum.Insights] = (props) => <Insights {...props}/>;
		this[blockTypesEnum.JobOffering] = (props) => <JobCardDetailsContainer {...props}/>;
		this[blockTypesEnum.ContactUs] = (props) => <ContactUs {...props}/>;
	}

	getPageType(pageData, props) {
		if (this[pageData]) {
			return this[pageData](props)
		} else {
			return <NotFoundPage/>
		}
	}
}

export default new BlockTypes(AboutUs, Careers, Industry, Location, Solution, MainPage, JobCardDetailsContainer, ContactUs);
