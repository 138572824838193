import { makeStyles } from '@material-ui/core';

const styles = (themeSelected) =>
  makeStyles((theme) => ({
    container: {
      width: '100%',
      padding: '24px 36px'
    },
    officeTitle: {
      fontSize: 19,
      lineHeight: '24px',
      fontWeight: 500
    },
    officeText: {
      fontSize: 15
    },
    selectOfice: {
      width: '100%',
      height: 40,
      fontSize: 15,
      borderRadius: '50px',
      marginBottom: 50
    },
    selectOfficeRoot: {
      '&:focus': {
				background: 'transparent'
			}
    }
  }));

export default styles;
