import {makeStyles} from '@material-ui/core';
import {themeType} from 'Theme/ThemeSwitching';

const styles = (themeSelected) =>
	makeStyles((theme) => ({
		container: {
			padding: '120px 0',
			maxWidth: 1680,
		},
		mainContainer: {
			padding: '0 36px'
		},
		title: {
			fontStyle: 'normal',
			fontWeight: 500,
			fontSize: 61,
			letterSpacing: -2.2,
			[theme.breakpoints.down('sm')]: {
				fontSize: 39,
				letterSpacing: -1.2
			},
			[theme.breakpoints.down('xs')]: {
				marginTop: 16
			},
			...themeType[themeSelected].title
		},
		carouselImage: {
			width: '100%'
		},
		carouselImageHidden: {
			height: '100%'
		},
		activeItem: {
			width: '100%',
			maxWidth: 700
		},
		inActiveItem: {
			width: '50%',
			maxWidth: 400,
			opacity: 0.7
		},
		description: {
			marginBottom: 84,
			marginTop: 48,
			fontSize: 17,
			lineHeight: '21px',
			fontWeight: 400,
			...themeType[themeSelected].title
		},
		imageContainer: {
			position: 'relative',
			height: 'calc(100% + 150px)'
		},
		imageDescription: {
			left: 10,
			zIndex: 99,
			fontSize: 14,
			lineHeight: '19px',
			fontWeight: 400,
			position: 'absolute',
			...themeType[themeSelected].subtitle
		},
		image: {
			width: '100%',
			padding: '0 10px'
		},
		blurredImage: {
			width: '100%',
			padding: '0 10px',
			opacity: 0.4
		}
	}));

export default styles;
