import {makeStyles} from "@material-ui/core";
import {themeType} from "Theme/ThemeSwitching";

const styles = (themeSelected) => makeStyles(theme => ({
	title: {
		fontWeight: 500,
		fontSize: 63,
		letterSpacing: -2.2,
		textAlign: 'left',
		[theme.breakpoints.down('sm')]: {
			fontSize: 39,
			letterSpacing: -1.2,

		},
	},
	transcom: {
		fontSize: 12,
		textAlign: 'right',
		[theme.breakpoints.down('xs')]: {
			textAlign: 'left'
		}
	},
	imagesBlock: {
		marginTop: 120,
		[theme.breakpoints.down('sm')]: {
			marginTop: 80,
		},
		[theme.breakpoints.down('xs')]: {
			marginTop: 56,
		},
	},
	contentContainer: {
		[theme.breakpoints.down('xs')]: {
			marginTop: 40,
		},
	},
	contentTitle: {
		...themeType[themeSelected].title,
		fontWeight: 'normal',
		fontSize: 39,
		letterSpacing: -0.9,
		display: 'flex',
		alignItems: 'flex-end',
		marginBottom: 18,
		[theme.breakpoints.down('sm')]: {
			marginBottom: 12,
			letterSpacing: -0.7,
			fontSize: 30,
		},
	},
	content: {
		...themeType[themeSelected].subtitle,
		fontSize: 19,
		letterSpacing: -0.4,
		marginTop: 18,
		[theme.breakpoints.down('sm')]: {
			marginTop: 12,
			fontSize: 17,
			letterSpacing: -0.2,
		},
		[theme.breakpoints.down('xs')]: {
			marginTop: 10,
		},
	}
}))


export default styles;
