// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".BurgerIcon_container__Dyml0 {\n    /*display: inline-block;*/\n    cursor: pointer;\n    display: flex;\n    flex-direction: column;\n    align-items: flex-end;\n    z-index: 2;\n}\n\n.BurgerIcon_bar1__2a0f6, .BurgerIcon_bar2__2cRfd, .BurgerIcon_bar3__1PmzX {\n    width: 30px;\n    height: 2px;\n    background-color: #333;\n    margin: 3px 0;\n    transition: 0.4s;\n}\n\n.BurgerIcon_change1__23J7s {\n    transform: rotate(-45deg) translate(-4px, 6px);\n}\n\n.BurgerIcon_change2__1bg4M {opacity: 0;}\n\n.BurgerIcon_change3__QE29Y {\n    transform: rotate(45deg) translate(-5px, -8px);\n}\n", "",{"version":3,"sources":["webpack://src/components/BurgerIcon/BurgerIcon.module.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,eAAe;IACf,aAAa;IACb,sBAAsB;IACtB,qBAAqB;IACrB,UAAU;AACd;;AAEA;IACI,WAAW;IACX,WAAW;IACX,sBAAsB;IACtB,aAAa;IACb,gBAAgB;AACpB;;AAEA;IAEI,8CAA8C;AAClD;;AAEA,4BAAU,UAAU,CAAC;;AAErB;IAEI,8CAA8C;AAClD","sourcesContent":[".container {\n    /*display: inline-block;*/\n    cursor: pointer;\n    display: flex;\n    flex-direction: column;\n    align-items: flex-end;\n    z-index: 2;\n}\n\n.bar1, .bar2, .bar3 {\n    width: 30px;\n    height: 2px;\n    background-color: #333;\n    margin: 3px 0;\n    transition: 0.4s;\n}\n\n.change1 {\n    -webkit-transform: rotate(-45deg) translate(-9px, 6px);\n    transform: rotate(-45deg) translate(-4px, 6px);\n}\n\n.change2 {opacity: 0;}\n\n.change3 {\n    -webkit-transform: rotate(45deg) translate(-8px, -8px);\n    transform: rotate(45deg) translate(-5px, -8px);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "BurgerIcon_container__Dyml0",
	"bar1": "BurgerIcon_bar1__2a0f6",
	"bar2": "BurgerIcon_bar2__2cRfd",
	"bar3": "BurgerIcon_bar3__1PmzX",
	"change1": "BurgerIcon_change1__23J7s",
	"change2": "BurgerIcon_change2__1bg4M",
	"change3": "BurgerIcon_change3__QE29Y"
};
export default ___CSS_LOADER_EXPORT___;
