import { makeStyles } from '@material-ui/core';

const styles = (themeSelected) =>
  makeStyles((theme) => ({
    container: {
      width: '100%',
      paddingRight: 24
    },
    accordion: {
      boxShadow: 'none',
      '&:before': {
        background: 'transparent'
      }
    },
    title: {
      fontSize: 19,
      color: '#0C0C0C'
    }
  }));

export default styles;
