import React from 'react';
import {Grid} from '@material-ui/core';
import {Input} from 'components';
// import SearchIcon from 'assets/icons/Search.svg';
import useStyles from './style';
import SearchIcon from '@mui/icons-material/Search';
import {themeType} from "../../Theme/ThemeSwitching";

const CareersInput = ({
						  placeholder,
						  label,
						  name,
						  handleSubmit = () => null,
						  themeSelected,
						  inputRightIcon,
						  isMobile
					  }) => {
	const classes = useStyles(themeSelected)();
	return (
		<Grid item xs={12} className={classes.inputContainer} component={'form'} onSubmit={handleSubmit(name)}>
			<Grid container justifyContent={'center'}>
				<Grid item xs={8} className={classes.inputLabel}>
					{label}
				</Grid>
				<Grid item xs={12}>
					<Input
						themeSelected={themeSelected}
						LeftImage={SearchIcon}
						leftImageStyle={{
							paddingLeft: isMobile ? 0 : 36,
							width: 60, ...themeType.getThemeData(themeSelected).subtitle
						}}
						className={classes.input}
						// style={{paddingLeft: 64}}
						name={name}
						placeholder={placeholder}
						RightImage={!isMobile && inputRightIcon}
						rightImageStyle={{right: 10, ...themeType.getThemeData(themeSelected).background}}
						submit={handleSubmit}
					/>
				</Grid>
			</Grid>
		</Grid>
	);
};

export default CareersInput;
