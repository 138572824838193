import {Grid} from '@material-ui/core';

import {BurgerIcon} from 'components';

import LogoImage from '../../assets/images/Label';
import useHeaderBurger from './useHeaderBurger';
import {BurgerMenuItem} from './BurgerMenuItem';
import GlobalIcon from 'assets/icons/GlobalIcon';
import PhoneIcon from 'assets/icons/PhoneIcon';
import useStyles from './style';

const HeaderBurger = () => {

	const {toggled, handleToggleBurger, headerData, handleRedirectPage, theme, headerAdditional} = useHeaderBurger();
	const classes = useStyles(theme)();
	return (
		<Grid container>
			<Grid item xs={12} component={'nav'} className={classes.container}>
				<Grid
					container
					justifyContent={'space-between'}
					className={classes.logoContainer}
				>
					<Grid
						item
						xs={3}
						onClick={handleRedirectPage('/')}
						component={LogoImage}
						className={classes.logoImg}
					/>
					<Grid item xs={2} className={classes.burgerContainer}>
						<BurgerIcon
							toggled={toggled}
							handleToggleBurger={handleToggleBurger}
						/>
					</Grid>
				</Grid>
				<Grid
					container
					// direction={'column'}
					alignContent={'flex-start'}
					className={`${classes.linksContainer} ${
						toggled ? classes.menuOpened : classes.menuClosed
					}`}
				>
					{headerData.map((menuItem) => (
						<BurgerMenuItem
							handleRedirectPage={handleRedirectPage}
							key={menuItem.id}
							classes={classes}
							menuItem={menuItem}
						/>
					))}
					<Grid item xs={12} style={{color: 'red'}} className={classes.subMenuContainer}>
						<div className={`${classes.divider}`}/>
						<Grid container className={classes.globalContainer}>
							<BurgerMenuItem
								handleRedirectPage={handleRedirectPage}
								key={headerAdditional[0]?.id}
								classes={classes}
								menuItem={headerAdditional[0]}
								icon={<GlobalIcon/>}
							/>
						</Grid>
						<div className={`${classes.divider}`}/>
						<Grid container className={classes.globalContainer}>
							<BurgerMenuItem
								handleRedirectPage={handleRedirectPage}
								key={headerAdditional[1]?.id}
								classes={classes}
								menuItem={headerAdditional[1]}
								icon={<PhoneIcon/>}
							/>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
};

export default HeaderBurger;
