import {useSelector} from "react-redux";
import filtersReselected from "Redux/Modules/Filters/reselect";
import {useActionCreator} from "Redux/useActionCreator";

const useCareersFilters = () => {
	const {updateInputsStoreAction} = useActionCreator();
	const filters = useSelector(filtersReselected.mapDataForSelectMenu);

	const listItemAction = (name) => (item) => {
		updateInputsStoreAction({[name]: item.title})
	}
	const handleClickIcon = (name) => () => {
		updateInputsStoreAction({[name]: ''})
	}


	return {
		filters,
		listItemAction,
		handleClickIcon,

	}
}

export default useCareersFilters;
