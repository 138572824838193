import {makeStyles} from "@material-ui/core";
import {themeType} from "Theme/ThemeSwitching";

// eslint-disable-next-line import/no-anonymous-default-export
export default (themeSelected) => makeStyles(theme => ({
	container: {
		position: 'relative',
		textAlign: 'left',
		...themeType.getThemeData(themeSelected).title,
	},
	searchBlock: {
		paddingBottom: 148,
		[theme.breakpoints.down('sm')]: {
			paddingBottom: 56,
		}
	},
	labelContainer: {
		padding: '0 36px !important',
		[theme.breakpoints.down('sm')]: {
			padding: '16px !important'
		}
	},

	columnBlockContainer: {
		...themeType.getThemeData(themeSelected).title,
		padding: '0 36px 148px 36px !important',
		textAlign: 'left',
		'& span': {
			// fontSize: 19,
			[theme.breakpoints.down('xs')]: {
				marginTop: 20,
				fontSize: 17
			}
		},
		[theme.breakpoints.down('xs')]: {
			padding: '16px 16px 56px 16px !important',
		}
	},
	exploreAllContainer: {
		display: 'flex',
		alignItems: 'center',
		fontSize: 15,
		letterSpacing: -0.2,
		cursor: 'pointer',
		justifyContent: 'center',
		[theme.breakpoints.down('sm')]: {
			marginTop: 20,
			fontSize: 17
		}
	},

	spaceForHeader: {
		height: 150,
		width: '100%',
		[theme.breakpoints.down('sm')]: {
			height: 20
		}
	}

}))
