import React from 'react';

import styles from './BurgerIcon.module.css';

const BurgerIcon = ({ toggled, handleToggleBurger }) => {
  return (
    <div className={styles.container} onClick={handleToggleBurger}>
      <div className={`${styles.bar1} ${toggled && styles.change1}`} />
      <div className={`${styles.bar2} ${toggled && styles.change2}`} />
      <div className={`${styles.bar3} ${toggled && styles.change3}`} />
    </div>
  );
};

export default BurgerIcon;
