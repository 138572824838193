import { Grid } from '@material-ui/core';
import { API_URL } from 'API/constants';
import { MainContainer } from 'components';

import useStyles from './style';

const getLogoWidth = (arr, idx) => {
  if (arr.length === 1) return '100%';

  switch (idx) {
    case 0:
    case 1:
      return arr.length > 4 ? '32%' : '49%';
    case 2:
      return arr.length === 3 ? '100%' : arr.length === 4 ? '49%' : '32%';
    case 3:
    case 4:
      return arr.length === 5 ? '49%' : '32%';
    case 5:
      return '32%';
    default:
      return '49%'
  }
};

const PartnershipsModule = ({ title, partnershipsData, themeSelected, label, blockName }) => {
  const classes = useStyles(themeSelected)();
  const iconPartnerClassName = partnershipsData.length > 1 ? classes.iconMultiple : classes.icon;

  return (
    <MainContainer justifyContent={'center'} themeSelected={themeSelected} blockName={blockName}>
      <Grid item md={10}>
        <Grid container justifyContent={'center'} className={classes.contextContainer}>
          <Grid item xs={12} sm={8}>
            <Grid container alignContent={'space-between'} className={classes.partnerContainer}>
              {partnershipsData.map((partner, idx) => (
                <Grid
                  style={{ width: getLogoWidth(partnershipsData, idx) }}
                  item
                  key={partner.id}
                  className={classes.partner}
                >
                  <img src={API_URL + partner.img} alt={'#'} className={iconPartnerClassName} />
                </Grid>
              ))}
            </Grid>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Grid container justifyContent={'center'} className={classes.titleContainer}>
              <Grid item xs={12} sm={9} className={classes.title}>
                <span className={classes.label}>{label}</span>
                {title}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </MainContainer>
  );
};

export default PartnershipsModule;
