import {blockTypesEnum} from "../BlockConstructor/BlockTypes";

class PageBlockUrl {
	BASE_URL = process.env.REACT_APP_BASE_URL;
	PAGE_BLOCK_QUERY;
	BASE_BLOCK;

	constructor(PAGE_BLOCK) {
		this.PAGE_BLOCK_QUERY = `${PAGE_BLOCK}`;
		this.BASE_BLOCK = `${this.BASE_URL}${PAGE_BLOCK}`;
	}
}


class ConstantsClass {
	API_URL = process.env.REACT_APP_URL;
	SUBREQUEST = `${this.API_URL}/en/subrequests?_format=json`;

	[blockTypesEnum.Career];
	[blockTypesEnum.MainPage];
	[blockTypesEnum.NotFoundPage];
	[blockTypesEnum.AboutUs];
	[blockTypesEnum.Insights];
	[blockTypesEnum.JobOffering];
	[blockTypesEnum.Industry];
	[blockTypesEnum.Solution];
	[blockTypesEnum.ContactUs];
	[blockTypesEnum.Location];

	constructor(mainPage, career, notFoundPage, aboutUs, insights, jobOffering, Industry, Solution, ContactUs, Location) {
		this[blockTypesEnum.MainPage] = mainPage;
		this[blockTypesEnum.Career] = career;
		this[blockTypesEnum.NotFoundPage] = notFoundPage;
		this[blockTypesEnum.AboutUs] = aboutUs;
		this[blockTypesEnum.Insights] = insights;
		this[blockTypesEnum.JobOffering] = jobOffering;
		this[blockTypesEnum.Industry] = Industry;
		this[blockTypesEnum.Solution] = Solution;
		this[blockTypesEnum.ContactUs] = ContactUs;
		this[blockTypesEnum.Location] = Location;
	}

	getUrl(pageType) {
		return this[pageType]
	}
}


export default new ConstantsClass(
	new PageBlockUrl('/node/homepage?include=field_sections,field_sections.field_hero_images.field_media_image,field_sections.field_images,field_sections.field_images.field_media_image,field_sections.field_documents_tab,field_sections.field_documents_tab.field_documents_tab_content,field_sections.field_indicators,field_sections.field_documents_tab.field_documents_tab_content.field_file_or_link,field_sections.field_documents_tab.field_documents_tab_content.field_file_or_link.field_documents,field_sections.field_documents_tab.field_documents_tab_content.field_file_or_link.field_documents.field_media_file,field_sections.field_use_cases,field_sections.field_quote_slide,field_sections.field_result,field_sections.field_article_card,field_sections.field_article_card,field_sections.field_article_card.field_image,field_sections.field_article_card.field_image.field_media_image,field_sections.field_image.field_media_image,field_sections.field_video_or_image,field_sections.field_video_or_image.field_video,field_sections.field_video_or_image.field_image,field_sections.field_video_or_image.field_image.field_media_image,field_sections.field_reply,field_sections.field_image_with_description,field_sections.field_image_with_description.field_image,field_sections.field_image_with_description,field_sections.field_image_with_description.field_image.field_media_image,field_sections.field_article_card,field_sections.field_article_card.field_image,field_sections.field_article_card.field_image.field_media_image'),
	new PageBlockUrl('?include=field_sections,field_sections.field_image_and_text,field_sections.field_image_and_text.field_image,field_sections.field_image_and_text.field_image.field_media_image,field_sections.field_article_card,field_sections.field_quote_slide,field_sections.field_image_with_description,field_sections.field_indicators,field_sections.field_award.field_image.field_media_image,field_sections.field_quote_slide.field_image,field_sections.field_quote_slide.field_image.field_media_image'),
	new PageBlockUrl('/node/error_page_2021?include=field_sections'),
	new PageBlockUrl('?include=filter[city][condition][path]=field_press_article&filter[city][condition][operator]==&filter[city][condition][value]=1&include=field_sections,field_sections.field_article_card,field_sections.field_article_card.field_image,field_sections.field_article_card.field_image.field_media_image,field_sections.field_image_and_text,field_sections.field_image_and_text.field_image,field_sections.field_image_and_text.field_image.field_media_image,field_sections.field_images,field_sections.field_images.field_media_image,field_sections.field_main_content,field_sections.field_vertical_tab,field_sections.field_vertical_tab.field_tab_content,field_sections.field_article_card_expandable,field_sections.field_article_card_expandable.field_image,field_sections.field_article_card_expandable.field_image.field_media_image,field_sections.field_documents_tab,field_sections.field_documents_tab.field_documents_tab_content,field_sections.field_documents_tab.field_documents_tab_content.field_file_or_link,field_sections.field_documents_tab.field_documents_tab_content.field_file_or_link.field_documents,field_sections.field_documents_tab.field_documents_tab_content.field_file_or_link.field_documents.field_media_file'),
	new PageBlockUrl("?include=field_insight_industry,field_type,field_listing_image.field_media_image,field_sections.field_image,field_sections.field_image.field_media_image,field_sections.field_main_content&sort=-created&filter[city][condition][path]=field_featured&filter[city][condition][operator]==&filter[city][condition][value]=1"),
	new PageBlockUrl("?include=field_taxonomy_city,country,job_type,field_taxonomy_city,field_taxonomy_language&fields[jobs]=title,field_taxonomy_city,field_taxonomy_language,country,job_type,body,link&fields[city]=name&fields[country]=name&fields[job-type]=name&fields[language]=name&sort[sort-created][path]=created&sort[sort-created][direction]=DESC"),
	new PageBlockUrl("?include=field_sections,field_sections.field_article_card,field_sections.field_article_card.field_image,field_sections.field_article_card.field_image.field_media_image,field_sections.field_result,field_sections.field_image,field_sections.field_image.field_media_image,field_sections.field_images,field_sections.field_images.field_media_image,field_sections.field_use_cases"),
	new PageBlockUrl("?include=field_sections,field_sections.field_article_card,field_sections.field_article_card.field_image,field_sections.field_article_card.field_image.field_media_image,field_sections.field_result,field_sections.field_use_cases,field_sections.field_image_and_text,field_sections.field_image_and_text.field_image,field_sections.field_image_and_text.field_image.field_media_image"),
	new PageBlockUrl("?include=field_sections.field_images,field_sections.field_images.field_media_image"),
	new PageBlockUrl("?include=field_sections.field_article_card,field_sections.field_article_card.field_image,field_sections.field_article_card.field_image.field_media_image,field_sections.field_result,field_sections.field_article_card,field_sections.field_article_card,field_sections.field_article_card.field_image,field_sections.field_article_card.field_image.field_media_image,field_sections.field_image_with_description,field_sections.field_image_with_description.field_image,field_sections.field_image_with_description,field_sections.field_image_with_description.field_image.field_media_image,field_sections.field_indicators,field_sections.field_vertical_tab,field_sections.field_vertical_tab.field_tab_content,field_sections.field_video_or_image,field_sections.field_video_or_image.field_video,field_sections.field_video_or_image.field_image,field_sections.field_video_or_image.field_image.field_media_image,field_sections.field_image.field_media_image")
);
