class StylesEnum {
	blondeBackground = 'blondeBackground';
	pinkBackground = 'pinkBackground';
	azureBackground = 'azureBackground';
	lavenderBackground = 'lavenderBackground';
	yellowBackground = 'yellowBackground';
	coralBackground = 'coralBackground';
	acquaBackground = 'acquaBackground';
	bronzeBackground = 'bronzeBackground';
	brownBackground = 'brownBackground';
	brightBlueBackground = 'brightBlueBackground';
	darkBlueBackground = 'darkBlueBackground';
	blackBackground = 'blackBackground';
	whiteBackground = 'whiteBackground';
	platinumBackground = 'platinumBackground';
	spaceGreyBackground = 'spaceGreyBackground';
	darkGreenBackground = 'darkGreenBackground';
}

let stylesEnumObject = new StylesEnum();

export const ProtectedProxy = (obj) => new Proxy(obj, {
	set(target, prop, val) {
		if (!target[prop] || target[prop] !== val) {
			throw new Error('Cant do this')
		}
		return true
	}
})

stylesEnumObject = ProtectedProxy(stylesEnumObject)
export default stylesEnumObject;
