import useStyle from './style';
import stylesEnumObject from "../../Theme/ThemeSwitching/theneNames";
import {MainContainer, Title} from "components";
import {Grid} from "@material-ui/core";
import React from "react";
import {offsetTitleOptionData} from "components/Title/Title";
import Tabs from './Test'

const VerticalTabsModule = ({
								blockName = '',
								steps = [],
								themeSelected = stylesEnumObject.whiteBackground,
								offsetOption = offsetTitleOptionData.right,
								title = 'We’ve been around',
								label = 'label',
								subtitle = 'We are the voice of our clients. Equipped with intelligent technology, we are a global network of local specialists, ambitious about making things work. We continuously evolve to be one step ahead, anticipating the newest technology and trends to create a new way to customer ',
							}) => {
	const classes = useStyle(themeSelected)();

	return (
		<MainContainer themeSelected={themeSelected} blockName={blockName}>
			<Grid item xs={12}>
				<Title
					offsetOption={offsetOption}
					title={title}
					label={label}
					themeSelected={themeSelected}
					className={classes.title}
				/>
				<Grid container className={classes.subtitle} justifyContent={'center'}>
					<Grid item xs={8}>
						{subtitle}
					</Grid>
				</Grid>
				<Grid container justifyContent={'center'}>
					<Grid item xs={8}>
						<Tabs classes={classes} steps={steps}/>
					</Grid>
				</Grid>
			</Grid>
		</MainContainer>

	)
}

export default VerticalTabsModule;
