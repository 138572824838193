import { makeStyles } from '@material-ui/core';
import { themeType } from 'Theme/ThemeSwitching';
import themeTypography from 'Theme/typography';

const styles = (themeSelected) =>
  makeStyles((theme) => ({
    title: {
      marginBottom: 75,
      ...themeTypography.headline2,
      ...themeType[themeSelected].title,
      [theme.breakpoints.down('sm')]: {
        marginBottom: 25,
      },
    },
    container: {
      margin: 'auto',
    },
    articleContainer: {
      [theme.breakpoints.down('sm')]: {
        flexWrap: 'nowrap',
        overflow: 'auto'
      },
      '&::-webkit-scrollbar': {
        height: 3,
        backgroundColor: '#f2f2f2'
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: '#8c8c8c',
        borderRadius: 35
      }
    },
    awardTitle: {
      margin: '15px 0',
      ...themeType[themeSelected].title,
    },
    awardDesc: {
      ...themeType[themeSelected].title,
    },
    award: {
      marginBottom: 50,
      cursor: 'pointer',
      transition: 'all linear 0.3s',
      '& img': {
        width: '100%',
        objectFit: 'cover',
        border: '1px solid #bcbcbc',
        borderRadius: 3
      },
      '&:hover': {
        transform: 'scale(1.03)'
      },
      [theme.breakpoints.down('sm')]: {
        minWidth: '75%'
      }
    }
  }));

export default styles;
