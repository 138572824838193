import {makeStyles} from '@material-ui/core';
import {themeType} from "Theme/ThemeSwitching";

const styles = (themeSelected) =>
	makeStyles((theme) => ({
		mainContainer: {
			padding: '50px 36px',
			maxWidth: 1680,
			[theme.breakpoints.down('sm')]: {
				padding: '25px',
			},
		},
		quotesItem: {
			height: '100%',
			minHeight: 700,
			[theme.breakpoints.down('sm')]: {
				minHeight: 550
			},
		},
		sliderContainer: {
			marginTop: 118,
			padding: '50px 0',
			minHeight: 440,
			textAlign: 'left'
		},
		quouteContainer: {
			marginBottom: 'auto',
			marginTop: 'auto',
			'& img': {
				width: '100%'
			}
		},
		sliderTitle: {
			...themeType[themeSelected].title,
			[theme.breakpoints.down('md')]: {
				fontSize: 39,
				lineHeight: '40px'
			},
		},
		text: {
			...themeType[themeSelected].subtitle,
			margin: '5px 0'
		},
		sliderIcon: {
			width: '50px !important',
			height: '50px !important',
			...themeType[themeSelected].subtitle
		},
		sliderIndicator: {
			display: 'flex',
		}
	}));

export default styles;
