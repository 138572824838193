import API from 'API/index';

import { UPDATE_CONTACT_US_STORE } from './contactUs-type';

class ContactUsActions {
  updateContactUsStore = (payload) => ({
    type: UPDATE_CONTACT_US_STORE,
    payload
  });

  getContactUsCountries = () => async (dispatch, store, query) => {
    try {
      const queryData = query.config;
      const res = await API.contactus.getCountries();
      const updatedData = res.data.data.map((i) => ({
        ...i,
        attributes: { ...i.attributes, type: i.type }
      }));

      const countries = queryData.normalize({ data: updatedData }).data.map((country) => ({
        title: country.title,
        id: country.drupal_internal__nid
      })).sort((a, b) => a.title.localeCompare(b.title));

      dispatch(this.updateContactUsStore({ countries }));
    } catch (e) {
      console.error(e);
    }
  };

  getContactUsOffices = (id) => async (dispatch, store, query) => {
    try {
      const queryData = query.config;
      const res = await API.contactus.getOffices(id);
      const updatedData = res.data.data.map((i) => ({
        ...i,
        attributes: { ...i.attributes, type: i.type }
      }));

      const offices = queryData
        .normalize({ data: updatedData })
        .data.map((office) => ({
          id: office.id,
          description: office.body.value,
          city: office.title
        }));

      dispatch(this.updateContactUsStore({ offices }));
    } catch (e) {
      console.error(e);
    }
  };

  clearContactUsData = (id) => async (dispatch) => {
    try {
      dispatch(this.updateContactUsStore({ offices: [], countries: [] }));
    } catch (e) {
      console.error(e);
    }
  };
}

export default new ContactUsActions();
