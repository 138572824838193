import {useEffect, useRef} from "react";
import {handleSetLocalStorageObjectData} from "../helpers/handleSetLocalStorageObjectData";

export const useThemeToggleByScroll = ({blockName = '', themeSelected}) => {
	const ref = useRef()
	const getPositionData = () => {
		const data = ref.current?.getBoundingClientRect();
		const localStorageData = {
			[blockName]: {
				y: data?.y,
				bottom: data?.bottom,
				height: data?.height,
				themeSelected,
				id: blockName
			}
		}
		handleSetLocalStorageObjectData('observer', localStorageData)
	}
	useEffect(() => {
		window.addEventListener('scroll', getPositionData)
		return () => window.removeEventListener('scroll', getPositionData)
		// eslint-disable-next-line
	}, [ref, themeSelected])

	return {
		ref
	}
}
