import stylesEnum, {ProtectedProxy} from './theneNames';
import DynamicClassConstructor from "./classConstructor";
import colors from '../colors';
import ClassThemeGenerator from './ClassThemeGenerator';

class ClassesOptionsEnum {
	background = 'background';
	title = 'title';
	subtitle = 'subtitle';
	readMore = 'readMore';
	button = 'button'
}

class ButtonTypeEnum {
	primary = 'primary';
	secondary = 'secondary';
}

export const buttonTypeEnum = new ButtonTypeEnum()

let classesOptionsEnum = new ClassesOptionsEnum();
classesOptionsEnum = ProtectedProxy(classesOptionsEnum)


class ClassType {
	constructor(
		backgroundColor = '',
		titleColor = '',
		subtitleColor = '',
		readMore = '',
		buttonStyle = {},
	) {
		this[classesOptionsEnum.background] = new DynamicClassConstructor.Background(backgroundColor)
		this[classesOptionsEnum.title] = new DynamicClassConstructor.Title(titleColor)
		this[classesOptionsEnum.subtitle] = new DynamicClassConstructor.Subtitle(subtitleColor)
		this[classesOptionsEnum.readMore] = new DynamicClassConstructor.ReadMore(readMore)
		this[classesOptionsEnum.button] = buttonStyle
	}
}

class ButtonOptionalStyle {
	constructor(primary, secondary) {
		this[buttonTypeEnum.primary] = primary;
		this[buttonTypeEnum.secondary] = secondary;
	}
}


const buttonType = new ClassThemeGenerator(
	new ButtonOptionalStyle(new DynamicClassConstructor.Button(colors.yellow, colors.darkBlack), new DynamicClassConstructor.Button(colors.darkBlack, colors.white, colors.darkGrey)),
	new ButtonOptionalStyle(new DynamicClassConstructor.Button(colors.brightBlue, colors.white), new DynamicClassConstructor.Button(colors.white, colors.darkBlack, colors.darkGrey)),
	new ButtonOptionalStyle(new DynamicClassConstructor.Button(colors.darkBlack, colors.white), new DynamicClassConstructor.Button(colors.lavender, colors.darkBlack, colors.darkGrey)),
	new ButtonOptionalStyle(new DynamicClassConstructor.Button(colors.darkBlack, colors.white), new DynamicClassConstructor.Button(colors.acqua, colors.darkBlack, colors.darkGrey)),
	new ButtonOptionalStyle(new DynamicClassConstructor.Button(colors.darkBlack, colors.white), new DynamicClassConstructor.Button(colors.pink, colors.darkBlack, colors.darkGrey)),
	new ButtonOptionalStyle(new DynamicClassConstructor.Button(colors.darkBlack, colors.white), new DynamicClassConstructor.Button(colors.coral, colors.darkBlack, colors.darkGrey)),
	new ButtonOptionalStyle(new DynamicClassConstructor.Button(colors.darkBlack, colors.white), new DynamicClassConstructor.Button(colors.azure, colors.darkBlack, colors.darkGrey)),
	new ButtonOptionalStyle(new DynamicClassConstructor.Button(colors.darkBlack, colors.white), new DynamicClassConstructor.Button(colors.blonde, colors.darkBlack, colors.darkGrey)),
	new ButtonOptionalStyle(new DynamicClassConstructor.Button(colors.darkBlack, colors.white), new DynamicClassConstructor.Button(colors.yellow, colors.darkBlack, colors.darkGrey)),
	new ButtonOptionalStyle(new DynamicClassConstructor.Button(colors.darkBlack, colors.white), new DynamicClassConstructor.Button(colors.yellow, colors.darkBlack, colors.darkGrey)),
	new ButtonOptionalStyle(new DynamicClassConstructor.Button(colors.brightBlue, colors.white), new DynamicClassConstructor.Button(colors.yellow, colors.darkBlack, colors.darkGrey)),
	new ButtonOptionalStyle(new DynamicClassConstructor.Button(colors.white, colors.black), new DynamicClassConstructor.Button(colors.darkBlue, colors.white, colors.lightGrey)),
	new ButtonOptionalStyle(new DynamicClassConstructor.Button(colors.white, colors.black), new DynamicClassConstructor.Button(colors.brightBlue, colors.white, colors.lightGrey)),
	new ButtonOptionalStyle(new DynamicClassConstructor.Button(colors.white, colors.black), new DynamicClassConstructor.Button(colors.brown, colors.white, colors.lightGrey)),
	new ButtonOptionalStyle(new DynamicClassConstructor.Button(colors.white, colors.black), new DynamicClassConstructor.Button(colors.darkGreen, colors.white, colors.lightGrey)),
	new ButtonOptionalStyle(new DynamicClassConstructor.Button(colors.white, colors.black), new DynamicClassConstructor.Button(colors.darkGrey, colors.white, colors.lightGrey))
)

export const themeType = new ClassThemeGenerator(
	new ClassType(colors.darkBlack, colors.white, colors.white, colors.yellow, buttonType.getThemeData(stylesEnum.blackBackground)),
	new ClassType(colors.white, colors.darkBlack, colors.darkBlack, colors.blue, buttonType.getThemeData(stylesEnum.whiteBackground)),
	new ClassType(colors.lavender, colors.darkBlack, colors.darkBlack, colors.darkGrey, buttonType.getThemeData(stylesEnum.lavenderBackground)),
	new ClassType(colors.acqua, colors.darkBlack, colors.darkBlack, colors.darkGrey, buttonType.getThemeData(stylesEnum.acquaBackground)),
	new ClassType(colors.pink, colors.darkBlack, colors.darkBlack, colors.darkGrey, buttonType.getThemeData(stylesEnum.pinkBackground)),
	new ClassType(colors.coral, colors.darkBlack, colors.darkBlack, colors.darkGrey, buttonType.getThemeData(stylesEnum.coralBackground)),
	new ClassType(colors.azure, colors.darkBlack, colors.darkBlack, colors.darkGrey, buttonType.getThemeData(stylesEnum.azureBackground)),
	new ClassType(colors.blonde, colors.darkBlack, colors.darkBlack, colors.darkGrey, buttonType.getThemeData(stylesEnum.blondeBackground)),
	new ClassType(colors.yellow, colors.darkBlack, colors.darkBlack, colors.darkGrey, buttonType.getThemeData(stylesEnum.yellowBackground)),
	new ClassType(colors.bronze, colors.darkBlack, colors.darkBlack, colors.darkGrey, buttonType.getThemeData(stylesEnum.bronzeBackground)),
	new ClassType(colors.platinum, colors.darkBlack, colors.darkBlack, colors.yellow, buttonType.getThemeData(stylesEnum.platinumBackground)),
	new ClassType(colors.darkBlue, colors.white, colors.white, colors.platinum, buttonType.getThemeData(stylesEnum.darkBlueBackground)),
	new ClassType(colors.brightBlue, colors.white, colors.white, colors.platinum, buttonType.getThemeData(stylesEnum.brightBlueBackground)),
	new ClassType(colors.brown, colors.white, colors.white, colors.platinum, buttonType.getThemeData(stylesEnum.brownBackground)),
	new ClassType(colors.darkGreen, colors.white, colors.white, colors.platinum, buttonType.getThemeData(stylesEnum.darkGreenBackground)),
	new ClassType(colors.darkGrey, colors.white, colors.lightGrey, colors.yellow, buttonType.getThemeData(stylesEnum.spaceGreyBackground))
)
