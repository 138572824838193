import { Grid } from '@material-ui/core';

import { MainContainer, ImageDescriptionCard, Title, AlignContainer } from 'components';
import stylesEnumObject from 'Theme/ThemeSwitching/theneNames';

import useStyles from './style';

const ArticleRowModule = ({
  title,
  contentData,
  themeSelected,
  label,
  titleOffset,
  contentOffset,
  blockName
}) => {

  const classes = useStyles(themeSelected = stylesEnumObject.whiteBackground)();
  return (
    <MainContainer themeSelected={themeSelected} blockName={blockName}>
      <Grid item xs={12}>
        <Title
          offsetOption={titleOffset}
          title={title}
          label={label}
          themeSelected={themeSelected}
          className={classes.title}
        />
        <Grid container alignItems={'center'}>
          <AlignContainer
            Component={({ data }) => (
              <ImageDescriptionCard themeSelected={themeSelected} data={data} />
            )}
            contentData={contentData}
            themeSelected={themeSelected}
            contentOffset={contentOffset}
          />
        </Grid>
      </Grid>
    </MainContainer>
  );
};

export default ArticleRowModule;
