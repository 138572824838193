import {Grid, makeStyles} from "@material-ui/core";
import stylesEnumObject from "Theme/ThemeSwitching/theneNames";
import {useEffect} from "react";
import {useActionCreator} from "Redux/useActionCreator";
import {useSelector} from "react-redux";
import {blockComponents} from "../../BlockConstructor/BlockNames";

const useStyles = () => makeStyles(theme => ({
	label: {
		fontSize: '14vw !important',
		letterSpacing: "-0.06em",
	}
}))

const NotFoundPage = ({themeSelected = stylesEnumObject.whiteBackground}) => {
	const classes = useStyles(themeSelected)();
	const {getNotFoundPageDataAction} = useActionCreator();
	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(getNotFoundPageDataAction, []);

	const {notFoundPageData} = useSelector(state => state.general)
	return (
		<Grid container>
			<Grid item xs={12}>
				{!!notFoundPageData?.length && notFoundPageData.map((block, index) => blockComponents.getComponent(block?.type)({params: block, headerLabelClass:classes.label}, index))}
			</Grid>
		</Grid>
	)
}

export default NotFoundPage;
