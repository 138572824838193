import FiltersApi from './filters';
import MainPageApi from './mainPage';
import GeneralPageApi from './generalData';
import CareersJobs from './careersJob';
import Insights from './insights';
import AboutUs from './aboutUs';
import ContactUs from './contactUs';

class API {
	filtersApi = new FiltersApi();
	mainPageApi = new MainPageApi();
	generalApi = new GeneralPageApi();
	careerJobs = new CareersJobs();
	insights = new Insights();
	aboutus = new AboutUs();
	contactus = new ContactUs();
}

export default new API();
