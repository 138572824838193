import {Grid, makeStyles} from '@material-ui/core';
import {themeType} from 'Theme/ThemeSwitching';
import {useThemeToggleByScroll} from "../../hooks/useThemeToggleByScroll";

const useStyles = (currentTheme) =>
	makeStyles((theme) => {
		return {
			layout: {
				...themeType.getThemeData(currentTheme).background,
				display: 'flex',
				justifyContent: 'center'
			},
			container: {
				padding: '120px 36px 150px 36px',
				maxWidth: 1680,
				[theme.breakpoints.down('sm')]: {
					padding: '80px 16px 100px 16px'
				},
				[theme.breakpoints.down('xs')]: {
					padding: '56px 16px 96px 16px'
				}
			}
		};
	});

const MainContainer = ({children, themeSelected = '', blockName, externalClassName, ...rest}) => {
	const {ref} = useThemeToggleByScroll({themeSelected, blockName})
	const classes = useStyles(themeSelected)();

	return (
		<div ref={ref} style={{width: '100%'}}>
			<div className={classes.layout}>
				<Grid container className={`${classes.container} ${externalClassName}`} {...rest}>
					{children}
				</Grid>
			</div>
		</div>
	);
};

export default MainContainer;
