import {makeStyles} from "@material-ui/core";
import {themeType} from "Theme/ThemeSwitching";

const styles = (themeSelected) => makeStyles(theme => ({
	title: {
		fontFamily: 'IBM Plex Sans',
		fontStyle: 'normal',
		fontWeight: 500,
		fontSize: '5.2vw',
		letterSpacing: -2.2,
		...themeType[themeSelected].title,
		[theme.breakpoints.down('sm')]: {
			fontSize: 39
		},
		[theme.breakpoints.down('xs')]: {
			marginTop: 16
		},
	},

	subtitle: {
		...themeType[themeSelected].subtitle,
		fontStyle: 'normal',
		fontWeight: 'normal',
		fontSize: 19,
		letterSpacing: -0.4,
		textAlign: 'left',
		margin: '24px 0 60px 0',
		[theme.breakpoints.down('sm')]: {
			fontSize: 17,
			letterSpacing: -0.2,
			margin: '32px 0 56px 0',
		}
	}
}))


export default styles;
