import {memo, useRef, useState} from 'react';
import { Grid } from '@material-ui/core';

import { API_URL } from 'API/constants';
import useOutsideToggle from '../../hooks/useOutsideToggle';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

const isMenuOpenIcon = (condition) => condition ? <KeyboardArrowDownIcon/> : <KeyboardArrowUpIcon/>

const MembersModuleItem = memo(({
  image = '',
  title = '',
  subtitle = '',
  description = '',
  alt = '',
  isShownText = '',
  classes = {}
}) => {
  const [isShown, setIsShown] = useState(false);

  const handleToggleIsShown = (data) => () => setIsShown(data);

  const ref = useRef();
  useOutsideToggle(ref, handleToggleIsShown(false), isShown);
    return (
    <Grid container className={classes.cardsContainer}>
      <Grid item xs={12} component={'img'} alt={alt} src={API_URL + image} className={classes.cardItemImage} />
      <Grid item xs={12} className={classes.cardItemTitle}>
        {title}
      </Grid>
      <Grid item xs={12} sm={6} className={classes.cardItemSubtitle}>
        {subtitle}
      </Grid>
      <Grid
        item
        xs={12}
        onClick={handleToggleIsShown(!isShown)}
        className={classes.cardItemShowMore}
        ref={ref}
      >
        {isShownText} {isMenuOpenIcon(isShown)}
      </Grid>
      {isShown && (
        <Grid item xs={12} className={classes.cardItemDescription}>
          {description}
        </Grid>
      )}
    </Grid>
  );
});

export default MembersModuleItem;
