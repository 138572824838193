import {useCallback, useEffect, useRef} from "react";
import {throttle} from "helpers/throttle";

export default function useDocumentScrollThrottled(callback) {
	let previousScrollTop = useRef(0);
	let previousScrollTopValue = 0;
	const handleDocumentScroll = useCallback(() => {
		const {scrollTop: currentScrollTop} = document.documentElement || document.body;
		// eslint-disable-next-line react-hooks/exhaustive-deps
		previousScrollTopValue = previousScrollTop.current;
		previousScrollTop.current = currentScrollTop;

		callback({previousScrollTop:previousScrollTopValue, currentScrollTop});
	}, [])

	const handleDocumentScrollThrottled = throttle(handleDocumentScroll, 250);

	useEffect(() => {
		window.addEventListener('scroll', handleDocumentScrollThrottled);

		return () =>
			window.removeEventListener('scroll', handleDocumentScrollThrottled);
		// eslint-disable-next-line
	}, []);
}
