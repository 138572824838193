import React from 'react';
import { Grid } from '@material-ui/core';

import {Title, MainContainer, Button} from 'components';
import { offsetTitleOptionData } from 'components/Title/Title';

import { useActionCreator } from 'Redux/useActionCreator';

import useStyles from './style';
import {useAnchor} from "hooks/useAnchor";
import {useLinkPush} from "../../hooks/useLinkPush";

const Post = ({ themeSelected, post, idx }) => {
  const { id, title = '', label = '', description = '', offset = 'rightOffset', image = '', link = '', layout = 'layout_a' } = post;
  const classes = useStyles(themeSelected, { offset, layout })();
  const { setStackPost } = useActionCreator();

  const parsedUrlFunction = useLinkPush(link?.url || link?.uri)

  const handleOpenPost = (path) => () => {
    parsedUrlFunction()
    setStackPost(post);
  };

  const {anchorRef} = useAnchor()
  return (
    <Grid
      container
      justifyContent='space-between'
      direction={offset === 'left' ? 'row' : 'row-reverse'}
      className={classes.postContainer}
      ref={anchorRef}
      id={`#stack_anchor_id_${idx}`}
    >
      <Grid item sm={4}>
        <Grid component='img' className={classes.postImage} src={image} />
      </Grid>
      <Grid
        sm={8}
        container
        className={classes.post}
        direction='column'
        justifyContent={link ? 'space-between' : 'flex-end'}
      >
        <Grid container>
          <Grid component='p' className={classes.postLabel}>
            {label}
          </Grid>
          <Grid component='p' className={classes.postTitle}>
            {title}
          </Grid>
        </Grid>
        <Grid container>
          <Grid component='p' className={classes.postDescription}>
            {description}
          </Grid>
          {link && (
            <Grid
              component='button'
              className={classes.postButton}
              onClick={handleOpenPost(id)}
            >
              {link.title} &#8212;
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

const StackModule = ({ themeSelected = 'whiteBackground', stackData, blockName }) => {
  const classes = useStyles(themeSelected)();
  const { title, description, label, posts } = stackData;
  return (
    <MainContainer themeSelected={themeSelected} blockName={blockName}>
      <Grid item xs={12}>
        <Title
          title={title}
          label={label}
          className={classes.title}
          themeSelected={themeSelected}
          offsetOption={offsetTitleOptionData.right}
        />
      </Grid>
      <Grid container justifyContent={'center'}>
        <Grid item xs={12} sm={8}>
          <Grid item component={'p'} className={classes.description}>
            {description}
          </Grid>
        </Grid>
      </Grid>
      <Grid container justifyContent='flex-end'>
        <Grid item xs={12} sm={11}>
          {posts.map((post, idx) => (
            <Post themeSelected={themeSelected} post={post} key={post.id} idx={idx + 1}/>
          ))}
        </Grid>
      </Grid>
      <Grid container className={classes.ctaButtonContainer}>
        <Button themeSelected={themeSelected} buttonName={stackData?.link?.title} url={stackData?.link?.url || stackData?.link?.uri}/>
      </Grid>
    </MainContainer>
  );
};

export default StackModule;
