import { createTheme } from '@material-ui/core';
import typographies from './typography';
import colors from './colors';

export const theme = createTheme({
  colors,
  typographies,
  typography: {
    fontWeight: 500,
    fontFamily: 'IBM Plex Sans',
    fontStyle: 'Normal',
    h1: {
      fontSize: 77,
      fontWeight: 500,
      lineHeight: '84px',
      letterSpacing: '-2.8px'
    },
    h2: {
      fontSize: 63,
      fontWeight: 500,
      lineHeight: '72px',
      letterSpacing: '-2.2px'
    },
    h3: {
      fontSize: 52,
      fontWeight: 500,
      lineHeight: '56px',
      letterSpacing: '-1.4px'
    },
    h4: {
      fontSize: 39,
      fontWeight: 500,
      lineHeight: '44px',
      letterSpacing: '-0.2px'
    },
    h5: {
      fontSize: 27,
      fontWeight: 500,
      lineHeight: '32px',
      letterSpacing: '-0.2px'
    },
    h6: {
      fontSize: 23,
      fontWeight: 500,
      lineHeight: '28px',
      letterSpacing: '-0.2px'
    },
    subtitle1: {
      fontSize: 19,
      fontWeight: 500,
      lineHeight: '24px',
      letterSpacing: '-0.4px'
    },
    body1: {
      fontSize: 15,
      lineHeight: '20px',
      letterSpacing: '-0.4px'
    },
    body2: {
      fontSize: 12,
      lineHeight: '16px',
      letterSpacing: '-0.4px'
    }
  }
});
