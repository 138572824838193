import {makeStyles} from "@material-ui/core";
import {headerTheme} from "./HeaderTheme";
import {themeType} from "../../Theme/ThemeSwitching";

const styles = (themeSelected) => makeStyles(theme => {
	const backgroundColor =  themeType.getThemeData(themeSelected).title.color === theme.colors.white ?  theme.colors.darkBlack : theme.colors.white
	return ({
		container: {
			fontFamily: 'IBM Plex Sans',
			fontSize: 14,
			// ...headerTheme.getThemeData(themeSelected),
			background: backgroundColor,
			position: 'fixed',
			top: -150,
			// transition: 'top 0.6',
			zIndex: 20,
			display: 'none',
			fill: themeType.getThemeData(themeSelected).title.color,
			transition: "all 0.2s ease-out",
			[theme.breakpoints.up(835)]: {
				display: 'block',
			},
		},
		showingContainer: {
			top: 0,
			transition: "top 1s ease-out",
			transitionDelay: "1.5s"
		},
		hideByScrollingDown: {
			top: -150,
			transition: "top 0.4s ease-out",
			transitionDelay: ".1s",
		},
		showByScrollingUp: {
			top: 0,
			transition: "top 0.4s ease-out",
			transitionDelay: ".1s"
		},
		containerScrolling: {
			background: 'transparent !important'
		},

		content: {
			padding: '20px 36px',
			width: '100%',
			maxWidth: 1752,
			margin: '0 auto',

		},
		navHidden: {
			padding: '16px 31px',
			transition: "all 0.2s ease-out",
		},
		itemContainer: {
			cursor: 'pointer',
		},
		label: {
			width: '100%',
			position: 'relative',
			...theme.typography.bodyText1,
			transition: "all 0.2s ease-out",
			// marginRight: 10
		},
		imgLogo: {
			width: '100%',
			transition: "all 0.2s ease-out",
			cursor: 'pointer'
		},
		squizeImage: {
			width: '50%',
			transition: "all 0.2s ease-out",
		},
		triangle: {
			width: 0,
			height: 0,
			marginLeft: 5,
			borderLeft: '6px solid transparent',
			borderRight: '6px solid transparent',
			borderTop: `12px solid ${headerTheme.getThemeData(themeSelected).borderColor}`,
		},
		globalContainer: {
			position: 'relative',
			cursor: 'pointer',
			display: 'flex',
			alignItems: 'right',
			justifyContent: 'flex-end',
			// fontSize: 14
		},
		globalIconImg: {
			// position: 'relative',
			marginRight: 4,
			// right: 23,
			// left: -25,
			// top: "50%",
			// transform: 'translateY(-50%)'
		},
		// contactUs: {
		// 	display: 'flex',
		// 	alignItems: 'right',
		// 	justifyContent: 'center',
		// 	cursor: 'pointer',
		// 	position: 'relative',
		// 	...headerTheme.getThemeData(themeSelected),
		// 	fontSize: '1vw',
		// 	letterSpacing: -0.2,
		// 	[theme.breakpoints.down('sm')]: {
		// 		fontSize: '1.2vw',
		// 	}
		// },
		phoneIconImg: {
			// position: 'absolute',
			// left: 0,
			// top: "50%",
			// transform: 'translateY(-50%)',
			// [theme.breakpoints.down('sm')]: {
			// 	left: -10,
			// }
		},

		contactContainer: {
			left: -60
		},
		subNavContainer: {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			maxWidth: 1752,
			margin: '0 auto',
			padding: '0 36px',
			// marginRight: -8,
		},

		linkItem: {
			height: 78,
			display: 'flex',
			alignItems: 'center',
		},
		linkText: {
			cursor: 'pointer',
			fontStyle: 'normal',
			fontWeight: 'normal',
			fontSize: '15px',
			lineHeight: '23px',
			color: theme.colors.red,
			margin: '0 8px',
			...themeType.getThemeData(themeSelected)?.title,
		},
	})
})


export default styles;
