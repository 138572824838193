import cmsTypes from "./cmsTypes";
import {blockTypesEnum} from "BlockConstructor/BlockTypes";

const initialState = {
	currentType: blockTypesEnum.MainPage
}


export const cmsReducer = (state = initialState, action) => {
	switch (action.type) {
		case cmsTypes.UPDATE_CMS_STORE: {
			return {...state, ...action.payload}
		}
		default: {
			return state;
		}
	}
}
