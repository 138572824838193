import {UPDATE_FILTERS_STORE_ACTION} from "./filters-type";
import API from 'API/index'

class FiltersActions {
	filtersUpdateStoreAction = (payload) => {
		return {
			type: UPDATE_FILTERS_STORE_ACTION,
			payload
		}
	}
	filterFetchInsightsAction = () => async (dispatch, store) => {
		try {
			const filters = await API.insights.getInsightsTypeFiler();
			const filterIndustry = await API.insights.getInsightsIndustryFilter();
			dispatch(this.filtersUpdateStoreAction({typeFilter: filters.data.data, industryFilter: filterIndustry.data.data}))
		}catch (e) {
			console.error(e)
		}
	};
	filterFetchAction = () => async (dispatch, store) => {
		try {
			const state = store().filters;
			let countries = state.countries, jobType = state.jobType, cities = state.cities, languages = state.languages;

			if (!state.countries) {
				const res = await API.filtersApi.getCountries();
				countries = res.data.data;
			}
			if (!state.jobType) {
				const res = await API.filtersApi.getJobTypes();
				jobType = res.data.data;
			}
			if (!state.cities) {
				const res = await API.filtersApi.getCities()
				cities = res.data.data;
			}
			if(!state.languages) {
				const res = await API.filtersApi.getLanguages()
				languages = res.data.data;
			}
			const dispatchCondition = !state.countries || !state.jobType || !state.cities;
			if (dispatchCondition) {
				dispatch(this.filtersUpdateStoreAction({cities, jobType, countries, languages}))
			}
		} catch (e) {
			console.error()
		}
	}
}

export default new FiltersActions()
