import {createSelector} from "reselect";

class FilterReselect {
	getFiltersStore = state => state.filters;
	getFiltersReselectedStore = createSelector(this.getFiltersStore, state => state);
	mapDataForSelectMenu = createSelector(
		this.getFiltersStore,
		(filters) => Object.keys(filters).reduce((acc, val) => ({
			...acc,
			[val]: filters[val].map(filterItem => ({id: filterItem.id, title: filterItem.attributes.name}))
		}), {})
	)

}

export default new FilterReselect();
