import { makeStyles } from '@material-ui/core';
import { themeType } from 'Theme/ThemeSwitching';

const styles = (themeSelected) =>
  makeStyles((theme) => ({
    cardContainer: {
      border: '1px solid #bcbcbc',
      borderRadius: 15,
      minHeight: 300,
      marginBottom: 12
    },
    cardImage: {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
      borderTopLeftRadius: 15,
      borderBottomLeftRadius: 15
    },
    newsContainer: {
      padding: 36
    },
    cardTitle: {
      fontSize: 19,
      lineHeight: '24px',
      ...themeType.getThemeData(themeSelected).title,
      fontWeight: 500,
      cursor: 'pointer'
    },
    cardLabel: {
      fontSize: 12,
      color: '#464646',
      margin: '0 0 4px 0'
    }
  }));

export default styles;
