import { makeStyles } from '@material-ui/core';

const styles = (themeSelected, props) =>
makeStyles((theme) => {
    return {
      title: {
        fontWeight: 500,
        fontSize: '4em',
        lineHeight: '72px',
        letterSpacing: -2.2,
        [theme.breakpoints.down('md')]: {
          fontSize: 35,
          lineHeight: '36px'
        },
      },
      contentContainer: {
        marginTop: 60,
        [theme.breakpoints.down('md')]: {
          marginTop: 26
        }
      },
      buttonContainer: {
        marginTop: 50,
        letterSpacing: -0.2,
        [theme.breakpoints.down('md')]: {
          marginTop: 36
        }
      },
      description: {
        fontWeight: props.isUSP ? 500 : 400,
        fontSize: props.isUSP ? 19 : 15,
        lineHeight: '24px',
        letterSpacing: -0.4,
        marginBottom: 76,
        [theme.breakpoints.down('md')]: {
          marginBottom: 36
        },
      },
      uspButton: {
        width: 211,
        height: 56,
        borderRadius: 100,
        cursor: 'pointer',
        alignItems: 'center',
        [theme.breakpoints.down('md')]: {
          width: 156,
          height: 48,
          fontSize: '15px !important'
        },
      },
      uspColumns: {
        display: 'flex',
        flexDirection: 'column',
        padding: props.isUSP ? '0 24px' : '0 0 24px 0',
        borderLeft: props.isUSP ? '1px solid #BEBEBE' : 'none',
        marginBottom: 72,
        [theme.breakpoints.down('md')]: {
          marginBottom: 24,
          padding: props.isUSP ? '0 0 0 24px' : '0'
        },
      },
      uspTitle: {
        fontWeight: 500,
        fontSize: 39,
        lineHeight: '44px',
        letterSpacing: -0.4
      },
      uspDescription: {
        marginTop: props.isUSP ? 90 : 16,
        fontSize: 15,
        lineHeight: '20px',
        [theme.breakpoints.down('md')]: {
          marginTop: 16
        },
      }
    };
  });

export default styles;
