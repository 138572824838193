import {Grid} from '@material-ui/core';
// import useStyles from './style';
import useCareers from './useCareers';
// import careersRoutes from './careersRoutes';
// import {Route, Switch} from 'react-router-dom';
// import {MainContainer} from "../../components";
import {CareersMainContent} from './careers-pages/careersMainContent/careersMainContent';
import {usePageMeta} from "../../hooks/usePageMeta";

export const Careers = ({
							pageData = []
						}) => {
	useCareers();
	const MetaTag = usePageMeta();
	return (
		<Grid item xs={12} >
			<MetaTag/>
			<CareersMainContent pageData={pageData}/>
		</Grid>
	)
};
