const headline = {
	fontWeight: 500,
	fontStyle: 'Normal',
}
const subHeadline = {
	...headline,
}
const bodyText = {
	fontWeight: 400,
	fontStyle: 'Normal',
}
const theme = {
	headline1: {
		...headline,
    fontSize: 77,
    lineHeight: '84px',
    letterSpacing: '-2.8px'
	},
	headline2: {
		...headline,
    fontSize: 63,
    lineHeight: '72px',
    letterSpacing: '-2.2px'
	},
	headline3: {
		...headline,
    fontSize: 52,
    lineHeight: '56px',
    letterSpacing: '-1.4px'
	},
	headline4: {
		...headline,
    fontSize: 47,
    lineHeight: '52px',
    letterSpacing: '-1px'
	},
	subHeadline1: {
		// ...subHeadline,
    fontSize: 39,
    lineHeight: '44px',
    letterSpacing: '-0.2px'
	},
	subHeadline2: {
		...subHeadline,
    fontSize: 27,
    lineHeight: '32px',
    letterSpacing: '-0.2px'
	},
	subHeadline3: {
		...subHeadline,
    fontSize: 23,
    lineHeight: '28px',
    letterSpacing: '-0.2px'
	},
	bodyText1: {
		...bodyText,
    fontSize: 19,
    lineHeight: '24px',
    letterSpacing: '-0.4px'
	},
	bodyText2: {
		...bodyText,
    fontSize: 15,
    lineHeight: '20px',
    letterSpacing: '-0.4px'
	},
	bodyText3: {
		...bodyText,
    fontSize: 12,
    lineHeight: '16px',
    letterSpacing: '-0.4px'
	}
}
export default theme;
