import {createContext, useState} from 'react';
import {makeStyles, Grid, Modal} from '@material-ui/core';

import {MobileFilterScreen} from 'components';
import CookieConsentModal from 'components/CookieConsent/CookieConsentModal';

import stylesEnumObject from '../Theme/ThemeSwitching/theneNames';

export const ModalContext = createContext(null);

const useStyles = makeStyles((theme) => ({
	container: {
		position: 'relative',
		width: '100%',
		height: '100%',
		overflow: 'hidden'
	},
	modalContainer: {
		position: 'absolute',
		boxSizing: 'border-box',
		left: 0,
		top: 0,
		bottom: 0,
		right: 0
	}
}));

class ModalNamesEnum {
	careersFilterModal = 'careersFilterModal';
	cookieConsentModal = 'cookieConsentModal';
}

export const modalNamesEnum = new ModalNamesEnum();

export const ModalContextProvider = ({children}) => {
	const classes = useStyles();

	const [modalData, setModalData] = useState({
		[modalNamesEnum.careersFilterModal]: {
			isShown: false,
			themeSelected: stylesEnumObject.blackBackground
		},
		[modalNamesEnum.cookieConsentModal]: {
			isShown: false,
			themeSelected: stylesEnumObject.blackBackground
		}
	});

	const handleSetModal = ({...rest}) => () =>
		setModalData((prev) => ({
			...prev,
			[rest.modalName]: {...prev[rest.modalName], ...rest, isShown: true}
		}));

	const handleCloseModal = (name) => () =>
		setModalData((prev) => ({
			...prev,
			[name]: {...prev[name], isShown: false}
		}));
	return (
		<ModalContext.Provider value={{handleSetModal, handleCloseModal}}>
			<Grid container className={classes.container}>
				<Grid item xs={12} className={classes.modalContainer}>
					<Modal
						open={modalData[modalNamesEnum.careersFilterModal].isShown}
						onClose={handleCloseModal(modalNamesEnum.careersFilterModal)}
						aria-labelledby="modal-modal-title"
						aria-describedby="modal-modal-description"
					>
						<div>
							<MobileFilterScreen data={modalData[modalNamesEnum.careersFilterModal]}/>
						</div>
					</Modal>
					<CookieConsentModal modal={modalData[modalNamesEnum.cookieConsentModal]}/>
				</Grid>
				{children}
			</Grid>
		</ModalContext.Provider>
	);
};
