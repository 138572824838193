import React from 'react';
import { Grid } from '@material-ui/core';

import { ReadMoreButton } from 'components';

import useStyles from './style';

const PressItem = ({ themeSelected, item }) => {
  const classes = useStyles(themeSelected)();

  const { title = '', label = '', description = '' } = item;

  return (
    <Grid container justifyContent='flex-start' direction='column' className={classes.pressItem}>
      <Grid item xs={12} component='p' className={classes.label}>
        {label}
      </Grid>
      <Grid item xs={12} component='p' className={classes.title}>
        {title}
      </Grid>
      <Grid item xs={12} component='p' className={classes.description}>
        {description}
      </Grid>
      <ReadMoreButton themeSelected={themeSelected} title="Read More" />
    </Grid>
  );
};

export default PressItem;
