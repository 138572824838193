import axios from "axios";
import {
	FOOTER_SOCIAL_MEDIA_ICONS,
	FOOTER_URL,
	HEADER_ADDITIONAL_DATA_URL,
	HEADER_MAIN_CONTENT_URL,
	NOT_FOUND_PAGE_BLOCK
} from "./constants";

import constantsClass from './ConstantsClass';

class GeneralData {
	async getFooterData(params) {
		try {
			return axios.get(FOOTER_URL, params)
		} catch (e) {
			console.error(e)
		}
	};

	async getHeaderMainData(params) {
		try {
			return axios.get(HEADER_MAIN_CONTENT_URL, params)
		} catch (e) {
			console.error(e)
		}
	};

	async getHeaderAdditionalData(params) {
		try {
			return axios.get(HEADER_ADDITIONAL_DATA_URL, params)
		} catch (e) {
			console.error(e)
		}
	};

	async getFooterSocialMediaIcons(params) {
		try {
			return axios.get(FOOTER_SOCIAL_MEDIA_ICONS, params)
		} catch (e) {
			console.error(e)
		}
	};

	async getNotFoundPageData(params) {
		try {
			return axios.get(NOT_FOUND_PAGE_BLOCK, params)
		} catch (e) {
			console.error(e)
		}
	}


	async postSubRequestData(params = '/about_us', queries = '') {
		try {
			const res = await fetch(
				constantsClass.SUBREQUEST,
				{
					method: 'POST',
					body: JSON.stringify([{
						"requestId": "router",
						"uri": `/router/translate-path?path=${params}`,
						"action": "view"
					},
						{
							"action": "view",
							"requestId": "iddd",
							"uri": `/api/v1/node/{{router.body@$.entity.bundle}}/{{router.body@$.entity.uuid}}${queries}`,
							"waitFor": "router"
						}
					])
				}
			);
			return res.json();
		} catch (e) {
			console.error(e)
		}
	}

	async getDynamicDataForPageType(url = '') {
		try {
			return axios.get(url)
		} catch (e) {
			console.error(e)
		}
	}
}

export default GeneralData;
