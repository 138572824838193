import React from 'react';

import { makeStyles } from '@material-ui/core';
import Masonry from 'react-masonry-css';

class AlignDirection {
  middle = 'center';
  top = 'flex-start';
};

export const alignDirection = new AlignDirection();

const breakpointColumnsObj = {
  default: 3,
  841: 2,
  500: 1
};

const useStyles = makeStyles((theme) => ({
  my_masonry_grid: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: -12,
    width: 'auto',
    marginTop: 120,
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      marginTop: 80
    }
  },
  my_masonry_grid_column: {
    paddingLeft: 12,
    backgroundClip: ' padding-box'
  }
}));

const AlignContainer = ({
  className,
  Component,
  contentData,
  contentOffset = 'middle'
}) => {
  const classes = useStyles();
  return (
    <Masonry
      breakpointCols={breakpointColumnsObj}
      className={`${classes.my_masonry_grid} ${className}`}
      style={{ alignItems: alignDirection[contentOffset] }}
      columnClassName={classes.my_masonry_grid_column}
    >
      {contentData.map((item) => (
        <Component key={item.id} data={item} />
      ))}
    </Masonry>
  );
};

export default AlignContainer;
