import {Grid} from "@material-ui/core";
import useStyles from './style'
import {buttonTypeEnum} from "Theme/ThemeSwitching";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

const SubmitIcon = ({themeSelected, className, submit}) => {
	const classes = useStyles(themeSelected, buttonTypeEnum.primary)();
	return <Grid container className={`${classes.container} ${className}`} onClick={submit('jobOpeningSearch')}>
		Go
		<ArrowForwardIcon/>
	</Grid>
}

export default SubmitIcon;
