import { Grid } from '@material-ui/core';

import { MainContainer, Title } from 'components';
import { offsetTitleOptionData } from 'components/Title/Title';
import SingleBlockImage from 'assets/images/SingleArticleBlockImage.png';

import useStyles from './style';

const SingleArticleModule = ({ title, subtitle, themeSelected }) => {
  const classes = useStyles(themeSelected)();

  return (
    <MainContainer themeSelected={themeSelected}>
      <Grid item xs={12} sm={7} component={'img'} src={SingleBlockImage} />
      <Grid item xs={12} sm={5}>
        <Grid
          container
          className={classes.articleContainer}
          alignContent={'space-between'}
        >
          <Title
            offsetOption={offsetTitleOptionData.left}
            title={title}
            label={'label'}
            themeSelected={themeSelected}
            className={classes.title}
          />

          <Grid container>
            <Grid item sm={12} md={9} className={classes.subtitle}>
              {subtitle}
            </Grid>
            <Grid item md={12} className={classes.readMore}>
              Read more &#8212;
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </MainContainer>
  );
};

export default SingleArticleModule;
