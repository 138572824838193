import {useEffect} from "react";

function useOutsideToggle(ref, cb) {
	useEffect(() => {
		function handleClickOutside(event) {
			if (ref.current && !ref.current.contains(event.target)) {
				cb()
			}
		}

		document.addEventListener("mouseup", handleClickOutside);
		return () => {
			document.removeEventListener("mouseup", handleClickOutside);
		};
		// eslint-disable-next-line
	}, [ref.current]);
}

export default useOutsideToggle;
