import stylesEnumObject from "Theme/ThemeSwitching/theneNames";
import {Grid} from "@material-ui/core";
import animation from './animation.module.css';

///////////////////////////////
// import FirstImage from 'assets/MobileAnimation/1.svg';
// import SecondImage from 'assets/MobileAnimation/2.svg';
// import ThirdImage from 'assets/MobileAnimation/3.svg';
// import ForthImage from 'assets/MobileAnimation/4.svg';
// import FifthImage from 'assets/MobileAnimation/5.svg';
// import SixthImage from 'assets/MobileAnimation/3.svg';
// import Last from 'assets/MobileAnimation/last.svg';


const MobileHeroAnimation = ({themeSelected = stylesEnumObject.blackBackground, module = []}) => {

	return (
		// <MainContainer themeSelected={themeSelected}>
		<div style={{height: 500}}>
				<Grid container className={animation.container}>
					<Grid item xs={12} className={animation.firstLineContainer}>
						<div className={animation.firstLineContainerBehind}>BEHIND</div>
						<div className={animation.firstLineContainerBehindCover}/>
						<img className={animation.firstLineImage} src={module[0]} alt={'#'}/>
						<div className={animation.firstLineImageCover}/>
					</Grid>

					<Grid item xs={12} className={animation.firstLineContainer}>
						<div className={animation.secondLineContainerThe}>THE</div>
						<div className={animation.secondLineContainerTheCover}/>
						<img className={animation.secondLineImage} src={module[1]} alt={'#'}/>
						<div className={animation.secondLineImageTheCover}/>
					</Grid>

					<Grid item xs={12} className={animation.firstLineContainer}>
						<div className={animation.thirdLineContainerBrilliance}>BRILLIANCE</div>
						<div className={animation.thirdLineContainerTheCover}/>
					</Grid>

					<Grid item xs={12} className={animation.firstLineContainer}>
						<img className={animation.fourthLineImage} src={module[2]} alt={'#'}/>
						<div className={animation.fourthLineImageCover}/>
						<div className={animation.fourthLineContainerOf}>OF</div>
					</Grid>

					<Grid item xs={12} className={animation.middleSpace}/>

					<Grid item xs={12} className={animation.firstLineContainer}>
						<div className={animation.fifthLineContainerTheBest}>THE BEST</div>
						<div className={animation.fifthLineTheCoverage}/>
						<div className={animation.fifthLineBestCoverage}/>

						<div className={animation.fifthLineContainerYour}>YOUR</div>

						<div className={animation.fifthLineContainerNext}>NEXT</div>
						<div className={animation.fifthLineNextCoverage}/>
					</Grid>

					<Grid item xs={12} className={animation.firstLineContainer}>
						<div className={animation.sixthLineContainerLoved}>LOVED</div>
						<div className={animation.sixthLineContainerLovedCover}/>

						<div className={animation.sixthLineContainerSuccess}>SUCCESS</div>

						<div className={animation.sixthLineContainerGen}>GEN</div>
						<img className={animation.sixthLineImage} src={module[3]} alt={'#'}/>
						<div className={animation.sixthLineImageCover}/>
					</Grid>

					<Grid item xs={12} className={animation.firstLineContainer}>
						<div className={animation.seventhLineBrandsContainer}>BRANDS</div>
						<div className={animation.seventhLineBrandsContainerCover}/>
						<img className={animation.seventhLineFifthImage} src={module[3]} alt={'#'}/>
						<div className={animation.seventhLineFifthImageCover}/>

						<div className={animation.seventhLineStoriesContainer}>STORIES</div>
						<img className={animation.seventhLineSixthImage} src={module[4]} alt={'#'}/>
						<div className={animation.seventhLineSixthImageCover}/>

						<div className={animation.seventhLineCxContainer}>CX</div>
						<img className={animation.seventhLineLastImage} src={module[5]} alt={"#"}/>
					</Grid>

				</Grid>
		</div>
		// </MainContainer>
	)
};

export default MobileHeroAnimation;
