import {useWindowSize} from "hooks/useWindowSize";
import {useSelector} from "react-redux";
import {getCareersPlaceholderName, getToggleIfSearchFilteringSelected} from "Redux/Modules/Inputs/reselect";
import {useActionCreator} from "Redux/useActionCreator";
import {useContext, useEffect} from "react";
import {ModalContext} from "Context/ModalContext";
import {useHistory} from "react-router-dom";
// import {initialState} from "../../Redux/Modules/Inputs/inputs-reducer";


export const filtersData = [
	{name: 'countries', placeholder: 'Countries', status: false},
	// {name: 'cities', placeholder: 'Cities', status: false},
	{name: 'jobType', placeholder: 'Category', status: false},
	{name: 'languages', placeholder: 'Languages', status: false},
];

export const useCareersInputArea = ({url = []}) => {
	const {width} = useWindowSize();
	const history = useHistory();
	const placeholderName = useSelector(getCareersPlaceholderName);
	const {resetCareersAction, findJobsAction} = useActionCreator();

	const {handleSetModal} = useContext(ModalContext);
	const handleSubmit = (key) => async e => {
		e.preventDefault();
		await findJobsAction({inputValue: key})
		// history.push(paths.jobOpening)
		if (url?.length > 1) {
			history.push(url[1]);
		}
	}


	const handleReset = () => {
		resetCareersAction();
	}

	const {
		getAllJobsCareersAction,
		filterFetchAction,
		updateCareersStateAction,
		pressShowMoreCareersAction,
	} = useActionCreator();
	const {
		jobsList,
		foundJobs,
		wasSearching,
		meta,
		pageOffset,
		metaSearch,
		foundListOffset
	} = useSelector((state) => state.careers);
	const ifFilterIsSelected = useSelector(getToggleIfSearchFilteringSelected);
	const {countries, cities, jobType, languages} = useSelector(state => state.inputs);

	const handleSelectCard = (card) => () => {
		updateCareersStateAction({certainJobData: {}});
		window.dataLayer = window.dataLayer || [];
		history.push(card?.attributes?.path?.alias);
	};

	// const handleSubmit = (key) => (e) => {
	// 	e && e.preventDefault();
	// 	findJobsAction({inputValue: key});
	// };

	useEffect(() => {
		filterFetchAction();
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		if (!wasSearching && !ifFilterIsSelected) {
			if (!pageOffset) {
				getAllJobsCareersAction();
			} else {
				getAllJobsCareersAction(true);
			}
		}
		// eslint-disable-next-line
	}, [wasSearching, pageOffset, ifFilterIsSelected]);

	useEffect(() => {
		// if (countries || cities || jobType || languages || ifFilterIsSelected) {
			if (!foundListOffset) {
				findJobsAction({inputValue: 'jobOpeningSearch'});
			} else {
				findJobsAction({inputValue: 'jobOpeningSearch'}, true);
			}
		// }
		// eslint-disable-next-line
	}, [foundListOffset, countries, cities, jobType, languages]);

	useEffect(() => {
		updateCareersStateAction({pageOffset: 0, foundListOffset: 0});
		// eslint-disable-next-line
	}, [wasSearching]);






	return {
		isMobile: width < 960,
		placeholderName,
		resetCareersAction: handleReset,
		findJobsAction,
		handleSetModal,
		handleSubmit,
		filtersData,

		handleSelectCard,
		jobsList,
		foundJobs,
		wasSearching,
		meta,
		pressShowMoreCareersAction,
		metaSearch,
	}
}
