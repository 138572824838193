import React from 'react';

import { useSelector } from 'react-redux';

import { Grid } from '@material-ui/core';
import generalReselect from 'Redux/Modules/General/reselect';
import iconsInterface from 'components/Footer/IconsInterface';
import useStyles from './style';

const CareersJobCard = ({ certainJobData }) => {
  const classes = useStyles();

  const { socialMediaIcons } = useSelector(generalReselect.getGeneralReselectedStore);

  const {
    title = '',
    description = '',
    country = '',
    city = '',
    jobType = '',
    link = ''
  } = certainJobData;
  const handleApplyForJob = () => {
    window.open(link, '_blank').focus();

    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'job_application',
      ec: 'job_application',
      ea: 'start_application',
      el: title,
      ev: 0,
      cd2_location: `${country}, ${city}`,
      cd3_job_type: jobType
    });
  };

  return (
    <>
      <Grid item xs={12} className={classes.container}>
        <Grid container className={classes.titleContainer}>
          <Grid item xs={12} md={9} className={classes.title}>
            {title}
          </Grid>

          <Grid item xs={12} md={9} className={classes.jobInfo}>
            <Grid component={'p'} item xs={12} className={classes.jobOptions}>
              Location: {`${country}, ${city}`}
            </Grid>
            <Grid component={'p'} item xs={12} className={classes.jobOptions}>
              Job type: <span>{jobType}</span>
            </Grid>
            <Grid component={'p'} item xs={12} className={classes.jobOptions}>
              Field: <span>Finance</span>
            </Grid>
          </Grid>

          <Grid item md={9} xs={12}>
            <Grid component={'p'} className={classes.parahraphTitle}>
              Who you are
            </Grid>
            <div dangerouslySetInnerHTML={{ __html: description }} />
            <Grid
              item
              component={'button'}
              className={classes.applyButton}
              onClick={handleApplyForJob}
            >
              Apply now
            </Grid>
          </Grid>
        </Grid>
        {/*<Grid container direction='column' justifyContent='flex-start' xs={12} md={3}>*/}
        {/*  {Array.from([0, 1]).map((person) => (*/}
        {/*    <Grid item className={classes.person} key={person}>*/}
        {/*      <Grid*/}
        {/*        component='img'*/}
        {/*        src='https://img.icons8.com/pastel-glyph/2x/person-male.png'*/}
        {/*        alt='person1'*/}
        {/*      />*/}
        {/*      <Grid component='p' className={classes.personTitle}>*/}
        {/*        Title*/}
        {/*      </Grid>*/}
        {/*      <Grid component='p' className={classes.personName}>*/}
        {/*        Name Person*/}
        {/*      </Grid>*/}
        {/*    </Grid>*/}
        {/*  ))}*/}
        {/*  <Grid item component={'button'} className={classes.applyPersonButton}>*/}
        {/*    Apply now*/}
        {/*  </Grid>*/}
        {/*</Grid>*/}
      </Grid>
      <Grid container justifyContent='center'>
        <Grid item xs={12} md={9} component="p" className={classes.iconsHeader}>Share This!</Grid>
        <Grid item xs={12} md={9} className={classes.iconContainer}>
          {Array.isArray(socialMediaIcons) &&
            !!socialMediaIcons.length &&
            socialMediaIcons?.map((icon) =>
              iconsInterface.getIconComponent(icon.name)({
                classes,
                icon
              })
            )}
        </Grid>
      </Grid>
    </>
  );
};

export default CareersJobCard;
