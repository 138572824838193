import {makeStyles} from '@material-ui/core';
import themeTypography from 'Theme/typography';

const styles = (themeSelected, props) =>
	makeStyles((theme) => ({
		container: {
			width: '100%',
			position: 'relative',
			background: props.background
		},
		image: {
			height: '100%',
			width: '100%',
			[theme.breakpoints.down('xs')]: {
				minHeight: 800,
        objectPosition: 'top',
        objectFit: 'none'
			},
		},
		mainContainer: {
			position: 'absolute',
			maxWidth: 1680,
      padding: '100px 36px',
			width: '100%',
			left: '50%',
			transform: 'translateX(-50%)',
			height: '100%',
			top: 0,
			display: 'flex',
			justifyContent: 'space-between',
			flexDirection: 'column',
      [theme.breakpoints.down('md')]: {
        padding: '0 36px',
        justifyContent: 'space-evenly',
			},
		},
		textContainer: {
			maxWidth: 900,
			[theme.breakpoints.down('md')]: {
				maxWidth: 650,
			},
			[theme.breakpoints.down('xs')]: {
				maxWidth: 350,
			},
		},
		numbersContainer: {
			maxWidth: 1060,
			[theme.breakpoints.down('md')]: {
				maxWidth: 750,
			},
			[theme.breakpoints.down('xs')]: {
				maxWidth: 450,
			},
		},
		title: {
			color: props.color,
			...themeTypography.headline1,
			whiteSpace: 'pre-line',
			marginBottom: 40,
			[theme.breakpoints.down('md')]: {
				top: 10,
				...themeTypography.headline3,
			},
			[theme.breakpoints.down('xs')]: {
				...themeTypography.subHeadline1,
				marginBottom: 30
			},
		},
		label: {
			color: props.labelColor,
			...themeTypography.bodyText1,
			marginBottom: 10,
      [theme.breakpoints.down('sm')]: {
        ...themeTypography.bodyText3,
        marginBottom: 0,
        marginTop: 10,
			},
		},
		description: {
			color: props.color,
			...themeTypography.bodyText1,
			whiteSpace: 'pre-line',
			[theme.breakpoints.down('md')]: {
				...themeTypography.bodyText1,
			},
			[theme.breakpoints.down('xs')]: {
				...themeTypography.bodyText2,
			},
		},
		counter: {
			color: props.color,
			...themeTypography.headline1,
			[theme.breakpoints.down('md')]: {
				...themeTypography.headline3,
			},
			[theme.breakpoints.down('xs')]: {
				...themeTypography.headline4
			},
		},

		itemWrapper: {
			[theme.breakpoints.down('xs')]: {
				width: '100%',
				// marginTop: 40,
			}
		}
	}));

export default styles;
