import {useActionCreator} from "Redux/useActionCreator";
import React, {useContext, useEffect, useState} from "react";
import {ModalContext} from "Context/ModalContext";
import {useHistory} from "react-router-dom";
import {useSelector} from "react-redux";
import {getInputReselectedStore} from "Redux/Modules/Inputs/reselect";
import filtersReselected from "Redux/Modules/Filters/reselect";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import {buttonTypeEnum} from "../../Theme/ThemeSwitching";
import ReplayIcon from "@mui/icons-material/Replay";

const buttonsData = [
	{
		title: 'Go', icon: <ArrowForwardIcon/>, buttonType: buttonTypeEnum.primary, action: ({
																								 findAction,
																								 history,
																								 key,
																								 handleCloseModal = () => null,
																								 submitRedirectUrl = ''
																							 }) => async () => {
			await findAction({inputValue: key})
			handleCloseModal()
			submitRedirectUrl && history.push(submitRedirectUrl)
		}
	},
	{
		title: 'Reset',
		icon: <ReplayIcon/>,
		buttonType: buttonTypeEnum.secondary,
		action: ({resetAction}) => () => resetAction()
	},
]

export const useMobileFilterScreen = ({filtersData = []}) => {
	const {updateInputsStoreAction} = useActionCreator();
	const {handleCloseModal} = useContext(ModalContext)
	const history = useHistory();
	const inputs = useSelector(getInputReselectedStore);
	const filters = useSelector(filtersReselected.mapDataForSelectMenu);

	const [isListShown, setIsListShown] = useState([...filtersData]);

	useEffect(() => {
		setIsListShown(filtersData)
		// eslint-disable-next-line
	}, [filtersData.length])


	const handleToggleList = (name) => () => setIsListShown(prev => prev.map(i => i.name === name ? {
		...i,
		status: !i.status
	} : {...i, status: false}));

	const listItemAction = (name) => (item) => {
		updateInputsStoreAction({[name]: item.title})
		handleToggleList(name)()
	}

	const handleClickIcon = (name) => () => {
		updateInputsStoreAction({[name]: ''})
	}
	return {
		handleClickIcon,
		listItemAction,
		isListShown,
		filters,
		inputs,
		history,
		handleCloseModal,
		filtersData,
		buttonsData,
		handleToggleList
	}
}
