import { UPDATE_CONTACT_US_STORE } from './contactUs-type';

const initialState = {
  countries: [],
  offices: []
};

export const contactUsReducer = (state = { ...initialState }, action) => {
  switch (action.type) {
    case UPDATE_CONTACT_US_STORE: {
      return { ...state, ...action.payload };
    }
    default: {
      return state;
    }
  }
};
