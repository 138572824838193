import React, { useEffect, useState } from 'react';
import { Grid, Select, MenuItem } from '@material-ui/core';

import { Title } from 'components';
import stylesEnum from 'Theme/ThemeSwitching/theneNames';

import useStyles from './style';
import './styles.css';

const contactData = {
  title: 'We’d love to hear from you!',
  label: 'We are transcom',
  description:
    'We are the voice of our clients. Equipped with intelligent technology, we are a global network of local specialists, ambitious about making things work. We continuously evolve to be one step ahead',
  contactReasons: ['Recruitment', 'Sales', 'Media inquires', 'General inquires']
};

const ContactUsForm = ({ data }) => {
  const classes = useStyles(stylesEnum.whiteBackground)();
  const { title, label, description, contactReasons } = contactData;

  const { title: sectionTitle, forms } = data;
  const form = forms[0].split('::');
  const [reason, setReason] = useState('');

  const handleLoadForm = () => {
    if (window.hbspt) {
      window.hbspt.forms.create({
        portalId: form[0],
        formId: form[1],
        target: '#hubspotForm'
      });
    }
  };

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://js.hsforms.net/forms/v2.js';
    document.body.appendChild(script);

    script.addEventListener('load', handleLoadForm);

    return () => {
      script.removeEventListener('load', handleLoadForm);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reason]);

  return (
    <>
      <Grid className={classes.container}>
        <Title
          title={title}
          label={label}
          themeSelected={stylesEnum.whiteBackground}
          className={classes.title}
        />
        <Grid container justifyContent='center'>
          <Grid item xs={12} md={8}>
            <Grid component='p' className={classes.description}>
              {description}
            </Grid>
            <Grid component='p' className={classes.selectHeader}>
              {sectionTitle}
            </Grid>
            <Select
              value={reason}
              displayEmpty
              onChange={(e) => setReason(e.target.value)}
              variant='outlined'
              className={classes.selectRoot}
              classes={{ select: classes.selectLabel }}
            >
              <MenuItem disabled value=''>
                <em>Select</em>
              </MenuItem>
              {contactReasons.map((reas) => (
                <MenuItem key={reas} value={reas}>
                  {reas}
                </MenuItem>
              ))}
            </Select>
            {reason && <div id='hubspotForm'></div>}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default ContactUsForm;
