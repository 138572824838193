import { makeStyles } from '@material-ui/core';

const styles = (themeSelected, props) =>
  makeStyles((theme) => {
    return {
      container: {
        '& img': {
          width: '100%'
        }
      },
      title: {
        fontSize: 67,
        lineHeight: '74px',
        fontWeight: 500,
        marginBottom: 54
      },
      callToAction: {
        width: 220,
        background: '#173EDE',
        color: '#fff',
        borderRadius: 100,
        height: 48,
        margin: '26px 0 86px 0',
        border: 'none',
        cursor: 'pointer',
        fontSize: 16
      },
      iconContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        // justifyContent: 'space-between',
        marginLeft: -24,
        marginTop: 24,
        marginBottom: 24
      },
    };
  });

export default styles;
