import {makeStyles} from "@material-ui/core";

export default makeStyles((theme) => ({
	wrapper: {
		// height: '100vh',
		position: 'relative',
	},
	// container: {
	// 	maxWidth: 1368,
	// 	margin: '0 36px',
	// }
}))
