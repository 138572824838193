import {makeStyles} from "@material-ui/core";
import {themeType} from "Theme/ThemeSwitching";

const styles = (themeSelected) => makeStyles(theme => ({
	container: {
		position: 'absolute',
		padding: '16px !important',
		top: 0,
		// bottom: 0,
		height: '100%',
		zIndex: 1000,
		...themeType.getThemeData(themeSelected).background,
		overflow: 'hidden',

	},
	contentContainer: {
		...themeType.getThemeData(themeSelected).title,
		// position: 'relative',
		// top: 300,
	},
	menuOpened: {
		right: '0 !important',
		transition: 'all .5s ease-in-out !important',
	},
	menuClosed: {
		right: '100% !important',
		transition: 'all .5s ease-in-out !important',
	},
	titleContainer: {
		fontSize: 17,
		fontWeight: 500,
		letterSpacing: -0.2,
		padding: '10px 0 18px 0',
		borderBottom: `1px solid ${theme.colors.darkGrey}`
	},
	closeIcon: {
		textAlign: 'right'
	},
	inputContainer: {
		paddingTop: 24,
		borderBottom: `1px solid ${theme.colors.darkGrey}`,
	},
	filtersTitle: {
		paddingTop: 24,
		paddingBottom: 12,
		...themeType.getThemeData(themeSelected)?.subtitle,
	},

	filterContainer: {
		borderBottom: `1px solid ${theme.colors.darkGrey}`,
		paddingBottom: 24,
	},
	submitButtonsContainer: {
		marginTop: 36
	}
}))


export default styles;
