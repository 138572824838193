import stylesEnumObject from "../Theme/ThemeSwitching/theneNames";

const parseHeaderData = (data) => {
	let parentData = data.filter(i => !i.attributes.parent).map(field => ({
		title: field.attributes.title,
		id: field.id,
		theme: stylesEnumObject.blackBackground,
		path: field.attributes.url,
	}))

	let firstDropdownLevel = data.reduce((acc, value) => parentData.some(j => j.id === value.attributes.parent)
		? {
			...acc, [value.attributes.parent]: acc[value.attributes.parent] ? [
				...acc[value.attributes.parent], {
					anchor: value.attributes?.options?.attributes?.anchor || '',
					title: value.attributes.title,
					id: value.id,
					path: value.attributes.url || '',
					theme: stylesEnumObject.blackBackground,
					link: data.reduce((accumulator, val) => val.attributes.parent === value.id ? [...accumulator, {
						anchor: val.attributes?.options?.attributes?.anchor || '',
						title: val.attributes.title,
						id: val.id,
						path: val.attributes.url || '',
						theme: stylesEnumObject.whiteBackground,
					}] : accumulator, [])
				}
			] : [{
				anchor: value.attributes?.options?.attributes?.anchor || '',
				title: value.attributes.title,
				id: value.id,
				path: value.attributes.url || '',
				theme: stylesEnumObject.blackBackground,
				link: data.reduce((accumulator, val) => val.attributes.parent === value.id ? [...accumulator, {
					anchor: val.attributes?.options?.attributes?.anchor || '',
					title: val.attributes.title,
					id: val.id,
					path: val.attributes.url || '',
					theme: stylesEnumObject.whiteBackground,
				}] : accumulator, [])
			}]
		}
		: acc, {})
	const parsedHeader = parentData.map(i => ({...i, dropdown: firstDropdownLevel[i.id]}))
	return parsedHeader || []
}

export default parseHeaderData;
