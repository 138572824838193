import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useActionCreator } from 'Redux/useActionCreator';

export const useInsights = () => {
  const {
    // getInsightsPageDataAction,
    filterFetchInsightsAction
  } = useActionCreator();
  const { pageData, newsList, newsDetail } = useSelector((state) => state.insights);

  useEffect(() => {
    // getInsightsPageDataAction();
    filterFetchInsightsAction();
    // eslint-disable-next-line
  }, []);

  return {pageData, newsList, newsDetail};
};
