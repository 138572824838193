import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import {BrowserRouter} from "react-router-dom";
import {theme} from "./Theme";
import {ThemeProvider} from '@material-ui/core/styles';
import {ContextProvider} from "./Context/Context";
import {Provider} from "react-redux";
import storeData from "./Redux";
import {PersistGate} from 'redux-persist/integration/react'
import {ModalContextProvider} from "./Context/ModalContext";
import './index.css'

const app = <BrowserRouter>
	<Provider store={storeData.store}>
		<ContextProvider>
			<PersistGate loading={null} persistor={storeData.persistor}>
				<ThemeProvider theme={theme}>
					<ModalContextProvider>
						<App/>
					</ModalContextProvider>
				</ThemeProvider>
			</PersistGate>
		</ContextProvider>
	</Provider>
</BrowserRouter>


ReactDOM.render(
	app,
	document.getElementById('root')
);
