import React, { useState } from 'react';
import { Grid, Select, MenuItem, Typography } from '@material-ui/core';
import { useSelector } from 'react-redux';

import { useActionCreator } from 'Redux/useActionCreator';
import stylesEnum from 'Theme/ThemeSwitching/theneNames';

import useStyles from './style';

const OfficeCard = ({ classes, title, description }) => (
  <Grid item xs={12} sm={3}>
    <Grid component='p' className={classes.officeTitle}>
      {title}
    </Grid>
    <Grid
      component='p'
      className={classes.officeText}
      dangerouslySetInnerHTML={{ __html: description }}
    />
  </Grid>
);

const FindOfficeModule = ({ data }) => {
  const classes = useStyles(stylesEnum.whiteBackground)();
  const { title, description } = data;

  const [country, setCountry] = useState('');
  const { countries, offices } = useSelector((state) => state.contactUs);
  const { getContactUsOffices } = useActionCreator();

  const handleChangeCountry = ({ target }) => {
    getContactUsOffices(target.value);
    setCountry(target.value);
  };
  return (
    <Grid className={classes.container} container justifyContent='center'>
      <Grid item xs={12} md={8}>
        <Grid container>
          <Grid item xs={12} sm={12}>
            <Typography variant='h2'>{title}</Typography>
            <Grid component='p' className={classes.officeTitle}>
              {description}
            </Grid>
            <Select
              value={country}
              displayEmpty
              variant='outlined'
              className={classes.selectOfice}
              classes={{ select: classes.selectOfficeRoot }}
              onChange={handleChangeCountry}
            >
              <MenuItem disabled value=''>
                <em>Select a Country</em>
              </MenuItem>
              {countries &&
                !!countries.length &&
                countries.map(({ id, title }) => (
                  <MenuItem key={id} value={id}>
                    {title}
                  </MenuItem>
                ))}
            </Select>
          </Grid>
        </Grid>
        {offices && !!offices.length && (
          <Grid container spacing={4}>
            {offices.map((office) => (
              <OfficeCard
                key={office.id}
                classes={classes}
                title={office.city}
                description={office.description}
              />
            ))}
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default FindOfficeModule;
