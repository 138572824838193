import { makeStyles } from '@material-ui/core';
import { themeType } from 'Theme/ThemeSwitching';

const styles = (themeSelected) =>
  makeStyles((theme) => ({
    pressItem: {
      width: '100%',
      marginBottom: 24,
      padding: '36px 28px',
      border: '1px solid #BEBEBE',
      borderRadius: 15
    },
    label: {
      fontSize: 12,
      color: '#464646',
      marginBottom: 4,
      ...themeType.getThemeData(themeSelected).label
    },
    title: {
      fontSize: 19,
      ...themeType.getThemeData(themeSelected).title,
      marginBottom: 24
    },
    description: {
      fontSize: 15,
      marginBottom: 20,
      maxWidth: 525,
      color: '#464646',
      ...themeType.getThemeData(themeSelected).description
    },
    button: {
      width: '100%',
      textAlign: 'left',
      padding: 0,
      fontSize: 15,
      lineHeight: '20px',
      fontWeight: 'bold',
      background: 'transparent',
      border: 'none',
      cursor: 'pointer',
      ...themeType[themeSelected].subtitle,
      color: '#173EDE',
    }
  }));

export default styles;
