import { Grid, Typography } from '@material-ui/core';
import Carousel from 'react-material-ui-carousel';
import FormatQuoteIcon from 'assets/images/comma.svg'

import { MainContainer } from 'components';

import useStyles from './style';
import { API_URL } from 'API/constants';

function Item({ item, classes }) {
  const ItemDescription = () => (
    <>
      <Typography variant="subtitle1" className={classes.text}>
        {item.author || ''}
      </Typography>{' '}
      <Typography variant="body1" className={classes.text}>
        {item.title || ''}
      </Typography>
      <Typography variant="body1" className={classes.text}>
        {item.country || ''}
      </Typography>
      <Typography variant="body1" className={classes.text}>
        {item.optionalLine || ''}
      </Typography>
    </>
  );
  return (
    <Grid container spacing={2} className={classes.quotesItem} justifyContent='space-between'>
      <Grid item xs={12} md={item.image ? 6 : 12} className={classes.quouteContainer}>
        <Grid component='img' src={FormatQuoteIcon} className={classes.sliderIcon} />
        <Typography variant="h3" className={classes.sliderTitle}>
          {item.quote || ''}
        </Typography>
        {!item.image && <ItemDescription />}
      </Grid>
      {item.image && (
        <Grid item xs={12} md={5}  className={classes.quouteContainer}>
          <Grid item component={'img'} src={API_URL + item.image} className={classes.sliderImage} />
          <ItemDescription />
        </Grid>
      )}
    </Grid>
  );
}

const QuoteSliderModule = ({ quotes, themeSelected, blockName }) => {
  const classes = useStyles(themeSelected)();

  return (
    <MainContainer
      justifyContent={'flex-start'}
      themeSelected={themeSelected}
      blockName={blockName}
      className={classes.mainContainer}
    >
      <Carousel
        indicatorContainerProps={{
          className: classes.sliderIndicator
        }}
        interval={32000}
        indicators={quotes.length > 1}
      >
        {quotes.length && quotes.map((item, i) => <Item key={i} item={item} classes={classes} />)}
      </Carousel>
    </MainContainer>
  );
};

export default QuoteSliderModule;
