import {makeStyles} from '@material-ui/core';
import {themeType} from '../../Theme/ThemeSwitching';

const styles = (themeSelected) =>
	makeStyles((theme) => ({
		layout: {
			...themeType[themeSelected].background
		},
		container: {
			padding: '0 36px 48px 36px',
			marginTop: 152
			// color: theme.colors.white
		},
		companyLabelContainer: {
			...themeType[themeSelected].title,
			fontSize: 15,
			letterSpacing: -0.2
		},
		columnLayout: {
			[theme.breakpoints.down('sm')]: {
				marginTop: -40
			},
			[theme.breakpoints.down('xs')]: {
				marginTop: 65
			}
		},
		columnContainer: {
			[theme.breakpoints.down('sm')]: {
				marginTop: 40
			},
			[theme.breakpoints.down('xs')]: {
				marginTop: 56
			}
		},
		columnTitle: {
			color: '#fff',
			fontWeight: 500,
			fontSize: 19,
			lineHeight: '24px'
		},
		columnItemContainer: {
			marginTop: 8
		},
		columnItem: {
			...themeType[themeSelected].subtitle,
			marginTop: 8,
			fontSize: 15,
			lineHeight: '20px',
			letterSpacing: ' -0.2px',
			color: '#BEBEBE',
			cursor: 'pointer',
			transition: 'all 0.15s linear',
			'&:hover': {
				color: '#e8e8e8'
			}
		},
		socialNetworksContainer: {
			textAlign: 'start',
			marginTop: 81,
			[theme.breakpoints.down('xs')]: {
				textAlign: 'left',
				marginTop: 58
			}
		},
		iconContainer: {
			display: 'flex',
			flexWrap: 'wrap',
			// justifyContent: 'space-between',
			marginLeft: -24,
			marginTop: 24,
			...themeType[themeSelected].title
		},
		followUsTitle: {
			color: '#fff'
		},
		footerIcon: {
			cursor: 'pointer',
			marginLeft: 24
		}
	}));

export default styles;
