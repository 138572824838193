import {useEffect, useRef} from "react";
import {useHistory} from "react-router-dom";

export const useAnchor = () => {
	const anchorRef = useRef(null);
	const history = useHistory();

	// const data = anchorRef.current?.getBoundingClientRect()?.y - 60

	useEffect(() => {
		if ((history.location.hash && anchorRef.current) && anchorRef.current?.id === history.location.hash) {
			// const data = anchorRef.current.getBoundingClientRect().y - 60
			// window.scrollTo(0, data);
			anchorRef.current.scrollIntoView();
		}
	}, [anchorRef, history.location.hash])

	return {anchorRef}
}


//anchorRef.current.scrollIntoView()
