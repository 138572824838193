import {makeStyles} from "@material-ui/core";
import {themeType} from "../../Theme/ThemeSwitching";

const styles = (themeSelected, buttonType) => makeStyles(theme => ({
	container: {
		textAlign: 'center',
		marginTop: 60,
		[theme.breakpoints.down('sm')]: {
			display: 'none'
		}
	},
	reset: {
		cursor: 'pointer',
		...themeType.getThemeData(themeSelected).title,
		textAlign: 'right'
	}
}))

export default styles;
