import {makeStyles} from "@material-ui/core";
import {themeType} from "../../Theme/ThemeSwitching";

const styles = (themeSelected) => makeStyles(theme => ({
	titleContainer: {
		[theme.breakpoints.down('xs')]: {
			// display: 'flex',
			// flexDirection: 'column-reverse',
		},
	},
	title: {
		fontFamily: 'IBM Plex Sans',
		fontStyle: 'normal',
		fontWeight: 500,
		fontSize: 63,
		letterSpacing: -2.2,
		marginBottom: 72,
		[theme.breakpoints.down('sm')]: {
			fontSize: 39
		},
		[theme.breakpoints.down('xs')]: {
			marginTop: 16
		},
	},
	videoContainer: {
		margin: 'auto',
	},
	video: {
		pointerEvents: 'none'
	},
	subtitle: {
		fontStyle: 'normal',
		fontWeight: 'normal',
		fontSize: 19,
		letterSpacing: -0.4,
		textAlign: 'left',
		margin: '48px 0 60px 0',
		...themeType[themeSelected].subtitle,
		[theme.breakpoints.down('sm')]: {
			fontSize: 17,
			letterSpacing: -0.2,
			margin: '32px 0 56px 0',
		}
	},
	button: {
		cursor: 'pointer',
		transition: 'all 0.15s linear',
		'&:hover': {
			opacity: 0.85
		}
	}
}))

export default styles;
