import {handleSplitUrl} from "../helpers/handleSplitUrl";
import {useHistory} from "react-router-dom";

export const useLinkPush = (url = '') => {
	const parsedUtl = handleSplitUrl(url);
	const history = useHistory();

	return () => {
		if (parsedUtl.includes("http")) {
			window.open(parsedUtl, '_blank').focus();
		} else {
			history.push(parsedUtl)
		}
	}
}
