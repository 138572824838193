import {Grid, makeStyles} from "@material-ui/core";
import {buttonTypeEnum, themeType} from "Theme/ThemeSwitching";
import FilterListIcon from '@mui/icons-material/FilterList';
import CloseIcon from '@mui/icons-material/Close';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import {useRef, useState} from "react";
import useOutsideToggle from "../../hooks/useOutsideToggle";

const useStyles = (themeSelected, buttonType) => makeStyles(theme => ({
	container: {
		...themeType.getThemeData(themeSelected).button[buttonType],
		width: 'max-content',
		height: 52,
		padding: '0 24px',
		margin: 0,
		borderRadius: 100,
		textAlign: 'left',
		fontSize: 15,
		letterSpacing: -0.2,
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		// marginTop: 20,
		position: 'relative',
		marginRight: 12,
		// display: 'none',
		[theme.breakpoints.down('sm')]: {
			width: '100%',
			marginTop: 20,
			marginRight: 'unset',
			// display: 'flex'
		}
	},
	iconContainer: {
		display: 'flex', alignItems: 'center'
	},
	dropdownListContainer: {
		position: 'absolute',
		top: 53,
		left: 0,
		maxHeight: 203,
		border: `1px solid ${themeType.getThemeData(themeSelected).title.color}`,
		...themeType.getThemeData(themeSelected).background,
		zIndex: 10,
		overflow: 'hidden',
		overflowY: 'scroll'

	},
	dropdownListItem: {
		...themeType.getThemeData(themeSelected).title,
		borderBottom: `1px solid ${theme.colors.darkGrey}`,
		padding: 5
	}
}))

const FilterMobileButton = ({
								title = 'Filter Jobs',
								Icon = FilterListIcon,
								themeSelected = '',
								isDropdown = false,
								placeholder = '',
								list = [],
								listItemAction = () => null,
								handleClickIcon = () => null,
								handlePressButton = () => null,
							}) => {
	const classes = useStyles(themeSelected, title ? buttonTypeEnum.primary : buttonTypeEnum.secondary)();

	const ref = useRef();
	const [isListShown, setIsListShown] = useState(false);
	const handleToggleList = (data) => () => setIsListShown(data);
	useOutsideToggle(ref, handleToggleList(false));

	const handleClickDropdownItem = ({id, title}) => () => {
		listItemAction({id, title})
		handleToggleList(false)()
	}

	if (isDropdown) {
		const DropdownIcon = title ? CloseIcon : ArrowDropDownIcon
		return <Grid item className={classes.container} ref={ref}>
			<Grid container alignItems={'center'} justifyContent={'space-between'}>
				<Grid item xs={11} onClick={handleToggleList(!isListShown)}>
					{title || placeholder}
				</Grid>
				<Grid item xs={1} className={classes.iconContainer}>
					<DropdownIcon onClick={() => {
						handleClickIcon();
						title && handleToggleList(false)();
						!title && handleToggleList(true)();
					}}/>
				</Grid>
			</Grid>
			{isListShown && <Grid container className={classes.dropdownListContainer}>
				{list.map(({id, title}) => <Grid className={classes.dropdownListItem} item xs={12}
												 onClick={handleClickDropdownItem({id, title})}
												 key={id}>{title}</Grid>)}
			</Grid>}
		</Grid>
	}
	return <div className={classes.container} onClick={handlePressButton}>{title} <Icon/></div>
}

export default FilterMobileButton;
