import {makeStyles} from "@material-ui/core";
import {themeType} from "../../Theme/ThemeSwitching";

const style = (themeSelected) => makeStyles(theme => ({
	inputContainerStyles: {
		textAlign: 'start',

		padding: '60px 36px 52px 36px !important',
		[theme.breakpoints.down('sm')]: {
			padding: '16px !important'
		}
	},
	labelContainer: {
		padding: '0 36px !important',
		[theme.breakpoints.down('sm')]: {
			padding: '16px !important'
		}
	},

	resultsLabel: {
		textAlign: 'left',
		marginTop: 80,
		marginBottom: 25,
		...themeType[themeSelected].subtitle,
		[theme.breakpoints.down('sm')]:{
			marginTop: 52
		}
	},
	loadMoreContainer: {
		margin: '39px 0 63px 0',
		color: theme.colors.darkGrey,
	},
	loadMoreButton: {
		border: 'none',
		background: 'transparent',
		textAlign: 'left',
		fontWeight: 'bold',
		fontSize: 15,
		letterSpacing: -0.2
	},
	contentContainer: {
		...themeType[themeSelected]?.subtitle,
		marginTop: 30,
		fontSize: 18,
		letterSpacing: '-0.02em'
	},
	column: {
		paddingRight: 24,
		fontSize: 19,
		fontWeight: 500,
		letterSpacing: -0.4,
		[theme.breakpoints.down('xs')]: {
			marginTop: 15,
		}
	},
	exploreAllContainer: {
		display: 'flex',
		alignItems: 'flex-start',
		fontSize: 15,
		letterSpacing: -0.2,
		cursor: 'pointer',
		justifyContent: 'flex-end',
		[theme.breakpoints.down('sm')]: {
			justifyContent: 'flex-start',
			marginTop: 20,
			fontSize: 17
		}
	}
}))

export default style;
