import PageLevelService from "helpers/PageLevelService";
import stylesEnumObject from "Theme/ThemeSwitching/theneNames";
import {themeType} from "Theme/ThemeSwitching";

class FontSizeService extends PageLevelService {
	// eslint-disable-next-line
	constructor(location) {
		super(location);
	}

	setThemeType() {
		if (this.isFirstLevel) {
			this.themeSelected = stylesEnumObject.blackBackground;
		} else {
			this.themeSelected = stylesEnumObject.whiteBackground;
		}
	}

	get themeData() {
		this.setThemeType();
		// const fontSize = this.isFirstLevel ? '9vw' : '6.7vw';
		const fontSize = this.isFirstLevel ? 230 : 170;
		return {
			...themeType.getThemeData(this.themeSelected).background,
			...themeType.getThemeData(this.themeSelected).title,
			fontSize
		};
	}
}

export default FontSizeService;
