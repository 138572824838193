import inputsActions from './Modules/Inputs/inputs-actions';
import filtersActions from './Modules/Filters/filters-actions';
import mainPageActions from './Modules/MainPage/main-page-actions';
import generalActions from './Modules/General/general-actions';
import careersActions from './Modules/Careers/careers-actions';
import insightsActions from './Modules/Insights/insights-actions';
import AboutUsActions from './Modules/AboutUs/aboutUs-actions';
import ContactUsActions from './Modules/ContactUs/contactUs-actions';
import cmsActions from "./Modules/CMS/cmsActions";
import {useDispatch} from "react-redux";
import {bindActionCreators} from "redux";

const actions = {
	...inputsActions,
	...filtersActions,
	...mainPageActions,
	...generalActions,
	...careersActions,
	...insightsActions,
	...AboutUsActions,
	...ContactUsActions,
	...cmsActions,
}

export const useActionCreator = () => {
	const dispatch = useDispatch();
	return bindActionCreators(actions, dispatch)
}
