import {makeStyles} from "@material-ui/core";

export default makeStyles(theme => {
	return ({
		container: {
			width: '100vw',
			// paddingTop: 70
		},
		animation: {
			width: '100%',
			height: '100vh',
			position: 'relative',
		}
	})
})
