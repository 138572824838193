import stylesEnum from "./theneNames";
class ClassThemeGenerator {
	constructor(blackData,
				whiteData,
				lavenderData,
				acquaData,
				pinkData,
				coralData,
				azureData,
				blondeData,
				yellowData,
				bronzeData,
				platinumData,
				darkBlueData,
				brightBlueData,
				brownData,
				darkGreenData,
				spaceGreyData) {

		this[stylesEnum.blackBackground] = blackData;
		this[stylesEnum.whiteBackground] = whiteData;
		this[stylesEnum.lavenderBackground] = lavenderData;
		this[stylesEnum.acquaBackground] = acquaData;
		this[stylesEnum.pinkBackground] = pinkData;
		this[stylesEnum.coralBackground] = coralData;
		this[stylesEnum.azureBackground] = azureData;
		this[stylesEnum.blondeBackground] = blondeData;
		this[stylesEnum.yellowBackground] = yellowData;
		this[stylesEnum.bronzeBackground] = bronzeData;
		this[stylesEnum.platinumBackground] = platinumData;
		this[stylesEnum.darkBlueBackground] = darkBlueData;
		this[stylesEnum.brightBlueBackground] = brightBlueData;
		this[stylesEnum.brownBackground] = brownData;
		this[stylesEnum.darkGreenBackground] = darkGreenData;
		this[stylesEnum.spaceGreyBackground] = spaceGreyData;
	}

	getThemeData(theme) {
		if(this[theme]){
			return this[theme]
		}else{
			return {}
		}
	}
}

export default ClassThemeGenerator;
