import {Grid} from "@material-ui/core";
import ReplayIcon from "@mui/icons-material/Replay";
import {FilterMobileButton} from "components";
import {modalNamesEnum} from "Context/ModalContext";
import React from "react";

const filtersData = [
	{name: 'industryFilter', placeholder: 'Industries', status: false},
	{name: 'typeFilter', placeholder: 'All types', status: false},
]

export const InsightsFilterAreaMobile = ({
											 themeSelected,
											 handleSetModal,
											 classes,
											 findInsightsAction = () => null,
											 resetAction = () => null,
											 placeholderName = ''
										 }) => {
	return (
		<Grid container className={classes.mobileFilterContainer}>
			{placeholderName && <Grid container justifyContent={'space-between'}>
				<Grid item xs={12}>
					Active Filters:
				</Grid>
				<Grid item xs={6}>
					{placeholderName}
				</Grid>
				<Grid item xs={6}>
					<Grid container justifyContent={'flex-end'} alignItems={'center'}
						  className={classes.reset}
						  onClick={resetAction}
					>
						<ReplayIcon/>
						<span>Reset</span>
					</Grid>
				</Grid>
			</Grid>}
			<FilterMobileButton
				themeSelected={themeSelected}
				handlePressButton={handleSetModal({
					modalName: modalNamesEnum.careersFilterModal,
					themeSelected,
					filtersData,
					inputStoreKey: 'insightsSearch',
					inputPlaceholder: `Search for insights and keywords`,
					findAction: findInsightsAction,
					resetAction,
					mainButtonName: 'TEST'
				})}/>
		</Grid>
	)
}
