import {makeStyles} from "@material-ui/core";
import {themeType} from "Theme/ThemeSwitching";

const styles = (themeSelected) => makeStyles(theme => {
	return {
		noPadding: {
			paddingTop: '0 !important'
		},
		title: {
			fontWeight: 500,
			fontSize: '5.2vw',
			letterSpacing: -2.8,
			[theme.breakpoints.down('sm')]: {
				fontSize: 39
			},
		},
		smallTitle: {
			fontWeight: 500,
			// fontSize: 63,
			fontSize: '3.8vw',
			letterSpacing: -2.8,
			[theme.breakpoints.down('sm')]: {
				fontSize: 39
			},
		},
		contentContainer: {
			...themeType[themeSelected]?.subtitle,
			marginTop: 30,
			fontSize: 18,
			letterSpacing: '-0.02em'
		},
		column: {
			paddingRight: 24,
			fontSize: 19,
			fontWeight: 500,
			letterSpacing: -0.4,
			[theme.breakpoints.down('xs')]: {
				marginTop: 15,
			}
		},
		buttonContainer: {
			marginTop: 64,
			letterSpacing: -0.2,
			[theme.breakpoints.down('sm')]: {
				marginTop: 48,
			},
		},
		exploreAllContainer: {
			display: 'flex',
			alignItems: 'flex-start',
			fontSize: 15,
			letterSpacing: -0.2,
			cursor: 'pointer',
			justifyContent: 'flex-end',
			[theme.breakpoints.down('sm')]: {
				justifyContent: 'flex-start',
				marginTop: 20,
				fontSize: 17
			}
		}
	}
})


export default styles;
