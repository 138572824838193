import {makeStyles} from "@material-ui/core";
import {themeType} from "Theme/ThemeSwitching";

const styles = (themeSelected, buttonType) => makeStyles(theme => ({
	container: {
		position: 'absolute',
		height: 56,
		width: 116,
		borderRadius: 100,
		...themeType.getThemeData(themeSelected).button[buttonType],
		right: 9,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		letterSpacing: -0.4,
		fontSize: 19,
		fontWeight: 'bold',
		cursor: 'pointer',
		[theme.breakpoints.down('sm')]: {

		}
	}
}))

export default styles;
