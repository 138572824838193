import {UPDATE_GENERAL_STORE_TYPE, UPDATE_META_DATA} from "./general-type";

class InitialState {
	footer = [];
	header = [];
	socialMediaIcons = [];
	headerAdditional = [];
	notFoundPageData = [];
	responseParsedPageType = {};
	meta = {};
}


export const generalReducer = (state = new InitialState(), action) => {
	switch (action.type) {
		case UPDATE_GENERAL_STORE_TYPE: {
			return {...state, ...action.payload}
		}
		case UPDATE_META_DATA: {
			return {...state, meta: {...state.meta, ...action.payload}}
		}
		default: {
			return state
		}
	}
}
