import {makeStyles} from '@material-ui/core';

import {headerTheme} from '../../HeaderTheme';
import {themeType} from "../../../../Theme/ThemeSwitching";

const styles = (themeSelected) =>
	makeStyles((theme) => {
		const backgroundColor = themeType.getThemeData(themeSelected).title.color === theme.colors.white ?  theme.colors.darkBlack : theme.colors.white

		return ({
			container: {
				width: '100%',
				position: 'relative'
			},
			layout: {
				position: 'absolute',
				width: '150%',
				height: 50,
				top: -20
			},
			title: {
				// fontSize: '1vw',
				position: 'relative',
				fontSize: 15,
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'space-between',
				letterSpacing: -0.2,
				zIndex: 2222,
				...themeType.getThemeData(themeSelected).title,
				[theme.breakpoints.down('sm')]: {
					fontSize: '1.2vw'
				}
			},
			dropdownContainer: {
				position: 'absolute',
				// background: theme.colors.darkBlack,
				padding: '10px 40px 30px 40px',
				// color: theme.colors.white,
				background: backgroundColor,
				...themeType.getThemeData(themeSelected).title,
				// ...headerTheme.getThemeData(themeSelected),
				overflowX: 'hidden',
				zIndex: 2000,
				width: 'max-content',
				maxWidth: 800,
				minWidth: 405,
				listStyleType: 'none',
				display: 'grid',
				gridGap: '0 2em'
			},
			dropdownArrow: {
				marginLeft: 5,
				display: 'block',
				fill: themeType.getThemeData(themeSelected).title.color,
			},
			selectedAnotherMenu: {
				color: `${theme.colors.gray} !important`
			},
			dropdownArrowRotated: {
				transform: 'rotateX(180deg)'
			},
			dropdownItemContainer: {
				fontStyle: 15,
				marginTop: 8,
				position: 'relative'
			},
			dropdownItem: {
				'&:hover': {
					color: headerTheme.getThemeData(themeSelected).hoverColor
				}
			},
			divider: {
				marginTop: 4,
				border: `solid 1px ${themeType.getThemeData(themeSelected).title.color}`
			},
			itemTriangle: {
				top: 10,
				transform: 'translateY(-50%)',
				position: 'absolute',
				right: 0,
				width: 0,
				height: 0,
				borderStyle: 'solid',
				borderWidth: '3px 0 3px 6px',
				borderColor: `transparent transparent transparent ${
					themeType.getThemeData(themeSelected).title.color
				}`
			},
			dropdownTriangle: {},
			subLinksContainer: {
				marginBottom: 12
			},
			subLinks: {
				fontSize: 15,
				marginTop: 8,
				position: 'relative',
				left: 48,
				// ...headerTheme.getThemeData(themeSelected),
				'&:hover': {
					color: headerTheme.getThemeData(themeSelected).hoverColor
				}
			}
		})
	});

export default styles;
