import React from 'react';
import {Grid} from '@material-ui/core';
import {useCareerMainContent} from './useCareerMainContent';
import useStyles from './style';
import stylesEnumObject from 'Theme/ThemeSwitching/theneNames';
import {blockComponents} from "BlockConstructor/BlockNames";

export const CareersMainContent = ({
									   pageData = []
}) => {
	const {
		themeSelected = stylesEnumObject.blackBackground,
		// pageData,
	} = useCareerMainContent();
	const classes = useStyles(themeSelected)();
	return (
		<Grid container justifyContent={'center'} className={classes.container}>
			<Grid item xs={12}>
				<div className={classes.spaceForHeader} />
				{pageData?.length && pageData?.map((block, index) => blockComponents.getComponent(block?.type)({params: block}, index))}
			</Grid>
		</Grid>
	);
};
