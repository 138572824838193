import {Grid, makeStyles} from "@material-ui/core";
import stylesEnumObject from "Theme/ThemeSwitching/theneNames";
import {useThemeToggleByScroll} from "hooks/useThemeToggleByScroll";
import {useLocation} from "react-router-dom";
import FontSizeService from "./FontSizeService";
import {themeType} from "../../Theme/ThemeSwitching";

const useStyle = (themeSelected, levelTheme) => makeStyles(theme => ({
	container: {
		fontFamily: 'TranscomFont',
		fontSize: '7.3vw',
		// fontSize: 112,
		letterSpacing: "-0.06em",
		// ...levelTheme,
		...themeType[themeSelected]?.background,
		...themeType[themeSelected]?.title,
		padding: '24px 36px',
		[theme.breakpoints.down('sm')]: {
			fontSize: 56,
			padding: 16,
			marginTop: 50
		},
	},
	titleContainer: {
		maxWidth: 1680,
		[theme.breakpoints.down('xs')]: {
			width: 40,
			wordBreak: 'break-all',
			paddingRight: 60
		}
	}
}))

const LabelModule = ({
						 themeSelected = stylesEnumObject.whiteBackground,
						 text = '',
						 blockName = '',
						 className,
						 ...rest
					 }) => {
	const {pathname} = useLocation();
	const pageLevelService = new FontSizeService(pathname);
	const {ref} = useThemeToggleByScroll({themeSelected, blockName})
	const classes = useStyle(themeSelected, pageLevelService.themeData)()
	return <Grid justifyContent={'center'} container className={`${classes.container} ${className && className}`}
				 ref={ref}>
		<Grid item {...rest} className={classes.titleContainer}>
			{text}
		</Grid>
	</Grid>
}

export default LabelModule;
