import React, {useState} from 'react';

import {Grid} from '@material-ui/core';
import Carousel, {slidesToShowPlugin} from '@brainhubeu/react-carousel';

import {MainContainer, Title} from 'components';
import {API_URL} from 'API/constants';

import useStyles from './style';
import '@brainhubeu/react-carousel/lib/style.css';

const CarouselModule = ({themeSelected, blockName, data}) => {
	const classes = useStyles(themeSelected)();
	const {title = '', description = '', images = []} = data;

	const [activeItem, setActiveItem] = useState(0);

	const handleChangeItem = (idx) => () => {
		setActiveItem(idx);
	};

	const filteredImage = images.filter(i => i.field_image)
	return (
		<MainContainer
			themeSelected={themeSelected}
			blockName={blockName}
			className={classes.container}
		>
			<Grid container className={classes.mainContainer}>
				<Title
					title={title}
					label={'label'}
					themeSelected={themeSelected}
					className={classes.title}
					titleGrid={{xs: 12, sm: 6}}
				/>
				<Grid container justifyContent='center'>
					<Grid item component='p' xs={12} sm={6} className={classes.description}>
						{description}
					</Grid>
				</Grid>
			</Grid>
			{!!filteredImage && <Carousel
				plugins={[
					'clickToChange',
					'centered',
					{
						resolve: slidesToShowPlugin,
						options: {
							numberOfSlides: 2
						}
					}
				]}
			>
				{filteredImage.filter(i => i.field_image).map((el, idx) => {
					const isActiveItem = idx === activeItem;

					return (
						<Grid key={el.id} className={classes.imageContainer}>
							<img
								src={API_URL + el.field_image?.field_media_image?.uri?.url}
								alt={el.description}
								onClick={handleChangeItem(idx)}
								className={isActiveItem ? classes.image : classes.blurredImage}
							/>
							{isActiveItem && (
								<Grid
									item
									component='p'
									xs={12}
									sm={9}
									className={classes.imageDescription}
								>
									{el.field_description}
								</Grid>
							)}
						</Grid>
					);
				})}
			</Carousel>}
		</MainContainer>
	);
};

export default CarouselModule;
