import LinkedInIcon from "@mui/icons-material/LinkedIn";
import YouTubeIcon from '@mui/icons-material/YouTube';
import InstagramIcon from '@mui/icons-material/Instagram';
import TwitterIcon from '@mui/icons-material/Twitter';
import GitHubIcon from '@mui/icons-material/GitHub';
import PinterestIcon from '@mui/icons-material/Pinterest';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import FacebookIcon from '@mui/icons-material/Facebook';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';

class IconsInterface {
	updateStyleComponent({IconComponent, classes, icon}) {
		return <IconComponent key={icon.value} className={classes.footerIcon}/>
	};

	linkedin = ({...rest}) => this.updateStyleComponent({IconComponent: LinkedInIcon, baseUrl: '', ...rest,});
	youtube = ({...rest}) => this.updateStyleComponent({IconComponent: YouTubeIcon, baseUrl: '', ...rest,});
	instagram = ({...rest}) => this.updateStyleComponent({IconComponent: InstagramIcon, baseUrl: '', ...rest,});
	twitter = ({...rest}) => this.updateStyleComponent({IconComponent: TwitterIcon, baseUrl: '', ...rest,});
	github = ({...rest}) => this.updateStyleComponent({IconComponent: GitHubIcon, baseUrl: '', ...rest,});
	pinterest = ({...rest}) => this.updateStyleComponent({IconComponent: PinterestIcon, baseUrl: '', ...rest,});
	whatsapp = ({...rest}) => this.updateStyleComponent({IconComponent: WhatsAppIcon, baseUrl: '', ...rest,});
	facebook = ({...rest}) => this.updateStyleComponent({IconComponent: FacebookIcon, baseUrl: '', ...rest,});
	email = ({...rest}) => this.updateStyleComponent({IconComponent: AlternateEmailIcon, baseUrl: '', ...rest,});


	getIconComponent(icon) {
		if (this[icon]) {
			return this[icon]
		} else {
			return null;
		}
	}
}

export default new IconsInterface()
