import cmsTypes from "./cmsTypes";
import API from 'API/index';
import {blockTypesEnum} from "BlockConstructor/BlockTypes";
import constantsClass from "API/ConstantsClass";
import filtersActions from 'Redux/Modules/General/general-actions';
import {handleParseJob} from "../../../helpers/handleParseJob";


const bundleForCustomParser = ['job_offering']

class CmsActions {
	updateCmsStoreAction = (payload) => ({
		type: cmsTypes.UPDATE_CMS_STORE,
		payload
	});

	fetchCmsSubRequestAction = (params) => async (dispatch, store, query) => {
		try {
			const queryData = query.config;
			let parsedBody, url, responsePageData;

			if (params !== '/') {
				const result = await API.generalApi.postSubRequestData(params);
				if (result['iddd#uri{0}']) {
					parsedBody = JSON.parse(result.router.body)
					url = constantsClass.getUrl(parsedBody.entity.bundle).PAGE_BLOCK_QUERY;
					const responsePageStructure = await API.generalApi.postSubRequestData(params, url);
					responsePageData = JSON.parse(responsePageStructure['iddd#uri{0}'].body);
				} else {
					url = constantsClass.getUrl(blockTypesEnum.NotFoundPage).BASE_BLOCK;
					const {data} = await API.generalApi.getDynamicDataForPageType(url)
					responsePageData = data
					parsedBody = {
						entity: {
							bundle: blockTypesEnum.NotFoundPage
						}
					}
				}
			} else {
				url = constantsClass.getUrl(blockTypesEnum.MainPage).BASE_BLOCK;
				const {data} = await API.generalApi.getDynamicDataForPageType(url);
				responsePageData = data;

				parsedBody = {
					entity: {
						bundle: blockTypesEnum.MainPage
					}
				}
			}

			let data;
			if (bundleForCustomParser.some(i => i === parsedBody.entity.bundle)) {
				data = handleParseJob(responsePageData)[0]
			} else {
				const updatedData = responsePageData.included.map(i => ({
					...i,
					attributes: {...i.attributes, type: i.type}
				}))
				data = queryData.normalize({data: updatedData}).data
			}
			dispatch(this.updateCmsStoreAction({
				[params]: data,
				currentType: parsedBody.entity.bundle,
			}))
			dispatch(filtersActions.generalUpdateMetaDataAction({
				[params]: responsePageData?.data?.attributes.field_meta_tags
			}))
		} catch (e) {
			console.error(e)
		}
	};
}

export default new CmsActions();
