import {Grid, makeStyles} from "@material-ui/core";
import {themeType} from "Theme/ThemeSwitching";

const useStyles = (themeSelected, buttonType) => makeStyles(theme => ({
	container: {
		...themeType.getThemeData(themeSelected).button[buttonType],
		display: 'flex',
		alignItems: 'center',
		padding: '14px 28px',
		borderRadius: 100
	}
}))
const MobileFilterSubmitButton = ({title, themeSelected, buttonType, icon, press}) => {
	const classes = useStyles(themeSelected, buttonType)()
	return <Grid item className={classes.container} onClick={press}>{title} {icon}</Grid>
}

export default MobileFilterSubmitButton;
