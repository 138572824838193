import {CareersFilters} from "components/CareersFilters";
import {Grid} from "@material-ui/core";
import ReplayIcon from "@mui/icons-material/Replay";
import {FilterMobileButton} from "components/FilterMobileButton";
import {modalNamesEnum} from "Context/ModalContext";
import {CareersInput} from "components/CareersInput";
import {SubmitIcon} from "components/SubmitIcon";
import {MainContainer} from "components/MainContainer";
import React from "react";
import useStyles from "./style";
import {useCareersInputArea} from "./useCareersInputArea";
import {CareersSearchResultBlock} from "../../pages/careers/career-blocks/search-results-block";
import CallMadeIcon from "@mui/icons-material/CallMade";
import {initialState} from "../../Redux/Modules/Inputs/inputs-reducer";
import {useHistory} from "react-router-dom";
import {useActionCreator} from "../../Redux/useActionCreator";
import stylesEnumObject from "../../Theme/ThemeSwitching/theneNames";
// import {CareersSearchResultBlock} from "../../pages/careers/career-blocks/search-results-block";


const CareersInputArea = ({
							  themeSelected = stylesEnumObject.whiteBackground,
							  blockName,
							  isListShown,
							  columnData = [],
							  buttonsData = {}
						  }) => {
	const classes = useStyles(themeSelected)();
	const {
		isMobile,
		placeholderName,
		resetCareersAction,
		findJobsAction,
		handleSetModal,
		handleSubmit,
		handleToggleList,
		filtersData,
		wasSearching,
		metaSearch,
		meta,
		foundJobs,
		jobsList,
		handleSelectCard,
		pressShowMoreCareersAction
	} = useCareersInputArea({url: buttonsData?.uri?.split('internal:')});
	const history = useHistory();
	const urlTemporary = buttonsData?.uri?.split('internal:');
	const {updateInputsStoreAction} = useActionCreator();

	const handlePushTemporary = async () => {
		if (urlTemporary.length > 1) {
			await updateInputsStoreAction({...initialState});
			history.push(urlTemporary[1]);
		}
	};
	return (
		<MainContainer themeSelected={themeSelected} blockName={blockName}
					   externalClassName={classes.inputContainerStyles}>
			<CareersFilters resetCareersAction={resetCareersAction}
							themeSelected={themeSelected}
							handleToggleList={handleToggleList}
							filtersData={filtersData}
			/>
			{isMobile && placeholderName && <Grid item xs={12}>
				<Grid container justifyContent={'space-between'}>
					<Grid item xs={12}>
						Active Filters:
					</Grid>
					<Grid item xs={6}>
						{placeholderName}
					</Grid>
					<Grid item xs={6}>
						<Grid container justifyContent={'flex-end'} alignItems={'center'}
							  className={classes.reset}
							  onClick={resetCareersAction}>
							<ReplayIcon/>
							<span>Reset</span>
						</Grid>
					</Grid>
				</Grid>
			</Grid>}
			{isMobile && <FilterMobileButton
				themeSelected={themeSelected}
				handlePressButton={handleSetModal({
					modalName: modalNamesEnum.careersFilterModal,
					themeSelected,
					filtersData,
					inputStoreKey: 'jobOpeningSearch',
					inputPlaceholder: `Search for jobs and keywords`,
					findAction: findJobsAction,
					resetAction: resetCareersAction,
					submitRedirectUrl: buttonsData?.uri?.split('internal:')[1]
				})}/>
			}

			<CareersInput handleSubmit={handleSubmit} name={'jobOpeningSearch'}
						  themeSelected={themeSelected}
						  isMobile={isMobile}
						  placeholder={`Search for jobs and keywords${!(isMobile && placeholderName) ? ':' : ''} ${!isMobile ? placeholderName : ''}`}
						  inputRightIcon={SubmitIcon}
			/>


			{!!columnData.length && <Grid container justifyContent={'center'} className={classes.contentContainer}>
				<Grid item md={12}>
					<Grid container justifyContent={'flex-start'}>
						{columnData.map((column, idx) => (
							<Grid
								className={classes.column}
								item
								xs={12}
								sm={3}
								key={idx}
							>
								{column}
							</Grid>
						))}

						<Grid
							item
							xs={columnData.length > 1 ? 6 : 9}
							className={classes.exploreAllContainer}
							onClick={handlePushTemporary}
						>
							{buttonsData.title}
							<CallMadeIcon/>
						</Grid>

					</Grid>
				</Grid>
			</Grid>}


			{isListShown && <>
				{(!!+metaSearch.count || !!+meta.count) &&
				(wasSearching ? +metaSearch.count : +meta.count) && (
					<Grid item xs={12} className={classes.resultsLabel}>
						Showing Results:
						{`${wasSearching ? foundJobs.length : jobsList.length} of ${
							wasSearching ? +metaSearch.count : +meta.count
						}`}
					</Grid>
				)}
				{wasSearching && !foundJobs.length ? (
					<h3>No results</h3>
				) : (
					<CareersSearchResultBlock
						listData={wasSearching ? foundJobs : jobsList}
						classes={classes}
						themeSelected={themeSelected}
						handleSelectCard={handleSelectCard}
					/>
				)}

				{(wasSearching ? foundJobs.length : jobsList.length) <
				(wasSearching ? +metaSearch.count : +meta.count) && (
					<Grid item md={12}>
						<Grid
							container
							justifyContent={'flex-start'}
							className={classes.loadMoreContainer}
						>
							<Grid
								onClick={pressShowMoreCareersAction}
								item
								md={2}
								component={'button'}
								className={classes.loadMoreButton}
							>
								Load more...
							</Grid>
						</Grid>
					</Grid>
				)}
			</>
			}
			{/*</MainContainer>*/}
		</MainContainer>
	)
}

export default CareersInputArea;
