import React, { useState, useMemo } from 'react';
import { styled } from '@mui/material/styles';
import { Grid } from '@material-ui/core';
import { Accordion, AccordionSummary } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { Title, MainContainer } from 'components';
import { useLinkPush } from '../../hooks/useLinkPush';
import openIcon from 'assets/icons/doc_open.svg';

import useStyles from './style';
import { API_URL } from 'API/constants';

const LinkUrl = ({ file, classes, title }) => {
  const onClick = useLinkPush(file.field_link?.url || file.field_link?.uri)

  return (
    <Grid
      item
      component='a'
      href={file.field_link?.uri}
      onClick={onClick}
      className={classes.file}
    >
      {title || ''}
    </Grid>
  );
};

const MuiAccordion = styled((props) => (
  <Accordion disableGutters elevation={0} square {...props} />
))(() => ({
  border: 'none',
  borderTop: '1px solid #bdbdbd'
}));

const DocumentDownload = ({ themeSelected, reportData, blockName }) => {
  const classes = useStyles(themeSelected)();
  const { title = '', description = '', label = '', documents = [] } = reportData;

  const defaultTitle = useMemo(() => {
    if (documents.length) {
      return documents[0].field_title;
    }
    return '';
  }, [documents]);

  const [documentType, setDownloadType] = useState(defaultTitle);

  const filteredDocuments = useMemo(() => {
    if (documents.length) {
      return documents.find((doc) => doc.field_title === documentType).field_documents_tab_content;
    }
    return [];
  }, [documentType, documents]);

  const defaultDocumentsTitle = useMemo(() => {
    if (documents.length) {
      return documents.map((el) => el.field_documents_tab_content[0].field_title);
    }
    return [];
  }, [documents]);

  const [expanded, setExpanded] = useState(defaultDocumentsTitle);

  const switchDocumentType = (value) => () => {
    setDownloadType(value);
  };

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? [panel, ...expanded] : expanded.filter((pan) => pan !== panel));
  };

  const handleDownloadDocument =
    (title = '') =>
    () => {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: 'papers_reports',
        ec: 'papers_reports',
        ea: 'download',
        el: title,
        ev: 0
      });
    };

  return (
    <MainContainer themeSelected={themeSelected} blockName={blockName}>
      <Grid item xs={12}>
        <Title
          title={title}
          label={label}
          themeSelected={themeSelected}
          className={classes.title}
        />
        <Grid container justifyContent={'center'}>
          <Grid item xs={12} sm={8} md={8} className={classes.contentContainer}>
            <Grid item component={'p'} className={classes.description}>
              {description}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={8} className={classes.contentContainer}>
          <Grid item md={4} xs={6}>
            <Grid container justifyContent={'space-between'} direction='column'>
              {documents.map((report) => {
                const isActiveItem = documentType === report.field_title;

                return (
                  <Grid
                    key={report.id}
                    item
                    component='button'
                    style={{
                      textDecoration: isActiveItem ? 'underline' : 'none'
                    }}
                    className={classes.reportFile}
                    onClick={switchDocumentType(report.field_title)}
                  >
                    {report.field_title || ''}
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
          <Grid item md={8} xs={6}>
            <Grid container justifyContent={'space-between'} direction='column'>
              {!!filteredDocuments?.length &&
                filteredDocuments?.map((doc) => (
                  <MuiAccordion
                    key={doc.id}
                    expanded={expanded.indexOf(doc.field_title) !== -1}
                    onChange={handleChange(doc.field_title)}
                    classes={{ root: classes.accordionRoot }}
                  >
                    <AccordionSummary
                      className={classes.accordion}
                      expandIcon={<ExpandMoreIcon />}
                      classes={{ content: classes.yearWrapper }}
                    >
                      <Grid className={classes.year}>{doc.field_title}</Grid>
                    </AccordionSummary>
                    {doc?.field_file_or_link?.map((file) => {
                      if (file.field_documents) {
                        return file.field_documents.map((el) => (
                          <Grid
                            key={el.id}
                            item
                            component='a'
                            href={API_URL + el.field_media_file.uri.url}
                            target='_blank'
                            download
                            className={classes.file}
                            onClick={handleDownloadDocument(el.name)}
                          >
                            {el.name || ''}
                            <img src={openIcon} alt='openIcon' />
                          </Grid>
                        ));
                      } else if (file?.field_link?.uri === 'route:<nolink>' || !file?.field_link?.uri) {
                        return (
                          <Grid key={file.id} item className={classes.text}>
                            {file.field_link?.title || ''}
                          </Grid>
                        );
                      } else if (file.field_link) {
                        return (
                          <LinkUrl
                            key={file.id}
                            file={file}
                            title={file.field_link?.title || ''}
                            classes={classes}
                          />
                        );
                      }
                      return null;
                    })}
                  </MuiAccordion>
                ))}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </MainContainer>
  );
};

export default DocumentDownload;
