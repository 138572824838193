import {makeStyles} from "@material-ui/core";

const useStyles = (themeSelected) =>
	makeStyles((theme) => ({
		selectRoot: {
			minWidth: 125,
			height: 35,
			textAlign: 'center',
			borderRadius: '50px',
			marginRight: 20
		},
		resetButton: {
			marginLeft: 'auto',
			background: 'transparent',
			// border: 'none',
			display: 'flex',
			justifyContent: 'flex-end',
			alignItems: 'center',
			fontSize: 15,
			cursor: 'pointer',
			color: '#464646',
			'& svg': {
				marginRight: 5
			}
		},
		inputRoot: {
			width: '100%',
			marginTop: 24,
			'& .MuiOutlinedInput-root': {
				borderRadius: '50px'
			},

			'& input': {
				padding: '20px 14px'
			}
		},
		inputLabel: {
			display: 'flex',
			justifyContent: 'flex-start',
			alignItems: 'center',
			marginLeft: 15,
			'& > div > svg': {
				marginRight: 5
			}
		},
		container: {
			padding: '24px 36px',
			width: '100%',
			display: 'flex',
			alignItems: 'center',
		},
		resultsContainer: {
			marginTop: 100
		},
		selectLabel: {
			'&:focus': {
				background: 'transparent'
			}
		},

		mobileFilterContainer: {
			display: 'none',
			[theme.breakpoints.down('sm')]: {
				display: 'block'
			}
		},

		desktopFilterContainer: {
			[theme.breakpoints.down('sm')]: {
				display: 'none'
			}
		}
	}));

export default useStyles;
