import {useHistory} from "react-router-dom";
//import path from 'Routes/paths'
import {useEffect} from "react";
import {useActionCreator} from "Redux/useActionCreator";
import {useSelector} from "react-redux";

const useCareers = () => {
	const history = useHistory();
	// const location = useLocation();
	// const {navigationCareers} = useSelector(state => state.careers)
	const cms = useSelector(state => state.cms);
	const {getCareersNavigation} = useActionCreator();

	const handleRedirectOnPage = (path) => () => {
		history.push(path)
	}

	// const [theme, setTheme] = useState(stylesEnumObject.whiteBackground);

	// useEffect(() => {
	// 	if (location.pathname === path.careers) {
	// 		setTheme(stylesEnumObject.blackBackground)
	// 	} else {
	// 		setTheme(stylesEnumObject.whiteBackground)
	// 	}
	// }, [location.pathname])
// eslint-disable-next-line
	useEffect(getCareersNavigation, [])

	return {
		handleRedirectOnPage,
		// theme,
		// navigationCareers,
		pageData: cms[cms.currentType] || [],
	}
}
export default useCareers;
