import React, { useState } from 'react';
import { Grid } from '@material-ui/core';

import { Title, MainContainer, ButtonsListContainer } from 'components';
import { API_URL } from 'API/constants';

import useStyles from './style';
import './video.css';

const getVideoId = (link) => {
  let videoId = link.split('v=')[1];
  const ampersandPosition = videoId.indexOf('&');
  if (ampersandPosition !== -1) {
    videoId = videoId.substring(0, ampersandPosition);
  }
  return videoId;
};

const VideoModule = ({ themeSelected, videoData, blockName }) => {
  const classes = useStyles(themeSelected)();
  const [video, setVideo] = useState(0);
  const { title = '', label = '', description = '', videoLink, coverImage, titleOffset, buttonsData } =
    videoData;
  const videoId = getVideoId(videoLink);

  const handlePlayVideo = () => setVideo('1');

  return (
    <MainContainer
      justifyContent={'center'}
      themeSelected={themeSelected}
      blockName={blockName}
    >
      <Title
        title={title || ''}
        label={label}
        className={classes.title}
        offsetOption={titleOffset}
        themeSelected={themeSelected}
      />
      <Grid item xs={12} md={8} className={classes.videoContainer}>
        <div className={`box-video ${video ? 'open' : ''}`} onClick={handlePlayVideo}>
          {coverImage && (
            <div
              className='bg-video'
              style={{
                backgroundImage: `url(${API_URL + coverImage}`
              }}
            >
              <div className='bt-play' />
            </div>
          )}
          {videoLink && (
            <div className='video-container'>
              <iframe
                width='100%'
                height='700'
                src={`https://www.youtube.com/embed/${videoId}?autoplay=${video}&showinfo=0&controls=0`}
                frameBorder='0'
                title='video'
                allowFullScreen
              />
            </div>
          )}
        </div>
      </Grid>

      {description && <Grid item xs={12} sm={8} className={classes.subtitle}>
        <Grid container>
          <Grid item md={10}>
            {description || ''}
          </Grid>
        </Grid>
      </Grid>}
      {buttonsData && (
        <Grid item xs={12} sm={8} md={8}>
          <ButtonsListContainer themeSelected={themeSelected} buttonsData={buttonsData} />
        </Grid>
      )}
    </MainContainer>
  );
};

export default VideoModule;
