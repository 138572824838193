import { Grid, makeStyles } from '@material-ui/core';
import { Button } from 'components';
import { buttonTypeEnum } from 'Theme/ThemeSwitching';

const useStyles = makeStyles((theme) => ({
  button: {
    marginRight: 17,
    [theme.breakpoints.down('sm')]: {
      marginTop: 15
    },
    cursor: 'pointer'
  }
}));

const ButtonsListContainer = ({
  themeSelected,
  buttonsData = [
    {
      uri: '',
      title: 'Contact Us',
      buttonType: buttonTypeEnum.primary
    },
    {
      uri: '',
      title: 'Contact Us',
      buttonType: buttonTypeEnum.secondary
    }
  ]
}) => {

  const classes = useStyles();

  const updatedButtonData = buttonsData.map((button, idx) => ({...button, buttonType: !idx ? buttonTypeEnum.primary : buttonTypeEnum.secondary})) || []

  return (
    <Grid container>
      {updatedButtonData.map((button, idx) => (
        <Button
          key={idx}
          xs={6}
          sm={5}
          md={2}
          className={classes.button}
          themeSelected={themeSelected}
          url={button.uri}
          buttonType={button.buttonType}
          buttonName={button.title}
        />
      ))}
    </Grid>
  );
};

export default ButtonsListContainer;
