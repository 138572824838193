import {useLocation} from "react-router-dom";
import {useSelector} from "react-redux";
import {Helmet} from "react-helmet";

export const usePageMeta = () => {
	const location = useLocation();
	const meta = useSelector(state => state.general.meta)

	return () => <Helmet>
		<meta charSet="utf-8"/>
		<title>{meta[location.pathname]?.title || 'Transcom'}</title>
		<meta name="description" content={meta[location.pathname]?.description || ''}/>
	</Helmet>;
}
