import {useEffect} from "react";
import {useActionCreator} from "../../Redux/useActionCreator";
import {useSelector} from "react-redux";
import generalReselect from 'Redux/Modules/General/reselect'
import {useHistory} from "react-router-dom";

const useFooter = () => {
	const {footer, socialMediaIcons} = useSelector(generalReselect.getGeneralReselectedStore);
	const history = useHistory();
	const {generalFooterFetchAction, generalFooterFetchSocialMediaIconsAction} = useActionCreator();
	const handleSelectUrl = (url) => () =>history.push(url)

	useEffect(() => {
		generalFooterFetchAction();
		generalFooterFetchSocialMediaIconsAction();
		// eslint-disable-next-line
	}, [])
	return {
		footerData: footer,
		handleSelectUrl,
		socialMediaIcons
	}
}

export default useFooter;
