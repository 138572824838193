import { makeStyles } from '@material-ui/core';
import { headerTheme } from '../Header/HeaderTheme';
import {themeType} from "../../Theme/ThemeSwitching";

const styles = (themeSelected) =>makeStyles((theme) => {
    const backgroundColor =  themeType.getThemeData(themeSelected).title.color === theme.colors.white ?  theme.colors.darkBlack : theme.colors.white

    return ({
        container: {
            position: 'fixed',
            zIndex: 100,
            width: '100%',
            background: backgroundColor,
            fill: themeType.getThemeData(themeSelected).title.color,
            display: 'block',
            [theme.breakpoints.up(834)]: {
                display: 'block'
            },
            [theme.breakpoints.up(835)]: {
                display: 'none'
            }
            // height: '100%',
        },
        logoContainer: {
            padding: 16,
            ...headerTheme.getThemeData(themeSelected)
        },
        burgerContainer: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end'
        },
        logoImg: {
            // height: 25,
            display: 'flex',
            justifyContent: 'flex-start',
            cursor: 'pointer'
        },
        linksContainer: {
            top: 60,
            color: 'white',
            position: 'absolute',
            background: backgroundColor,
            padding: '0 16px 16px 16px',
            height: '92vh',
            overflowY: 'scroll'
        },
        link: {
            margin: '10px 0',
            cursor: 'pointer',
            position: 'relative'
        },
        linkTitle: {
            display: 'flex',
            alignItems: 'center',
            fontWeight: 500,
            fontSize: 20,
            letterSpacing: -0.4,
            ...themeType.getThemeData(themeSelected).title,
            '&:hover': {
                color: headerTheme.getThemeData(themeSelected).hoverColor
            },
            '& span': {
                marginLeft: 12
            }
        },
        itemTriangle: {
            top: 10,
            transform: 'translateY(-50%)',
            position: 'absolute',
            right: 0,
            width: 0,
            height: 0,
            borderStyle: 'solid',
            borderWidth: '3px 0 3px 6px',
            borderColor: `transparent transparent transparent ${theme.colors.darkGrey}`
        },
        burgerBlockItemContainer: {
            marginTop: 16,
            position: 'relative'
        },
        divider: {
            marginTop: 4,
            border: `solid 1px ${theme.colors.darkGrey}`
        },
        menuOpened: {
            left: 0,
            transition: 'all .5s ease-in-out'
        },
        menuClosed: {
            left: '100%',
            transition: 'all .5s ease-in-out'
        },
        pageBlockTitle: {
            ...themeType.getThemeData(themeSelected).title,
        },
        pageBlockItem: {
            marginLeft: 48,
            padding: '2px 0',
            marginTop: 18,
            position: 'relative',
            ...themeType.getThemeData(themeSelected).title,
            '&:hover': {
                color: headerTheme.getThemeData(themeSelected).hoverColor
            }
        },
        subMenuContainer: {
            marginTop: 46
        },

        globalContainer: {
            margin: '28px 0 24px 0'
        }

    })
});



export default styles;
