import {Grid} from '@material-ui/core';
import {useRef, useState} from 'react';
import useOutsideToggle from "../../hooks/useOutsideToggle";

const BurgerBlockItem = ({menuLink, classes, handleRedirectPage}) => {
	return (
		<Grid
			className={classes.burgerBlockItemContainer}
			item
			xs={12}
		>
			<span onClick={handleRedirectPage(menuLink)} className={classes.pageBlockTitle}>{menuLink.title}</span>
			<div className={classes.divider}/>
			<div className={classes.itemTriangle}/>
			{menuLink.link && (
				<Grid container>
					{menuLink.link.map((item) => (
						<Grid
							className={classes.pageBlockItem}
							onClick={handleRedirectPage(item)}
							item
							xs={12}
						>
							{item.title}
							<div
								style={{left: -20, top: 12}}
								className={classes.itemTriangle}
							/>
						</Grid>
					))}
				</Grid>
			)}
		</Grid>
	);
};

export const BurgerMenuItem = ({classes, menuItem = {}, handleRedirectPage, icon}) => {
	const [isToggledItem, setIsToggledItem] = useState(false);
	const handleToggleMenuItem = () => {
		if (menuItem.dropdown) {
			setIsToggledItem((prev) => !prev)
		} else {
			handleRedirectPage(menuItem)()
		}
	};
	const ref = useRef(null);
	useOutsideToggle(ref, () => setIsToggledItem(false))
	return (
		<Grid className={classes.link} item xs={12}>
			<Grid container ref={ref}>
				<Grid
					item
					xs={12}
					onClick={handleToggleMenuItem}
					className={classes.linkTitle}
				>
					{icon}  <span>{menuItem.title}</span>
				</Grid>
				{menuItem.dropdown && <div className={classes.itemTriangle}/>}
				<Grid container>
					{menuItem.dropdown &&
					isToggledItem &&
					menuItem.dropdown.map((menuLink) => (
						<BurgerBlockItem
							key={menuLink.id}
							handleRedirectPage={handleRedirectPage}
							classes={classes}
							menuLink={menuLink}
						/>
					))}
				</Grid>
			</Grid>
		</Grid>
	);
};
