import {Grid} from '@material-ui/core';
import {MainContainer} from 'components';
import useStyles from './style';
import {API_URL} from "API/constants";
import {useLinkPush} from "hooks/useLinkPush";

const MainPost = ({classes, post, field_call_to_action}) => {
	const {label, title, descriptionTitle, moreInfo, img, cases = []} = post;
	const handleSelectLink = useLinkPush(field_call_to_action?.uri)
	return (
		<Grid container>
			<Grid item md={7} className={classes.title}>
				<span className={classes.label}>{label}</span>
				<span>{title}</span>
			</Grid>
			<Grid container className={classes.contentContainer}>
				<Grid item md={6}>
					<Grid container alignItems={'center'} justifyContent={'flex-start'}>
						<Grid item>
							<img src={API_URL + img} alt={''} className={classes.image}/>
						</Grid>
					</Grid>
				</Grid>
				<Grid item md={5}>
					<Grid container className={classes.descriptionContainer}>
						{/*<Grid item md={12}>*/}
						{/*  <img src={Icon} alt={''} className={classes.descriptionIcon} />*/}
						{/*</Grid>*/}
						<Grid item md={8} className={classes.descriptionTitle}>
							{descriptionTitle}
						</Grid>
						<Grid item md={12}>
							<Grid container spacing={2}>
								{cases.map((item, idx) => (
									<Grid key={idx} item xs={12} className={classes.articleItemContainer}>
										<div className={classes.articleItemTitle}>{item.title}</div>
										<div className={classes.articleItemSubtitle}>{item.subtitle}</div>
									</Grid>
								))}
							</Grid>
						</Grid>
						<Grid item md={12} className={classes.moreInfo}>
							{moreInfo}
						</Grid>
						<button className={classes.postButton}
								onClick={handleSelectLink}>{field_call_to_action?.title}</button>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
};
const Post = ({classes, post}) => {
	const {label, title, img, linkTitle, linkUrl} = post;
	const handlePushLink = useLinkPush(linkUrl)

	return (
		<Grid item md={4} sm={6} xs={12} className={classes.postsContainer}>
			<p className={classes.postLabel}>{label}</p>
			<Grid
				item
				md={12}
				component={'img'}
				src={img}
				alt={''}
				className={classes.postImage}
			/>
			<Grid item xs={10} className={classes.postTitle}>
				{title}
			</Grid>
			<button onClick={handlePushLink} className={classes.postButton}>{linkTitle}</button>
		</Grid>
	);
};

const CaseStudiesModule = ({themeSelected, posts, field_call_to_action, blockName, field_article_card}) => {
	const classes = useStyles(themeSelected)();

	return (
		<>
			<MainContainer
				justifyContent={'space-between'}
				themeSelected={themeSelected}
				blockName={blockName}
			>
				<MainPost classes={classes} post={posts[0]} field_call_to_action={field_call_to_action[0]}/>
				<Grid container spacing={2}>
					{posts.slice(1, 5).map((post, idx) => (
						<Post key={idx} classes={classes} post={post}/>
					))}
				</Grid>
			</MainContainer>
		</>
	);
};

export default CaseStudiesModule;
