import { makeStyles } from '@material-ui/core';
import { themeType } from 'Theme/ThemeSwitching';

const styles = (themeSelected) =>
  makeStyles((theme) => ({
    container: {
      width: '100%',
      padding: '24px 36px'
    },
    imageContainer: {
      background: '#0C0C0C',
      width: '100%',
      padding: '100px 36px 24px 36px',
      '& img': {
        width: '100%',
        maxWidth: 900
      }
    },
    title: {
      fontSize: 63,
      lineHeight: '72px',
      ...themeType[themeSelected].title
    },
    description: {
      fontSize: 19,
      lineHeight: '24px',
      marginTop: 60,
      marginBottom: 100
    },
    selectHeader: {
      fontSize: 27,
      lineHeight: '32px',
      marginBottom: 36
    },
    selectRoot: {
      width: '100%',
      borderRadius: '50px',
      marginBottom: 50
    },
		selectLabel: {
			'&:focus': {
				background: 'transparent'
			}
		},
  }));

export default styles;
