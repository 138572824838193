import {makeStyles} from '@material-ui/core';
import {themeType} from 'Theme/ThemeSwitching';

const styles = (themeSelected) =>
	makeStyles((theme) => ({
		title: {
			position: 'relative',
			fontWeight: 500,
			fontSize: 52,
			letterSpacing: -1.4,
			...themeType[themeSelected].title
		},
		label: {
			position: 'absolute',
			fontSize: 12,
			letterSpacing: -0.2,
			top: -12
		},
		image: {
			maxHeight: 412,
			width: '100%'
		},
		contentContainer: {
			marginTop: 72,
		},
		descriptionContainer: {
			maxHeight: 412,
			padding: '30px 0 30px 64px',
			height: '100%'
		},
		descriptionIcon: {
			width: 32
		},
		descriptionTitle: {
			...themeType[themeSelected].title,
			fontSize: 27,
			letterSpacing: -0.2,
			fontWeight: 500,
			// marginTop: 16,
			// marginBottom: 16
		},
		articleItemContainer: {
			marginTop: 16
		},
		articleItemTitle: {
			...themeType[themeSelected].title,
			fontSize: 15,
			letterSpacing: -0.2,
			fontWeight: 'bold'
		},
		articleItemSubtitle: {
			...themeType[themeSelected].subtitle,
			fontSize: 15,
			letterSpacing: -0.2
		},
		moreInfo: {
			...themeType[themeSelected].subtitle,
			// marginTop: 52,
			fontSize: 15,
			letterSpacing: -0.2,
			fontWeight: 'bold'
		},
		postsContainer: {
			marginTop: 72,
		},
		postTitle: {
			fontSize: 19,
			lineHeight: '24px',
			letterSpacing: ' -0.2px',
			color: '#0C0C0C',
			marginTop: 8
		},
		postLabel: {
			fontSize: 12,
			lineHeight: '16px',
			letterSpacing: ' -0.2px',
			color: '#464646',
			marginBottom: 16
		},
		postButton: {
			fontFamily: 'IBM Plex Sans',
			fontWeight: 'bold',
			fontSize: 15,
			lineHeight: '20px',
			display: 'flex',
			alignItems: 'center',
			letterSpacing: '-0.2px',
			color: '#173EDE',
			padding: 0,
			background: 'none',
			border: 'none',
			cursor: 'pointer',
			marginTop: 16,
			width: '100%'
		},
		postImage: {
			// maxWidth: '95%',
			width: '100%'
		},
		contextContainer: {
			[theme.breakpoints.down('xs')]: {
				display: 'flex',
				flexDirection: 'column-reverse'
			}
		},
		partnerContainer: {
			height: '100%',
      justifyContent: 'space-between',
			[theme.breakpoints.down('xs')]: {
				marginTop: 33,
			}
		},
		partner: {
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'flex-start',
      maxHeight: 120,
      marginTop: 10
		},
		icon: {
			height: 115,

		},
		iconMultiple: {
			display: 'flex',
			justifyContent: 'space-between',
      width: '100%',
      padding: 10,
      height: '100%',
      objectFit: 'contain'
		}
	}));

export default styles;
