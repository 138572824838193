class Colors  {
	black = '#101010';
	darkBlack = '#0C0C0C';
	white = '#FFF';
	gray = '#C4C4C4';
	darkGrey = '#464646';
	lightGrey = '#BEBEBE';
	darkGreen = '#204E4A';
	blue = '#173EDE';
	darkBlue = '#000082';
	brightBlue = '#173EDE';
	yellow = '#FFB800';
	gold = '#FFB800';
	platinum = '#E8E8E8';
	brown = '#9B2620';
	bronze = '#D4802B';
	acqua = '#00A799';
	coral = '#FF5046';
	blonde = '#FFEDBE';
	pink = '#FFD7D4';
	azure = '#DFF1F1';
	lavender = '#DBE2FF';
}


export default new Colors();
