import { Grid } from '@material-ui/core';
import { MainContainer, Title } from 'components';

import MembersModuleItem from './MembersModuleItem';
import useStyles from './style';


const MembersModule = ({ data, themeSelected, blockName }) => {
  const classes = useStyles(themeSelected)();

  const { title = '', label = '', cards = [], titleOffsetOption = 'rightOffset' } = data;

  return (
    <MainContainer justifyContent={'center'} themeSelected={themeSelected} blockName={blockName}>
      <Grid item xs={12}>
        <Title
          title={title}
          themeSelected={themeSelected}
          label={label}
          offsetOption={titleOffsetOption}
          className={classes.title}
        />
        <Grid container justifyContent={'center'}>
          <Grid item xs={12} sm={8}>
            <Grid container spacing={2}>
              {!!cards.length && cards.map((item, idx) => (
                <Grid item key={idx} sm={4}>
                  <MembersModuleItem
                    title={item.title}
                    image={item.image}
                    description={item.description}
                    subtitle={item.subtitle}
                    classes={classes}
                    isShownText={item.isShownText}
                  />
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </MainContainer>
  );
};

export default MembersModule;
