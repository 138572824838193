import {makeStyles} from "@material-ui/core";
import {themeType} from "Theme/ThemeSwitching";

const styles = (themeSelected) => makeStyles(theme => ({
	title: {
		...themeType[themeSelected].title,
		fontWeight: 500,
		fontSize: 63,
		letterSpacing: -2.2,
		[theme.breakpoints.down('xs')]: {
			fontSize: 39,
		}
	},
	label: {
		...themeType[themeSelected].subtitle,
		fontSize: 12,
		letterSpacing: -0.2
	},
	subtitle: {
		fontSize: 15,
		letterSpacing: -0.2,
		...themeType[themeSelected].subtitle,
		marginTop: 160,
		[theme.breakpoints.down('xs')]: {
			marginTop: 48,
		}
	},
	button: {
		marginTop: 56
	},
	animationDesctopContainer: {
		position: 'relative',
		maxHeight: 510,
		overflow: 'hidden',
		[theme.breakpoints.down('xs')]: {
			display: 'none'
		}
	},
	animationMobileContainer: {
		position: 'relative',
		maxHeight: 464,
		overflow: 'hidden',
		display: 'none',
		[theme.breakpoints.down('xs')]: {
			display: 'block'
		}
	}
}));


export default styles;
