import {Grid} from "@material-ui/core";
import animationStyle from "./style.module.css";
import React, {useEffect, useState} from "react";
import stylesEnumObject from "Theme/ThemeSwitching/theneNames";
// import {useSelector} from "react-redux";
//import {blockNames} from "pages/MainPage/mocks";
import {API_URL} from 'API/constants';
import MobileHeroAnimation from "../../components/MobileHeroAnimation/MobileHeroAnimation";
import {useThemeToggleByScroll} from "../../hooks/useThemeToggleByScroll";
// import {useWindowSize} from "../../hooks/useWindowSize";

const Animation = ({blockName = '30', themeSelected = stylesEnumObject.blackBackground, pageData = {}, ...rest}) => {
	// const {width} = useWindowSize();
	const {ref} = useThemeToggleByScroll({themeSelected, blockName})
	// const {pageData} = useSelector(state => state.mainPage)
	const module = pageData?.field_hero_images?.map(i => API_URL + i.field_media_image?.uri?.url) || [];
	const [toggle, setToggle] = useState(true);

	useEffect(()=>{
		setInterval(() => {
			setToggle((prev) => !prev);
			setToggle((prev) => !prev);
		}, 12000);
	}, [])


	if (window.innerWidth <= 600) {
		return <>{toggle && <div ref={ref}><MobileHeroAnimation module={module}/></div>}</>
	}

	return (
		<>
			{toggle && <div
				ref={ref}
				className={animationStyle.wrapper}>
				<Grid container style={{position: 'relative', height: '20%', overflow: 'hidden'}}>
					<div className={animationStyle.firstLineBehindTextContainer}>
						<span className={animationStyle.firstLineBehindText}>BEHIND</span>
					</div>
					<div className={animationStyle.firstLineCenterBlock}>
						<img src={module[0]} alt={'#'} className={animationStyle.firstLineImage}/>
						<span className={animationStyle.imageCover}/>
					</div>
					<div className={animationStyle.firstLineLastBlock}>
						<span className={animationStyle.firstLineTheText}>THE</span>
					</div>
				</Grid>
				{/*</div>*/}

				<Grid container style={{position: 'relative', height: '20%', overflow: 'hidden'}}>
					<div className={animationStyle.secondLineImageContainer}>
						<img alt={''} src={module[1]} className={animationStyle.secondLineImage}/>
						<span className={animationStyle.secondLineFirstImageCover}/>
					</div>
					<div className={animationStyle.secondLineBrilianceContainer}>
						<span className={animationStyle.secondLineBrilianceText}>BRILLIANCE</span>
						<span className={animationStyle.secondLineBrilianceTextCover}/>
					</div>
					<div className={animationStyle.secondLineSecondImageContainer}>
						<img alt={''} src={module[2]} className={animationStyle.secondLineSecondImage}/>
						<span className={animationStyle.secondLineSecondImageCover}/>
					</div>
				</Grid>

				<Grid container style={{position: 'relative', height: '20%', overflow: 'hidden'}}>
					<div className={animationStyle.thirdLineOfContainer}>
						<span className={animationStyle.thirdLineOfText}>OF</span>
						<span className={animationStyle.thirdLineOfTextCover}/>
					</div>
				</Grid>

				<Grid container style={{position: 'relative', height: '20%', overflow: 'hidden'}}>
					<div className={animationStyle.fourthLineTheBestContainer}>
						<span className={animationStyle.fourthLineTheBestText}>THE BEST</span>
						<span className={animationStyle.fourthLineTheBestTextCover}/>

						<span className={animationStyle.fourthLineYourSuccessContainerText}>YOUR SUCCESS</span>
						<span className={animationStyle.fourthLineYourSuccessContainerCover}/>

						<span className={animationStyle.fourthLineYourCover}/>
						<span className={animationStyle.fourthLineSuccessCover}/>

						<span className={animationStyle.fourthLineNextGenText}>NEXT GEN</span>
						<span className={animationStyle.fourthLineNextCover}/>
						<span className={animationStyle.fourthLineGenCover}/>
					</div>
				</Grid>

				<Grid container style={{position: 'relative', height: '20%', overflow: 'hidden'}}>
					<div className={animationStyle.fifthLineContainer}>
						<span className={animationStyle.fifthLineLovedBrandsText}>LOVED BRANDS</span>
						<span className={animationStyle.fifthLineLovedCover}/>
						<span className={animationStyle.fifthLineBrandsCover}/>

						<img src={module[3]} alt={''} className={animationStyle.fifthLineFirstImage}/>
						<span className={animationStyle.fifthLineFirstImageCover}/>
						{/*<span className={animationStyle.fourthLineTheBestTextCover}/>*/}

						<span className={animationStyle.fifthLineStoriesText}>STORIES</span>
						<span className={animationStyle.fifthLineStoriesCover}/>
						<img src={module[4]} alt={''} className={animationStyle.fifthLineSecondImage}/>
						<span className={animationStyle.fifthLineSecondImageCover}/>

						<img src={module[5]} alt={''} className={animationStyle.fifthLineThirdImage}/>
						<span className={animationStyle.fifthLineThirdImageCover}/>
						<span className={animationStyle.fifthLineThirdImageSecondCover}/>

						<span className={animationStyle.fifthLineCXText}>CX</span>
						<span className={animationStyle.fifthLineCXTextCover}/>

						<img src={module[3]} alt={''} className={animationStyle.fifthLineFourthImage}/>
						<span className={animationStyle.fifthLineFourthImageCover}/>
						{/*<img src={module[3]} alt={''} className={animationStyle.fifthLineFifthImage}/>*/}
					</div>
				</Grid>
			</div>}
		</>
	)
}

export default Animation;
