import { Grid, makeStyles } from '@material-ui/core';
import { themeType } from 'Theme/ThemeSwitching';
import {useLinkPush} from "../../hooks/useLinkPush";

const useStyle = (themeSelected) =>
  makeStyles(() => ({
    button: {
      width: '100%',
      textAlign: 'left',
      padding: 0,
      fontSize: 15,
      lineHeight: '20px',
      fontWeight: 'bold',
      background: 'transparent',
      border: 'none',
      cursor: 'pointer',
      ...themeType[themeSelected].subtitle
    }
  }));

const ReadMoreButton = ({ themeSelected, onClick, title, url }) => {
  const classes = useStyle(themeSelected)();

  const handleParsedDefaultUrl = useLinkPush(url)

  return (
    <Grid component='button' className={classes.button} onClick={onClick || handleParsedDefaultUrl}>
      {title}
    </Grid>
  );
};

export default ReadMoreButton;
