import React, { useState } from 'react';
import {
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  FormGroup,
  FormControlLabel,
  Checkbox
} from '@material-ui/core';
import { useSelector } from 'react-redux';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useActionCreator } from 'Redux/useActionCreator';
import getMonth from 'date-fns/getMonth';

import useStyles from './style';

const MONTHS = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
];

const PressArchive = ({ themeSelected }) => {
  const classes = useStyles(themeSelected)();

  const [months, setMonths] = useState([]);
  const [years, setYears] = useState([]);
  const { archiveDates } = useSelector((state) => state.aboutUs);

  const { filterPressListings } = useActionCreator();

  const handleFilter = (year) => (event) => {
    const { value: month } = event.target;

    const filteredYears = years.includes(+year) ? years.filter((el) => el === year) : [+year, ...years];
    const filteredMonths = months.includes(+month) ? months.filter((el) => el === month) : [+month, ...months];

    setYears(filteredYears);
    setMonths(filteredMonths);
    filterPressListings(filteredYears, filteredMonths);
  };

  return (
    <Grid className={classes.container}>
      <Grid component='p' className={classes.title}>
        Archive
      </Grid>
      {!!archiveDates?.length && archiveDates.map((date) => {
        const year = Object.keys(date)[0];
        const months = [...new Set(date[year].map((el) => getMonth(new Date(el.created))))].map(
          (el) => ({ value: +el, label: MONTHS[el + 1] })
        );

        return (
          <Accordion key={year} className={classes.accordion}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>{year}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              {!!months?.length && months.map((month) => (
                <FormGroup key={month.value}>
                  <FormControlLabel
                    label={month.label}
                    value={month.value}
                    control={<Checkbox onChange={handleFilter(+year)} />}
                  />
                </FormGroup>
              ))}
            </AccordionDetails>
          </Accordion>
        );
      })}
    </Grid>
  );
};

export default PressArchive;
