import {Grid, makeStyles} from '@material-ui/core';
import {buttonTypeEnum, themeType} from '../../Theme/ThemeSwitching';
import {useLinkPush} from "../../hooks/useLinkPush";

const useStyle = (themeSelected, buttonType) =>
	makeStyles(() => ({
		container: {
			display: 'flex',
			justifyContent: 'center',
			padding: '17px 0',
			borderRadius: 40,
			fontSize: 17,
			fontWeight: 500,
			alignItems: 'center',
			minWidth: 125,
			...themeType.getThemeData(themeSelected).button[buttonType],
		}
	}));

const Button = ({
					buttonName,
					url = '',
					themeSelected,
					className,
					buttonAction,
					buttonType = buttonTypeEnum.primary,
					...rest
				}) => {
	const classes = useStyle(themeSelected, buttonType)();
	const parsedUrl = useLinkPush(url)
	const handleRedirect = () => {
		if (!url.includes('pdf')) {
			parsedUrl();
		} else {
			window.open(url, '_blank')
		}
	};
	return (
		<Grid
			item
			{...rest}
			component={'button'}
			onClick={buttonAction ? buttonAction : handleRedirect}
			className={`${classes.container} ${className}`}
		>
			{buttonName}
		</Grid>
	);
};

export default Button;
