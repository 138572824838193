import React, { useEffect } from 'react';
import { Grid } from '@material-ui/core';

import stylesEnum from 'Theme/ThemeSwitching/theneNames';

import { PressItem } from './components/PressItem';
import { PressArchive } from './components/PressArchive';

import useStyles from './style';
import { useSelector } from 'react-redux';
import { useActionCreator } from 'Redux/useActionCreator';
// import { useThemeToggleByScroll } from '../../hooks/useThemeToggleByScroll';
import {MainContainer} from "components";

const PressListings = ({ blockName, themeSelected = stylesEnum.whiteBackground }) => {
  const classes = useStyles(stylesEnum.whiteBackground)();
  const { pressListings } = useSelector((state) => state.aboutUs);
  const { getAboutUsPressListingsAction } = useActionCreator();

  // const { ref } = useThemeToggleByScroll({ themeSelected, blockName });

  useEffect(() => {
    getAboutUsPressListingsAction();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <MainContainer themeSelected={themeSelected} blockName={blockName}>
      <Grid container justifyContent='space-between' className={classes.container}>
        <Grid item sx={12} sm={3} className={classes.sidebarContainer}>
          <PressArchive themeSelected={stylesEnum.whiteBackground} />
        </Grid>

        <Grid item sx={12} sm={9} className={classes.pressContainer}>
          <Grid item className={classes.pressLists}>
            {pressListings?.map((press) => (
              <PressItem key={press.id} themeSelected={stylesEnum.whiteBackground} item={press} />
            ))}
          </Grid>
        </Grid>
      </Grid>
    </MainContainer>
  );
};

export default PressListings;
