import {useHistory, useParams} from 'react-router-dom';
import {useEffect} from 'react';
import {useActionCreator} from 'Redux/useActionCreator';
import {useSelector} from 'react-redux';

const useJobCardDetails = () => {
	const history = useHistory();
	const {jobsList = [], foundJobs = []} = useSelector((state) => state.careers);
	const {getCertainJobAction} = useActionCreator();
	const id = useParams();
	const handleGoBack = () => history.goBack();
	const updatedList = (foundJobs.length ? foundJobs : jobsList).filter((job) => job.id !== id.id);
	const handleSelectCard = (path) => () => history.push(path);

	useEffect(() => {
		getCertainJobAction(id.id);
		window.scrollTo(0, 0);
		// eslint-disable-next-line
	}, [id.id]);

	return {
		handleGoBack,
		jobList: updatedList,
		handleSelectCard,
	};
};

export default useJobCardDetails;
