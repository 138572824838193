import { useEffect, useRef, useState } from 'react';
import { useThemeToggleByScroll } from 'hooks/useThemeToggleByScroll';

export const useMapModule = (blockName, themeSelected) => {
  const [isHovered, setHovered] = useState(false);

  const mapRef = useRef(null);

  const handleMouseover = () => setHovered(true);

  const { ref: blockRef } = useThemeToggleByScroll({ blockName, themeSelected });

  useEffect(() => {
    if (mapRef.current) {
      mapRef.current.addEventListener('mouseover', handleMouseover);
    }
    return () => {
      if (mapRef.current) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        mapRef.current.removeEventListener('mouseover', handleMouseover);
      }
    };
  }, []);

  return { mapRef, blockRef, isHovered };
};
