import { Grid, makeStyles } from '@material-ui/core';
import { themeType } from '../../Theme/ThemeSwitching';
import { useWindowSize } from '../../hooks/useWindowSize';

class OffsetTitleOptionData {
  left = 'leftFlushed';
  right = 'rightOffset';
}

export const offsetTitleOptionData = new OffsetTitleOptionData();

const useStyles = (themeSelected, offsetOption) =>
  makeStyles((theme) => ({
    wrapper: {
      position: 'relative'
    },
    container: {
      ...themeType[themeSelected]?.title,
        [theme.breakpoints.down('xs')]: {
          fontSize: 47,
          lineHeight: "48px"
        }
    },
    label: {
      fontSize: 12,
      fontWeight: 400,
      letterSpacing: -0.2,
      position: 'absolute',
      top: '-20px',
      ...themeType[themeSelected]?.title,
        right: offsetOption === offsetTitleOptionData.left ? 0 : 'unset',
        left: offsetOption === offsetTitleOptionData.left ? 'unset' : 0,
      [theme.breakpoints.down('xs')]: {
        right: 'unset',
        left: 0,
      }
    }
  }));

const Title = ({
  title,
  label,
  className,
  themeSelected,
  offsetOption = '',
  labelGrid = { xs: 12, sm: 12, md: 1 },
  titleGrid = { xs: 12, sm: 8 },
}) => {
  const classes = useStyles(themeSelected, offsetOption)();
  const { width } = useWindowSize();
  const conditionPosition =
    width > 450
      ? offsetOption === offsetTitleOptionData.left
        ? 'space-between'
        : 'center'
      : 'flex-start';
  return (
    <Grid item xs={12}>
      <Grid
        container
        className={classes.wrapper}
        justifyContent={conditionPosition}
      >
        <Grid
          item
          className={`${classes.container} ${className}`}
          {...titleGrid}
        >
          {title}
        </Grid>
        <Grid item {...labelGrid} className={classes.label}>
          {label}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Title;
