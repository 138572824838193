import {UPDATE_ABOUT_US_STORE} from "./aboutUs-type";

const initialState = {
	pageData: [],
	defaultPressListings: null,
	pressListings: null,
  archiveDates: []
};

export const aboutUsReducer = (state = {...initialState}, action) => {
	switch (action.type) {
		case UPDATE_ABOUT_US_STORE: {
			return {...state, ...action.payload}
		}
		default: {
			return state;
		}
	}
}

