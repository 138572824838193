class BlockNames {
  ['paragraph--text_two_columns'] = 'paragraph--text_two_columns';
  ['paragraph--article_row'] = 'paragraph--article_row';
  ['paragraph--single_article_block'] = 'paragraph--single_article_block';
  ['paragraph--partners'] = 'paragraph--partners';
  ['paragraph--case_study'] = 'paragraph--case_study';
  ['paragraph--use_cases'] = 'paragraph--use_cases';
  ['paragraph--document_download'] = 'paragraph--document_download';
  ['paragraph--quote_slider'] = 'paragraph--quote_slider';
  ['media--video'] = 'media--video';
  ['paragraph--video'] = 'paragraph--video';
  ['paragraph--filters'] = 'paragraph--filters';
  ['paragraph--image_and_text_module'] = 'paragraph--image_and_text_module';
  ['paragraph--image_and_text_stack'] = 'paragraph--image_and_text_stack';
  ['paragraph--video_module'] = 'paragraph--video_module';
  ['paragraph--hero_images'] = 'paragraph--hero_images';
  ['paragraph--animated_chat'] = 'paragraph--animated_chat';
  ['paragraph--insights_search_and_filter'] = 'paragraph--insights_search_and_filter';
  ['paragraph--label_module'] = 'paragraph--label_module';
  ['paragraph--cta_not_found'] = 'paragraph--cta_not_found';
  ['paragraph--global_footprint'] = 'paragraph--global_footprint';
  ['paragraph--awards'] = 'paragraph--awards';
  ['paragraph--carousel'] = 'paragraph--carousel';
  ['paragraph--vertical_tabs'] = 'paragraph--vertical_tabs';
  ['paragraph--page_header'] = 'paragraph--page_header';
  ['paragraph--article_tile'] = 'paragraph--article_tile';
  ['paragraph--press_listing'] = 'paragraph--press_listing';
  ['paragraph--call_to_action'] = 'paragraph--call_to_action';
  ['paragraph--article_tile_expandable'] = 'paragraph--article_tile_expandable';
  ['paragraph--hubspot_forms'] = 'paragraph--hubspot_forms';
  ['paragraph--find_your_office'] = 'paragraph--find_your_office';
}

export const blockNames = new BlockNames();

