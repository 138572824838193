import { MappedItem } from './MappedItem';

export const MappedMenu = ({
  menu,
  classes,
  handleSelectDropdownItem,
  isItemHovered,
  style,
  dropdownPosition,
  triangle,
  divider
}) => {
  return (
    <ul
      className={classes.dropdownContainer}
      style={{
        ...menu?.style,
        ...style,
        ...dropdownPosition
      }}
    >
      {menu.dropdown?.length &&
        isItemHovered &&
        menu.dropdown.map((dropdownItem) => (
          <MappedItem
            triangle={triangle}
            divider={divider}
            classes={classes}
            key={dropdownItem.id}
            handleSelectDropdownItem={handleSelectDropdownItem}
            dropdownItem={dropdownItem}
          />
        ))}
    </ul>
  );
};
