import {Grid} from '@material-ui/core';
import useStyles from './style';
import useFooter from "./useFooter";
import iconsInterface from './IconsInterface'
import {useLinkPush} from "hooks/useLinkPush";

const MappedFooterItem = ({classes, item}) => {
	const handleSelectUrl = useLinkPush(item.url)
	return <Grid className={classes.columnItem} item key={item.id} xs={12} sm={12} onClick={handleSelectUrl}>
		{item.title}
	</Grid>
}

const FooterColumn = ({itemData, classes}) => {

	return (
		<Grid item xs={6} sm={6} md={3} className={classes.columnContainer}>
			<Grid container className={classes.columnTitle}>
				{itemData.title}
			</Grid>
			<Grid container className={classes.columnItemContainer}>
				{itemData.items && !!itemData.items.length && itemData.items?.map((item) => <MappedFooterItem key={item.id} item={item} classes={classes}/>)}
			</Grid>
		</Grid>
	);
};

const Footer = ({themeSelected}) => {
	const classes = useStyles(themeSelected)();
	const {footerData, handleSelectUrl, socialMediaIcons = []} = useFooter();
	return (
		<Grid container className={classes.layout}>
			<Grid item xs={12} className={classes.container}>
				<Grid container>
					<Grid item xs={12} sm={4} className={classes.companyLabelContainer}>
						<div>Transcom ©2021</div>
						<div>Privacy — Terms</div>
					</Grid>
					<Grid item xs={12} sm={8}>
						<Grid
							container
							// justifyContent={'space-between'}
							className={classes.columnLayout}
						>
							{footerData.map((column) => (
								<FooterColumn
									handleSelectUrl={handleSelectUrl}
									classes={classes}
									key={column.id}
									itemData={column}
								/>
							))}
						</Grid>
					</Grid>
				</Grid>
				<Grid item md={12} className={classes.socialNetworksContainer}>
					<Grid container>
						<Grid item sm={4}/>
						<span className={classes.followUsTitle}>Follow us</span>
					</Grid>
					<Grid container>
						<Grid item sm={4}/>
						<Grid item xs={6} sm={3} md={2} className={classes.iconContainer}>
							{Array.isArray(socialMediaIcons) && !!socialMediaIcons.length && socialMediaIcons?.map(icon => iconsInterface.getIconComponent(icon.name)({
								classes,
								icon
							}))}
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
};

export default Footer;
