import axios from "axios";
import {
	CAREERS_JOB,
	CAREERS_NAVIGATION,
	CAREERS_PAGE_BLOCK,
	CAREERS_SINGLE_JOB,
	FIND_JOB_BY_TITLE_AND_DESCRIPTION
} from "./constants";

class CareersJob {
	async getCareersPageBlock(params) {
		try {
			return axios.get(CAREERS_PAGE_BLOCK, params)
		} catch (e) {
			console.error(e)
		}
	};

	async getCareersNavigation(params) {
		try {
			return axios.get(CAREERS_NAVIGATION, params)
		}catch (e) {
			console.error(e)
		}
	};

	async getAllJobs(offset = 0, limit, params) {
		try {
			return axios.get(CAREERS_JOB(`${offset}`, `${limit}`), params)
		} catch (e) {
			console.error(e)
		}
	};

	async getCertainJob(id, params) {
		try {
			return axios.get(CAREERS_SINGLE_JOB(id), params)
		} catch (e) {
			console.error(e)
		}
	};

	async findJobs(value, additionalField, params) {
		try {
			return axios.get(FIND_JOB_BY_TITLE_AND_DESCRIPTION(value, additionalField), params)
		} catch (e) {
			console.error(e)
		}
	};
}

export default CareersJob;
