import {INPUTS_UPDATE_STORE} from "./inputs-types";

export const initialState = {
	countries: '',
	cities: '',
	jobType: '',
	languages: '',
	jobOpeningSearch: '',
	insightsSearch: '',
	typeFilter: '',
	industryFilter: '',
}

export const inputsReducer = (state = {...initialState}, action) => {
	switch (action.type) {
		case INPUTS_UPDATE_STORE: {
			return {...state, ...action.payload}
		}
		default:
			return state;
	}
}
